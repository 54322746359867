import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getProductData, getTerritoriesData, getTypeProductData } from "app/states/handbooks/selectStates";
import { fulfillmentReportData, fulfillmentReportFilter } from "app/states/reports";
import {
    BaseSelect,
    Button,
    Content,
    DatePicker,
    FlexBox,
    Title,
} from "components";


import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import NotFound from "../components/NotFound";
import ExcelJS from "exceljs";
import { contractorOptions, loadingConterpart } from "app/states/documents/selectStates";

import { fetchTypeProduct } from "app/slices/handbookSlices/typeProductSlices/typeProdcutSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { clearFilterFulfillment, fetchFulFillmentReports, filterFulfillment } from "app/slices/reportSlices/fulfillmentReportSlices/fulfillmentReportSlices";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import FulfillmentReportTable from "../components/FulfillmentReportTable";
type ParamsType = {
    name: string;
    clear?: boolean;
    value: any;
};
type DetailsState = {
    kontragent: string;
    tipTovara: string;
    tovar: string;
    territoriya: string;
    tipLitsaId: string
}
// Отчет о выполнении заказов
function FulFillmentReportContainer() {
    const dispatch = useAppDispatch();

    // data
    const filter = useAppSelector(fulfillmentReportFilter);
    const data = useAppSelector(fulfillmentReportData);
    const LOADING_TABLE = get(data, "loading", false);
    const contractorDropdowns = useAppSelector(contractorOptions);
    let loadingKontragent = useAppSelector(loadingConterpart)
    const productDropdowns = useAppSelector(getTypeProductData);
    const territoryDropdowns = useAppSelector(getTerritoriesData);
    const tovariiOptions = useAppSelector(getProductData);
    // state
    const [tipLitsaId, setTipLitsaId] = useState([
        {
            label: 'Юр. лицо',
            value: 1
        },
        {
            label: 'Физ. лицо',
            value: 2
        }
    ]);
    const [details, setDetails] = useState<DetailsState>({
        kontragent: "",
        tipTovara: '',
        tovar: '',
        territoriya: '',
        tipLitsaId: ''

    });
    // cash list filter
    const fetchCashExpenseDate = useCallback(() => {
        dispatch(fetchFulFillmentReports(filter));
    }, [dispatch, filter]);

    const handleClearFilter = () => {
        setDetails({
            kontragent: "",
            tipTovara: '',
            tovar: '',
            territoriya: '',
            tipLitsaId: ''
        })
        dispatch(clearFilterFulfillment());
    };

    //  excel download
    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const EXCEL_SHEET = `Отчет о выполнении заказов ${moment(
            new Date()
        ).format("YYYY-MM-DD")}`;
        const sheet = workbook.addWorksheet(EXCEL_SHEET);
        sheet.mergeCells("A1", `N1`);
        sheet.getCell(
            "A1"
        ).value = `Отчет о выполнении заказов с ${moment(
            get(filter, "date", 0)
        ).format("DD.MM.YYYY")}.`;
        const arr = [
            {
                label: "Контрагент " + get(details, "kontragent", null),
                value: get(details, "kontragent", ""),
            },
            {
                label: "Вид товара: " + get(details, "tipTovara", null),
                value: get(details, "tipTovara", ""),
            },
            {
                label: "Tовар: " + get(details, "tovar", null),
                value: get(details, "tovar", ""),
            },
            {
                label: "Tерритория: " + get(details, "territoriya", null),
                value: get(details, "territoriya", ""),
            },
            {
                label: "Тип лица: " + get(details, "tipLitsaId", null),
                value: get(details, "tipLitsaId", ""),
            },
        ];
        // console.log("details", details);
        let count = 1;
        arr.forEach((val: any, index: number) => {
            if (val.value != "") {
                sheet.mergeCells(
                    `A${count + 1}`,
                    `N${1 + count}`
                );
                sheet.getCell(`A${1 + count}`).value = val.label;
                count++;
            }
        });
        const rowCompany = sheet.getRow(1);
        rowCompany.height = 20;


        // sheet.getCell("A2").value = `Касса: ${details.kassa}`;
        const tableHeader = [
            {
                header: "№",
                key: "order",
                width: 4,
            },
            {
                header: "Дата документа",
                key: "dataDoc",
                width: 20,
            },
            {
                header: "Номер Заказ-наяда",
                key: "nomer",
                width: 20,
            },
            {
                header: "Контрагент",
                key: "imyaKontragent",
                width: 35,
            },
            {
                header: "Товар",
                key: "imyaTovar",
                width: 20,
            },
            {
                header: "Количество",
                key: "kol",
                width: 20,
            },
            {
                header: "Ед. измерение",
                key: "edIzm",
                width: 15,
            },
            {
                header: "На складе",
                key: "skladKol",
                width: 15,
            },
            {
                header: "Отгузка",
                key: "otgruzkaKol",
                width: 15,
            },
            {
                header: "Остатка",
                key: "ostatkaKol",
                width: 15,
            },
            {
                header: "Статус",
                key: "status",
                width: 15,
            },
            {
                header: "Телефоны",
                key: "telNumber",
                width: 20,
            },
            {
                header: "Территория",
                key: "imyaTerritori",
                width: 25,
            },
            {
                header: "Тип лица",
                key: "tipLitsa",
                width: 10,
            },
        ];
        const statusItem = (text: string) => {
            switch (text) {
                case 'NEW':
                    return 'Новый'
                case 'IN_PROGRESS':
                    return 'В процессе'
                case 'COMPLETED':
                    return 'Произведен'
                case 'CLOSED':
                    return 'Выполнен'
            }
        }
        const headerValues = tableHeader.map((item) => item.header);
        const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
            key: key,
            ...props,
        }));
        sheet.getRow(count + 2).values = headerValues;
        sheet.columns = headerKeys;
        const headerRow = sheet.getRow(count + 2);
        headerRow.height = 25;
        headerRow.alignment = { vertical: "middle", horizontal: "center" };
        let counterRow = 2;
        // row1.height = 25;
        // row1.alignment = { vertical: "middle", horizontal: "center" };
        get(data, "dataList", [])?.forEach((item: any, index: number) => {
            const row = sheet.addRow({
                order: index + 1,
                dataDoc: moment(get(item, "dataDoc", "")).format("DD.MM.YYYY"),
                nomer: get(item, "nomer", ""),
                imyaKontragent: get(item, "imyaKontragent", ""),
                imyaTovar: get(item, "imyaTovar", ""),
                kol: get(item, "kol", ""),
                edIzm: get(item, "edIzm", ""),
                skladKol: get(item, "skladKol", ""),
                otgruzkaKol: get(item, "otgruzkaKol", ""),
                ostatkaKol: get(item, "ostatkaKol", ""),
                status: statusItem(get(item, "status", "")),
                telNumber: get(item, "telNumber", ""),
                imyaTerritori: get(item, "imyaTerritori", ""),
                tipLitsa: get(item, "tipLitsa", ""),
            });
            // row.height = 25;
            // row.alignment = { vertical: "middle", horizontal: "center" };
            counterRow += 1;
        });

        // height.height = 25;
        // itogoRasxod.alignment = { vertical: "middle", horizontal: "center" };
        // itogoPrixod.alignment = { vertical: "middle", horizontal: "center" };
        // totSumma.height = 25;
        const fontBoldCells = [
            "A1",
            "A" + (count + 2),
            "B" + (count + 2),
            "C" + (count + 2),
            "D" + (count + 2),
            "E" + (count + 2),
            "F" + (count + 2),
            "G" + (count + 2),
            "H" + (count + 2),
            "I" + (count + 2),
            "J" + (count + 2),
            "K" + (count + 2),
            "L" + (count + 2),
            "M" + (count + 2),
            "N" + (count + 2),
        ];
        const startCell: any = sheet.getCell(`A${count + 2}`);
        const endCell: any = sheet.getCell(`N${counterRow + count}`);

        for (let row = startCell.row; row <= endCell.row; row++) {
            for (let col = startCell.col; col <= endCell.col; col++) {
                const cell = sheet.getCell(row, col);
                cell.border = {
                    top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
                    left: { style: "thin", color: { argb: "00000000" } },
                    bottom: { style: "thin", color: { argb: "00000000" } },
                    right: { style: "thin", color: { argb: "00000000" } },
                };
            }
        }
        fontBoldCells.forEach(
            (item) =>
            (sheet.getCell(item).font = {
                size: 11,
                bold: true,
            })
        );
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${EXCEL_SHEET}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    // change
    const handleChangeFilter = ({
        clear = false,
        value,
        name,
        ...params
    }: ParamsType) => {
        if (isEqual(get(filter, `${name}`, ""), value)) {
            return;
        }
        if (clear) {
            dispatch(clearFilterFulfillment());
        }
        dispatch(filterFulfillment({ ...filter, name, value }));
    };

    useEffect(() => {
        dispatch(fetchAllCounterpart({ all: true }));
        dispatch(fetchTypeProduct({ all: true }));
        return () => {
            handleClearFilter();
        };
    }, [dispatch]);

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <Content className={"min_height"}>
                        <Row className="mb-16">
                            <Col xs={12}>
                                <Title sm bold>
                                    Отчет о выполнении заказов
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={7}>
                                <FlexBox gap={"12px"}>

                                    <DatePicker
                                        width={"250px"}
                                        placeholder="Дата конец"
                                        dateFormat="dd.MM.yyyy"
                                        value={get(filter, "date", "")}
                                        handleDate={(e: any) => {
                                            handleChangeFilter({
                                                name: "date",
                                                value: moment(e).format("YYYY-MM-DD"),
                                                clear: true,
                                            });
                                        }}
                                    />
                                </FlexBox>
                            </Col>
                            <Col className={"text-right"} xs={5}>
                                {!isEmpty(get(data, "dataList", null)) && (
                                    <Button className="mr-8" grey handleClick={downloadExcel}>
                                        Скачать
                                    </Button>
                                )}
                                <Button
                                    green
                                    className={"mr-8"}
                                    handleClick={fetchCashExpenseDate}
                                >
                                    Сформировать
                                </Button>
                                <Button danger handleClick={handleClearFilter}>
                                    Сброс
                                </Button>
                            </Col>
                            {/* elements */}
                            <Col xs={12} className="mt-16">
                                <Row className="mb-16">
                                    <Col xs={12}>
                                        <Title sm bold>
                                            Необязательные параметры
                                        </Title>
                                    </Col>
                                </Row>
                                <FlexBox gap={"10px"}>
                                    <BaseSelect
                                        width={"250px"}
                                        placeholder={"Контрагент"}
                                        isLoading={loadingKontragent}
                                        value={get(filter, "kontragentId", "")}
                                        options={contractorDropdowns}
                                        handleValueLabel={(e: any) => {
                                            handleChangeFilter({
                                                name: "kontragentId",
                                                value: e.value,
                                            });
                                            setDetails({
                                                ...details,
                                                kontragent: get(e, "label", ""),
                                            });

                                            if (data.dataList === null) return
                                            dispatch(fetchFulFillmentReports({ ...filter, kontragentId: e.value }));
                                        }}
                                    />
                                    <BaseSelect

                                        width={"250px"}
                                        options={productDropdowns}
                                        placeholder="Вид товара"
                                        value={get(filter, "vidTovaraId", null)}
                                        handleValueLabel={(e: any) => {
                                            if (filter.vidTovaraId === e.value) return;

                                            handleChangeFilter({
                                                name: "vidTovaraId",
                                                value: e.value,
                                            });
                                            handleChangeFilter({
                                                name: "tovarId",
                                                value: null,
                                            })
                                            setDetails({
                                                ...details,
                                                tipTovara: get(e, "label", ""),
                                            });
                                            dispatch(fetchProduct({ vidTovaraId: e.value, all: true }));
                                            if (data.dataList === null) return
                                            dispatch(fetchFulFillmentReports({ ...filter, vidTovaraId: e.value, all: true }));
                                        }}
                                    />
                                    <BaseSelect
                                        isDisabled={!get(details, "tipTovara", true)}
                                        value={get(details, "tipTovara", true) ? get(filter, "tovarId", null) : null}
                                        width={"250px"}
                                        options={tovariiOptions}
                                        placeholder="Товар"
                                        handleValueLabel={(e: any) => {
                                            handleChangeFilter({
                                                name: "tovarId",
                                                value: e.value,
                                            })
                                            setDetails({
                                                ...details,
                                                tovar: get(e, "label", ""),
                                            });
                                            if (data.dataList === null) return
                                            dispatch(fetchFulFillmentReports({ ...filter, tovarId: e.value }));
                                        }}
                                        isSearchable
                                    />
                                    <BaseSelect
                                        width={"200px"}
                                        options={territoryDropdowns}
                                        placeholder="Tерритория"
                                        value={get(filter, "territoriId", null)}
                                        handleValueLabel={(e: any) => {
                                            if (filter.territoriId === e.value) return;

                                            handleChangeFilter({
                                                name: "territoriId",
                                                value: e.value,
                                            });

                                            setDetails({
                                                ...details,
                                                territoriya: get(e, "label", ""),
                                            });
                                            if (data.dataList === null) return
                                            dispatch(fetchFulFillmentReports({ ...filter, territoriId: e.value }));
                                        }}
                                    />
                                    <BaseSelect
                                        width={"200px"}
                                        options={tipLitsaId}
                                        placeholder="Тип лица"
                                        value={get(filter, "tipLitsaId", null)}
                                        handleValueLabel={(e: any) => {
                                            handleChangeFilter({
                                                name: "tipLitsaId",
                                                value: e.value,
                                            });
                                            setDetails({
                                                ...details,
                                                tipLitsaId: get(e, "label", ""),
                                            });
                                            if (data.dataList === null) return
                                            dispatch(fetchFulFillmentReports({ ...filter, tipLitsaId: e.value }));
                                        }}
                                    />
                                </FlexBox>
                            </Col>
                            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
                                <hr />
                            </Col>
                        </Row>
                        {!LOADING_TABLE ? (
                            <>
                                {data.dataList !== null ? (
                                    <>
                                        <ReportInfoTitle
                                            filter={filter}
                                            details={details}
                                            titleTxt={
                                                "Отчет о выполнении заказов"
                                            }
                                        />
                                        {!isEmpty(get(data, "dataList", [])) ? (
                                            <FulfillmentReportTable data={data} />
                                        ) : (
                                            <NotFound />
                                        )}
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <TableContentLoader col={6} />
                        )}
                    </Content>
                </Col>
            </Row>
        </div>
    );
}

export default FulFillmentReportContainer;
