import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Col, Row } from "react-grid-system";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { NumericFormat } from "react-number-format";
import {
  calcAmountWithVAT,
  calcTotalPrice,
  checkDateYear,
  removeValueOption,
} from "utilities/helpers";
import { forEach, get, isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hook";
import add_green from "assets/images/icons/add_green.svg";
import { ProductDetailsTable } from "types/documentsType";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentServices from "services/apiServices/documents";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import {
  rgstSaleContractsForm,
  saleContractFilter,
} from "app/states/documents";
import {
  getDiscountData,
  getVatRateData,
  loadingDiscount,
} from "app/states/handbooks/selectStates";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchDiscounts } from "app/slices/handbookSlices/discountsSlices/discountSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import {
  contractorOptions,
  deliveryMethodsOptions,
  loadingProductOptions,
  paymentMethodsOptions,
  productOptions,
  termsOfPaymentOptions,
  typeContractsOptions,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import {
  changeVATPriceForSales,
  fetchNotFilledSalesContracts,
  fetchSaleContractsStat,
  fetchSalesContractById,
  fetchSalesContracts,
  fetchSalesContractTempById,
  movementSalesCntSubject,
} from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import {
  RequiredSchema,
  registerSalesMaterialSchema,
} from "utilities/schema/document/documentSchemas";
import { AddNewCounterpartModal } from "modules/statics/spravochnik/containers/CounterpartsContainer";
import { Space, Spin } from "antd";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, HANDBOOK_KEY, MAIN_DEPARTMENT } from "routers/constants";

// Добавить Регистрация договоров на продажу

type OptionType = {
  label: string;
  value: number | string;
};

type protsentType = {
  label: string | null;
  okruglineyaMetod: string | null;
  prosent: number | null;
  value: number | null;
};

type ContractDetails = {
  nomerDoc: string;
  dataDoc: string;
  tipDogovoraId: OptionType | {};
  kontragentId: OptionType | {};
  dataIsp: string;
  sposobOplataId: OptionType | {};
  sposobDostavkaId: OptionType | {};
  usloviyaOplataId: OptionType | {};
};
export const AddContractSubjects = ({
  handleOpenModal,
  handleCloseModal,
  stavkaNDSId,
  dataTable,
  id,
  dataDoc,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  stavkaNDSId?: number | null;
  dataTable?: Array<ProductDetailsTable>;
  id?: string | number | null | undefined;
  dataDoc: any;
}) => {
  const dispatch = useAppDispatch();
  const productDropdowns = useAppSelector(productOptions);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const discountData = useAppSelector(getDiscountData);
  let loadingProd = useAppSelector(loadingProductOptions);
  let loadingDistrictOptions = useAppSelector(loadingDiscount);
  const details = dataTable?.find((res: any) => res.tovarId === id);
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      ...get(values, "tovar", {}),
      imyaTovara: get(values, "tovar.label", ""),
      tovarId: get(values, "tovarId", ""),
      ...get(values, "skidka"),
      skidka: get(values, "skidka.prosent", ""),
    };
    if (isEmpty(details)) {
      dispatch(movementSalesCntSubject({ data: res, type: ACTION.ADD }));
      resetForm();
      handleCloseModal();
    } else {
      dispatch(
        movementSalesCntSubject({
          data: res,
          type: ACTION.UPDATE,
          id: get(details, "tovarId", ""),
        })
      );
      resetForm();
      handleCloseModal();
    }
  };
  const detailsOkrug = () => {
    if (discountData) {
      return discountData.find((pro: protsentType) => pro.prosent == 0)
        ?.okruglineyaMetod;
    } else {
      return null;
    }
  };
  const makeDefault = () => {
    const pricePro = discountData.find(
      (pro: protsentType) => pro.prosent == get(details, "skidka", null)
    );
    if (details) {
      return pricePro;
    } else if (discountData) {
      return discountData.find((pro: protsentType) => pro.prosent == 0);
    } else {
      return null;
    }
  };
  const { values, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: {
      tovarId: get(details, "tovarId", ""),
      tovar: {
        value: get(details, "tovarId", ""),
        label: get(details, "imyaTovara", ""),
      },
      edIzmId: get(details, "edIzmId", ""),
      kol: get(details, "kol", ""),
      tsena: get(details, "tsena", 0),
      tsenaSoSkidkoy: get(details, "tsenaSoSkidkoy", 0),
      summa: get(details, "summa", 0),
      nds: get(details, "nds", ""),
      okruglineyaMetod: get(details, "okruglineyaMetod", detailsOkrug()),
      id: get(details, "id", ""),
      vsego: get(details, "vsego", 0),
      skidka: makeDefault(),
      summaNDS: get(details, "summaNDS", 0),
    },
    enableReinitialize: true,
    validationSchema: registerSalesMaterialSchema,
    onSubmit,
  });
  const handleFetchProductPrice = useCallback(() => {
    if (
      get(values, "skidka.value", null) &&
      get(values, "kol", null) &&
      get(values, "tovar.value", "")
    ) {
      DocumentServices.FetchProductPrice({
        tovarId: get(values, "tovarId", ""),
        skidka: get(values, "skidka.prosent", ""),
        okruglineyaMetod: get(values, "okruglineyaMetod", ""),
        kol: get(values, "kol", 0),
        dataDoc: dataDoc,
        nds: stavkaNDSId,
      })
        .then((res: any) => {
          if (get(res, "status", "") == 200) {
            setFieldValue("tsena", get(res, "data.data.tsena", ""));
            setFieldValue("summa", get(res, "data.data.summa", ""));
            setFieldValue(
              "tsenaSoSkidkoy",
              get(res, "data.data.tsenaSoSkidkoy", "")
            );
            setFieldValue("nds", get(res, "data.data.nds", ""));
            setFieldValue("vsego", get(res, "data.data.vsego", ""));
            setFieldValue("summaNDS", get(res, "data.data.summaNds", ""));
          }
        })
        .catch((err) => {
          console.log(err);
          setFieldValue("tsena", 0);
          setFieldValue("summa", 0);
          setFieldValue("tsenaSoSkidkoy", null);
          setFieldValue("tsenaSoSkidkoy", 0);
          setFieldValue("vsego", 0);
          setFieldValue("summaNDS", null);
        });
    }
  }, [
    values.tovar,
    values.skidka,
    values.kol,
    values.okruglineyaMetod,
    stavkaNDSId,
  ]);

  useEffect(() => {
    dispatch(fetchDiscounts({ size: 100 }));
    dispatch(fetchProduct({ all: true }));
    return () => {
      resetForm();
      handleCloseModal();
    };
  }, [dispatch]);

  useEffect(() => {
    handleFetchProductPrice();
  }, [handleFetchProductPrice]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            Ввод данных
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"tovar"}
            isSearchable
            label={"Товар"}
            required={true}
            isLoading={loadingProd}
            value={get(values, "tovar.value", "")}
            options={removeValueOption({
              currentOption: productDropdowns,
              removeValueList: dataTable,
              valueName: "tovarId",
              isUpdate: get(details, "tovar", ""),
            })}
            defaultValue={get(values, "tovar", {})}
            handleValueLabel={(e: any) => {
              setFieldValue("tovar", e);
              setFieldValue("tovarId", e.value);
              setFieldValue("id", e.id);
              setFieldValue("edIzmId", get(e, "edIzmId", ""));
            }}
          />

          <FormSelect
            label="Единица измерения"
            id={"edIzmId"}
            isSearchable
            isDisabled={true}
            required={true}
            value={get(values, "edIzmId", "")}
            options={unitsMeasurementDropdowns}
            className="mt-24"
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                value={get(values, "kol", "")}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                maxLength={40}
              />
            </Col>
          </Row>
          <FormSelect
            label={"Cкидкa"}
            id={"skidka"}
            className="mt-24"
            isLoading={loadingDistrictOptions}
            isSearchable
            required={true}
            defaultValue={get(values, "skidka", "")}
            value={get(values, "skidka.value", "")}
            options={discountData}
            handleValueLabel={(e: any) => {
              setFieldValue("skidka", e);
              setFieldValue("okruglineyaMetod", get(e, "okruglineyaMetod", ""));
            }}
          />
          <FormInput
            label={"Цена товара"}
            id={"tsena"}
            value={get(values, "tsena", "")}
            fixedDecimalScale={true}
            required={true}
            disabled={true}
          />
          <FormInput
            id={"tsenaSoSkidkoy"}
            label={"Цена товара со скидкой"}
            value={get(values, "tsenaSoSkidkoy", "")}
            fixedDecimalScale={true}
            required={true}
            disabled={true}
          />
          <FormInput
            id={"summa"}
            label={"Сумма товара"}
            value={get(values, "summa", "")}
            required={true}
            disabled={true}
          />
          <FormInput
            id={"summaNDS"}
            label={"Сумма НДС"}
            disabled={true}
            required={true}
            value={get(values, "summaNDS", "")}
          />
          <FormInput
            id={"vsego"}
            value={get(values, "vsego", "")}
            label={"Всего"}
            fixedDecimalScale={true}
            required={true}
            disabled={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" green type="submit">
              {get(details, "tovarId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddRegistSalesContractsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const paymentMethodsDropdowns = useAppSelector(paymentMethodsOptions);
  const deliveryMethodsDropdowns = useAppSelector(deliveryMethodsOptions);
  const typeContractsDropdowns = useAppSelector(typeContractsOptions);
  const paymentTermsDropdown = useAppSelector(termsOfPaymentOptions);
  const vatRate = useAppSelector(getVatRateData);
  const { state } = useLocation();
  const saleContractSubjects = useAppSelector(rgstSaleContractsForm);
  const LOADING = useMemo(
    () => get(saleContractSubjects, "loading", false),
    [saleContractSubjects]
  );
  const filter = useAppSelector(saleContractFilter);
  const [disable, setDisable] = useState(false);
  const [openCounterpart, setOpenCounterpart] = useState(false);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const [productId, setProductId] = useState<
    number | string | null | undefined
  >();

  const handleOpenModal = useCallback((id?: number | string | null): void => {
    setOpenModal(true);
    setProductId(id);
  }, []);

  // submit new form
  const onSubmit = (values: ContractDetails) => {
    const cntrcSubjects = get(
      saleContractSubjects,
      "data.dogProdajaDetails",
      []
    ).map((item: any) => ({
      tovarId: get(item, "tovarId", ""),
      kol: get(item, "kol", ""),
      skidka: get(item, "skidka", ""),
      edIzmId: get(item, "edIzmId", null),
      okruglineyaMetod: get(item, "okruglineyaMetod", ""),
      nds: get(item, "nds", null),
    }));

    let valuesToSbm: any = {};

    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = [
        "dataDoc",
        "dataIsp",
        "nomerDoc",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", ""))
    );

    try {
      DocumentServices.AddSalesContract({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dogProdajaDetails:
            Number(get(values, "tipDogovoraId.value", 1)) === 2
              ? cntrcSubjects
              : null,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchSalesContracts(filter));
          dispatch(fetchSaleContractsStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveSalesTempContractById(
              get(state, "id", "")
            ).then((res) => {
              if (get(res, "status", "") == 200) {
                dispatch(fetchNotFilledSalesContracts());
              }
            });
          }
        }

        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...get(saleContractSubjects, "data.formIntVls", {}),
      dataDoc: get(state, 'id', false) ? moment(get(saleContractSubjects, "data.formIntVls.dataDoc", "")).format("YYYY-MM-DD") : ''
    },
    onSubmit,
    validationSchema: RequiredSchema,
  });

  //  update
  const handleUpdateRegisterSalces = useCallback(() => {
    const cntrcSubjects =
      !isEmpty(get(saleContractSubjects, "data.dogProdajaDetails", [])) &&
      get(saleContractSubjects, "data.dogProdajaDetails", []).map(
        (item: any) => ({
          tovarId: get(item, "tovarId", ""),
          kol: get(item, "kol", ""),
          skidka: get(item, "skidka", ""),
          edIzmId: get(item, "edIzmId", null),
          imyaEdIzm: get(item, "imyaEdIzm", null),
          imyaTovara: get(item, "imyaTovara", null),
          okruglineyaMetod: get(item, "okruglineyaMetod", ""),
          nds: get(item, "nds", null),
        })
      );

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = [
        "dataIsp",
        "nomerDoc",
        "dataDoc",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", "");
    });

    try {
      DocumentServices.UpdateSalesContract({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          id: get(state, "id", null),
          dogProdajaDetails: !isEmpty(cntrcSubjects) ? cntrcSubjects : [],
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchSalesContracts(filter));
          dispatch(fetchSaleContractsStat());
          toast.success(MESSAGES.ADDED);
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, saleContractSubjects, values]);

  // submit not fully filled form
  const saveNotFullFilledForm = useCallback(() => {
    const cntrcSubjects =
      !isEmpty(get(saleContractSubjects, "data.dogProdajaDetails", [])) &&
      get(saleContractSubjects, "data.dogProdajaDetails", []).map(
        (item: any) => ({
          tovarId: get(item, "tovarId", ""),
          kol: get(item, "kol", ""),
          skidka: get(item, "skidka", ""),
          edIzmId: get(item, "edIzmId", null),
          imyaEdIzm: get(item, "imyaEdIzm", null),
          imyaTovara: get(item, "imyaTovara", null),
          okruglineyaMetod: get(item, "okruglineyaMetod", ""),
          nds: get(item, "nds", null),
        })
      );

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = [
        "dataIsp",
        "nomerDoc",
        "dataDoc",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", "");
    });
    const requstData = {
      ...valuesToSbm,
      dataDoc: !isEqual(get(values, "dataDoc", null), null)
        ? get(values, "dataDoc", "")
        : moment(new Date()).format("YYYY-MM-DD"),
      id: get(state, "id", null),
      imyaTipDogovora: get(values, "tipDogovoraId.label", "Разовый"),
      imyaKontragent: get(values, "kontragentId.label", ""),
      sposobOplataName: get(values, "sposobOplataId.label", ""),
      sposobDostavkaName: get(
        values,
        "sposobDostavkaId.label",
        "Доставка автотранспортом"
      ),
      imyaUsloviyaOplati: get(values, "usloviyaOplataId.label", ""),
      dogProdajaDetails: !isEmpty(cntrcSubjects) ? cntrcSubjects : [],
    };
    try {
      DocumentServices.AddNotFullFilledSalesContract({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: requstData,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledSalesContracts());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, saleContractSubjects, values]);
  // fetch doc number
  const getNumber = useCallback(
    (year?: string | null) => {
      DocumentServices.FetchContractNumber({ year }).then(({ data }: any) => {
        if (data) {
          setFieldValue("nomerDoc", get(data, "data", ""));
          if (get(data, "transactionTime", "")) {
            const transactionTime = get(data, "transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            if (isEmpty(get(values, 'dataDoc', ''))) {
              setFieldValue("dataDoc", localDate);
            }
          }
        }
      });
    },
    [get(values, "dataDoc", null)]
  );
  // check if contract is fetched by id
  const handleCheckTemp = useCallback(() => {
    if (!isEmpty(get(state, "id", null))) {
      if (get(state, "isTemp")) {
        dispatch(fetchSalesContractTempById(get(state, "id", "")));
      } else if (get(state, "isUpdate", false)) {
        dispatch(fetchSalesContractById(get(state, "id", ""))).then((res: any) => {
          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            // if (isEmpty(get(values, 'dataDoc', ''))) {
            //   setFieldValue("dataDoc", localDate);
            // }
          }
        });
      } else {
        dispatch(fetchSalesContractById(get(state, "id", "")));
        setDisable(true);
      }
    } else {
      getNumber();
      setDisable(false);
    }
  }, [dispatch, state]);

  const callDetails = useCallback(() => {
    if (isEmpty(get(state, "id", " ")) || get(state, "isUpdate", false)) {
      dispatch(fetchAllCounterpart({ all: true }));
      dispatch(
        fetchProduct({
          all: true,
          kalkulyatsiya: true,
          dataDoc: get(values, "dataDoc", null),
        })
      );
    }
  }, [dispatch]);
  // change VAT price in material when change select
  const handleCalcAmtWithVAT = useCallback(() => {
    const finedVAT = vatRate.find(
      (item: any) =>
        get(item, "value", 0) == get(values, "stavkaNDSId.value", 0)
    );

    get(saleContractSubjects, "data.dogProdajaDetails", []).map(
      (item: any, index: number) => {
        if (finedVAT && get(item, "summa", "")) {
          // calculate VAT price
          const amountWithVAT = calcAmountWithVAT({
            amount: get(item, "summa", ""),
            VAT: get(finedVAT, "percentage", ""),
            kol: get(item, "kol", ""),
            sena: get(item, "tsena", ""),
          });
          const totalSum = calcTotalPrice({
            nds: get(finedVAT, "percentage", ""),
            totalAmount: get(item, "summa", 0),
          });
          dispatch(
            changeVATPriceForSales({
              value: amountWithVAT,
              id: index,
              nds: get(finedVAT, "percentage", 0),
              vsego: totalSum,
            })
          );
        }
      }
    );
  }, [get(values, "stavkaNDSId.value", 0), vatRate]);

  useEffect(() => {
    handleCalcAmtWithVAT();
  }, [handleCalcAmtWithVAT]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    callDetails();
  }, [callDetails]);
  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddContractSubjects
          handleCloseModal={handleCloseModal}
          stavkaNDSId={get(values, "stavkaNDSId.percentage", 0)}
          dataTable={get(saleContractSubjects, "data.dogProdajaDetails", [])}
          dataDoc={get(values, "dataDoc", null)}
          id={productId}
        />
      </ReactModal>
      <ReactModal isOpen={openCounterpart}>
        <AddNewCounterpartModal
          handleCloseModal={() => setOpenCounterpart(false)}
          afterSubmitAction={() => dispatch(fetchAllCounterpart({ all: true }))}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  {PAGE.REQ}
                </Title>
                {!get(state, "isUpdate", false) && (
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    {PAGE.SAVE}
                  </Button>
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          value={get(values, "dataDoc", null)}
                          dateFormat="dd.MM.yyyy"
                          handleDate={(e: any) => {
                            if (
                              checkDateYear({
                                currentYear: get(values, "dataDoc", null),
                                checkYear: moment(e).format("YYYY-MM-DD"),
                              })
                            ) {

                              getNumber(
                                checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                })
                              );
                            }
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormSelect
                id={"tipDogovoraId"}
                isSearchable
                value={get(values, "tipDogovoraId.value", 2)}
                label={"Тип договора"}
                options={typeContractsDropdowns}
                handleValueLabel={(e: any) => {
                  setFieldValue("tipDogovoraId", e);
                }}
                className="mt-24"
                isDisabled={disable}
                required={true}
                error={touched.tipDogovoraId && errors.tipDogovoraId}
              />
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <HasAccess>
                    {({ userCan }: any) =>
                      userCan({
                        permission: `${HANDBOOK_KEY.KONTRAGENT}_CREATE`,
                        page: HANDBOOK_KEY.KONTRAGENT,
                        department: MAIN_DEPARTMENT.HANDBOOK,
                        type: PROTECTED_TYPE.PERMISSIONS,
                      }) ? (
                        <BaseSelect
                          id={"kontragentId"}
                          defaultValue={get(values, "kontragentId", "")}
                          isSearchable
                          value={get(values, "kontragentId.value", "")}
                          options={contractorDropdowns}
                          handleValueLabel={(e: any) => {
                            setFieldValue("kontragentId", e);
                          }}
                          isDisabled={disable}
                          isCustomMenu={true}
                          menuButtonTitle={"+ Добавить"}
                          handleAddNewOption={() => setOpenCounterpart(true)}
                        />
                      ) : (
                        <BaseSelect
                          id={"kontragentId"}
                          defaultValue={get(values, "kontragentId", "")}
                          isSearchable
                          value={get(values, "kontragentId.value", "")}
                          options={contractorDropdowns}
                          handleValueLabel={(e: any) => {
                            setFieldValue("kontragentId", e);
                          }}
                          isDisabled={disable}
                        />
                      )
                    }
                  </HasAccess>
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Дата исполнения <br /> договора
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <DatePicker
                    id={"dataIsp"}
                    value={get(values, "dataIsp", null)}
                    dateFormat="dd.MM.yyyy"
                    handleDate={(e) =>
                      setFieldValue("dataIsp", moment(e).format("YYYY-MM-DD"))
                    }
                    disabled={disable}
                  />
                </Col>
              </Row>

              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            {/* right */}
            <Col xs={6}>
              <FormSelect
                id={"sposobOplataId"}
                name={"sposobOplataId"}
                label={"Способ оплаты"}
                isSearchable
                value={get(values, "sposobOplataId.value", "")}
                required={true}
                options={paymentMethodsDropdowns}
                handleValueLabel={(e: any) => {
                  setFieldValue("sposobOplataId", e);
                }}
                isDisabled={disable}
                error={
                  get(errors, "sposobOplataId", null) &&
                  get(touched, "sposobOplataId", null)
                }
              />
              <FormSelect
                id={"usloviyaOplataId"}
                name={"usloviyaOplataId"}
                label={"Условия оплаты"}
                isSearchable
                value={get(values, "usloviyaOplataId.value", "")}
                className="mt-24"
                required={true}
                options={paymentTermsDropdown}
                handleValueLabel={(e: any) => {
                  setFieldValue("usloviyaOplataId", e);
                }}
                isDisabled={disable}
                error={
                  !!get(errors, "usloviyaOplataId", null) &&
                  !!get(touched, "usloviyaOplataId", null)
                }
              />
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Способ доставки
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"sposobDostavkaId"}
                    isSearchable
                    value={get(values, "sposobDostavkaId.value", "")}
                    options={deliveryMethodsDropdowns}
                    handleValueLabel={(e: any) => {
                      setFieldValue("sposobDostavkaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Ставка НДС
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"stavkaNDSId"}
                    isSearchable
                    value={get(values, "stavkaNDSId.value", "")}
                    options={vatRate}
                    handleValueLabel={(e: any) => {
                      setFieldValue("stavkaNDSId", e);
                      // dispatch(
                      //   movementSalesCntSubject({
                      //     data: null,
                      //     type: ACTION.ADD,
                      //   })
                      // );
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-24" justify={"between"}>
            {isEqual(Number(get(values, "tipDogovoraId.value", 1)), 2) &&
              isEqual(disable, false) && (
                <Col xs={12} className="align-center">
                  <FlexBox justify={"space-between"} align={"center"}>
                    <Title bold sm>
                      {PAGE.TABLE_PART}
                      <span className="required_point">*</span>
                    </Title>

                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={() => handleOpenModal()}
                    />
                  </FlexBox>
                </Col>
              )}
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          {isEqual(Number(get(values, "tipDogovoraId.value", 1)), 2) ? (
            <Row>
              <Col xs={12}>
                <Col className="responsive_overflow">
                  <BaseTable
                    tableHeader={[
                      "№",
                      "Товар",
                      "Ед. изм",
                      "Цена",
                      "Скидка",
                      "Количество",
                      "Цена со скидкой",
                      "Сумма",
                      "Сумма НДС",
                      "Всего",
                      "",
                    ]}
                  >
                    {!isEmpty(
                      get(saleContractSubjects, "data.dogProdajaDetails", [])
                    ) ? (
                      get(
                        saleContractSubjects,
                        "data.dogProdajaDetails",
                        []
                      ).map((item: any, index: number) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTovara", "")}</td>
                          <td>{get(item, "imyaEdIzm", "")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "tsena", 0)}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            {get(state, "id", null)
                              ? get(item, "skidka", 0)
                              : get(item, "skidka.prosent", 0)}
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", 0)}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "tsenaSoSkidkoy", 0)}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summa", 0)}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "summaNDS", 0)}
                              decimalScale={2}
                              thousandSeparator={true}
                            />
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "vsego", 0)}
                              decimalScale={2}
                              thousandSeparator={true}
                            />
                          </td>
                          <td>
                            <ActionBase
                              disable={disable}
                              handleUpdate={handleOpenModal}
                              handleDelete={() =>
                                dispatch(
                                  movementSalesCntSubject({
                                    id: get(item, "tovarId", null),
                                    type: ACTION.DELETE,
                                  })
                                )
                              }
                              id={get(item, "tovarId", null)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={11}>{PAGE.NO_DATA}</td>
                      </tr>
                    )}
                  </BaseTable>
                </Col>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={12}>
                <Col className="responsive_overflow"></Col>
              </Col>
            </Row>
          )}
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {isEqual(disable, false) &&
                (get(state, "isUpdate", false) ? (
                  <FormButton
                    loading={isSubmitting}
                    handleClick={handleUpdateRegisterSalces}
                  >
                    {" "}
                    {PAGE.CHANGE}
                  </FormButton>
                ) : (
                  <HasAccess>
                    {({ userCan }: any) =>
                      userCan({
                        permission: `${DOCUMENT_KEY.DOG_PRODAJA}_CREATE`,
                        page: DOCUMENT_KEY.DOG_PRODAJA,
                        department: MAIN_DEPARTMENT.DOCUMENTS,
                        type: PROTECTED_TYPE.PERMISSIONS,
                      }) && (
                        <FormButton
                          loading={isSubmitting}
                          disabled={disable}
                          handleClick={handleSubmit}
                        >
                          {PAGE.DONE}
                        </FormButton>
                      )
                    }
                  </HasAccess>
                ))}

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Space className="centrality">
          <Spin size="large" />
        </Space>
      )}
    </Content>
  );
};

export default AddRegistSalesContractsContainer;
