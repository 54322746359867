import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";

const initialState: any = {
    data: { loading: false, dataList: null, error: "" },
    filter: {
        // fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
        date: moment(new Date()).format("YYYY-MM-DD"),
        vidTovaraId: null,
        kontragentId: null,
        tovarId: null,
        territoriId: null,
        tipListaId: null,

    },
};

export const fetchFulFillmentReports = createAsyncThunk(
    "fetchFullReport/fetchFulFillmentReports",
    async (params: any, thunkAPI) => {
        try {
            const request = await ReportsServices.FetchFulfillmentReports(params);
            const respond = await request.data;
            return respond;
        } catch (error) {
            thunkAPI.rejectWithValue(error);
        }
    }
);

const fulfillmentReportSlice = createSlice({
    name: "fulfillmentReport",
    initialState,
    reducers: {
        filterFulfillment: (state, actions) => {
            const name = actions.payload.name;
            const value = actions.payload.value;

            state.filter = {
                ...state.filter,
                [name]: value,
            };
        },
        clearFilterFulfillment: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFulFillmentReports.pending, (state: any) => {
            state.data = {
                loading: true,
                dataList: [],
                error: "",
            };
        });
        builder.addCase(fetchFulFillmentReports.fulfilled, (state: any, action: any) => {
            console.log(action.payload, "payload")
            state.data = {
                loading: false,
                dataList: get(action, "payload.data", []),
                error: "",
            };
        });
        builder.addCase(fetchFulFillmentReports.rejected, (state: any, payload: any) => {
            state.data = {
                loading: false,
                dataList: [],
                error: payload.error.message,
            };
        });
    },
});

export const { filterFulfillment, clearFilterFulfillment } = fulfillmentReportSlice.actions;
export default fulfillmentReportSlice.reducer;
