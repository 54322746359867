import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";
import { InitialState } from "./type";

const initialState: InitialState = {
    data: { loading: false, finishedList: [], isInitialState: true, error: "" },
    filter: {
        fromDate: moment(new Date()).startOf("year").format("YYYY-MM-DD"),
        toDate: moment(new Date()).subtract(1, "day").format("YYYY-MM-DD"),
        vidTovaraId: null,
        territoriyaId: null,
        sposobOplataId: null,
        kontragentId: null,
        tipLitsaId: null,
        // skladId: null,
        // detalno: true,
    },
};

export const fetchReportFinished = createAsyncThunk(
    "reportFinishedTable/fetchReportFinished",
    async (params: any, thunkAPI) => {
        try {
            const request = await ReportsServices.FetchReportFinishedProducts(params);
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

const reportFinishedTable = createSlice({
    name: "reportFinishedTable",
    initialState,
    reducers: {
        filterFinishedTable: (state, actions) => {
            const name = actions.payload.name;
            const value = actions.payload.value;

            state.filter = { ...state.filter, [name]: value };
        },
        clearFinishedTable: () => initialState,
        changeFinishedTable: (state, action) => {
            state.data = {
                loading: false,
                finishedList: action.payload,
                isInitialState: true,
                error: "",
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReportFinished.pending, (state) => {
            state.data = {
                loading: true,
                finishedList: [],
                isInitialState: true,
                error: "",
            };
        });
        builder.addCase(fetchReportFinished.fulfilled, (state, action) => {
            state.data = {
                loading: false,
                finishedList: get(action, "payload.data", []),
                isInitialState: false,
                error: "",
            };
        });
        builder.addCase(fetchReportFinished.rejected, (state, payload) => {
            state.data = {
                loading: false,
                finishedList: [],
                isInitialState: true,
                error: payload.error.message,
            };
        });
    },
});

export const { filterFinishedTable, clearFinishedTable, changeFinishedTable } =
    reportFinishedTable.actions;
export default reportFinishedTable.reducer;
