import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getDriversData, getMaterialData, getSkladCM, getSkladGP } from "app/states/handbooks/selectStates";
import { reportRawMaterialData, reportRawMaterialFilter } from "app/states/reports";
import {
    BaseSelect,
    Button,
    Content,
    DatePicker,
    FlexBox,
    Title,
} from "components";
import {
    changeCashExpense,
    clearCashExpense,
    fetchCashExpense,
    filterCashExpense,
} from "app/slices/reportSlices/registerCashExpenseSlices/registerCashExpenseSlices";

import RegisterCashExpenseTable from "../components/RegisterCashExpenseTable";
import {
    fetchOwnKassa,
} from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import ExcelJS from "exceljs";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import NotFound from "../components/NotFound";
import { contractorOptions, loadingConterpart } from "app/states/documents/selectStates";
import { fetchOwnSkladCM, fetchOwnSkladGP } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { clearReportRawMaterial, fetchReceiptOFRawMaterials, filterReportRawMaterial } from "app/slices/reportSlices/receiptOfRawMaterialsSlices/reciptOfRawMaterialsSlices";
import ReportsRawMaterialsTable from "../components/ReportRawMaterialsTable";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
type ParamsType = {
    name: string;
    clear?: boolean;
    value: any;
};
type DetailsState = {
    kontragent: string;
    sklad: string;
    voditel: string;
    material: string
}
// Отчет о поступлении сырья и материалов
function ReceiptOfRawMaterialContainer() {
    const dispatch = useAppDispatch();

    // data
    const filter = useAppSelector(reportRawMaterialFilter);
    const data = useAppSelector(reportRawMaterialData);
    const LOADING_TABLE = get(data, "loading", false);
    const contractorDropdowns = useAppSelector(contractorOptions);
    const skladGPDropdowns = useAppSelector(getSkladCM);
    // const skladSM = useAppSelector(getSkladCM);
    const driversDropdowns = useAppSelector(getDriversData);
    const materialOptions = useAppSelector(getMaterialData);
    let loadingKontragent = useAppSelector(loadingConterpart)
    // state
    const [details, setDetails] = useState<DetailsState>({
        kontragent: "",
        sklad: '',
        voditel: '',
        material: ''
    });
    // cash list filter
    const fetchCashExpenseDate = useCallback(() => {
        // setDetails({
        //     kontragent: "",
        //     sklad: '',
        //     voditel: '',
        //     material: ''
        // })
        dispatch(fetchReceiptOFRawMaterials(filter));
    }, [dispatch, filter]);

    const handleClearFilter = () => {
        dispatch(clearReportRawMaterial());
    };
    //  excel download
    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const EXCEL_SHEET = `Отчет о поступлении сырья и материалов ${moment(
            new Date()
        ).format("YYYY-MM-DD")}`;
        const sheet = workbook.addWorksheet(EXCEL_SHEET);
        sheet.mergeCells("A1", `L1`);
        sheet.getCell(
            "A1"
        ).value = `Отчет о поступлении сырья и материалов с ${moment(
            get(filter, "date", 0)
        ).format("DD.MM.YYYY")}.`;
        const arr = [
            {
                label: "Контрагент " + get(details, "kontragent", null),
                value: get(details, "kontragent", ""),
            },
            {
                label: "Склад: " + get(details, "sklad", null),
                value: get(details, "sklad", ""),
            },
            {
                label: "Материал: " + get(details, "material", null),
                value: get(details, "material", ""),
            },
            {
                label: "Tерритория: " + get(details, "territoriya", null),
                value: get(details, "territoriya", ""),
            },
            {
                label: "Водитель: " + get(details, "voditel", null),
                value: get(details, "voditel", ""),
            },
        ];
        console.log("details", details);
        let count = 1;
        arr.forEach((val: any, index: number) => {
            if (val.value != "") {
                sheet.mergeCells(
                    `A${count + 1}`,
                    `L${1 + count}`
                );
                sheet.getCell(`A${1 + count}`).value = val.label;
                count++;
            }
        });
        const rowCompany = sheet.getRow(1);
        rowCompany.height = 20;


        // sheet.getCell("A2").value = `Касса: ${details.kassa}`;
        const tableHeader = [
            {
                header: "№",
                key: "order",
                width: 4,
            },
            {
                header: "Дата документа",
                key: "date",
                width: 20,
            },
            {
                header: "Склад",
                key: "imyaSklad",
                width: 30,
            },
            {
                header: "Контрагент",
                key: "imyaKontragent",
                width: 35,
            },
            {
                header: "Материал",
                key: "imyaMaterial",
                width: 25,
            },
            {
                header: "Количество",
                key: "kol",
                width: 20,
            },
            {
                header: "Ед. измерение",
                key: "edIzm",
                width: 15,
            },
            {
                header: "Цена",
                key: "sena",
                width: 15,
            },
            {
                header: "Всего",
                key: "vsego",
                width: 15,
            },
            {
                header: "Водитель",
                key: "imyaVoditeli",
                width: 30,
            },
            {
                header: "Гос. номер",
                key: "gosNomer",
                width: 15,
            },
            {
                header: "Авто",
                key: "imyaAvto",
                width: 20,
            },
        ];
        const headerValues = tableHeader.map((item) => item.header);
        const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
            key: key,
            ...props,
        }));
        sheet.getRow(count + 2).values = headerValues;
        sheet.columns = headerKeys;
        const headerRow = sheet.getRow(count + 2);
        headerRow.height = 25;
        headerRow.alignment = { vertical: "middle", horizontal: "center" };
        let counterRow = 2;
        // row1.height = 25;
        // row1.alignment = { vertical: "middle", horizontal: "center" };
        get(data, "dataList", [])?.forEach((item: any, index: number) => {
            const row = sheet.addRow({
                order: index + 1,
                date: moment(get(item, "date", "")).format("DD.MM.YYYY"),
                imyaSklad: get(item, "imyaSklad", ""),
                imyaKontragent: get(item, "imyaKontragent", ""),
                imyaMaterial: get(item, "imyaMaterial", ""),
                kol: get(item, "kol", ""),
                edIzm: get(item, "edIzm", ""),
                sena: get(item, "sena", ""),
                vsego: get(item, "vsego", ""),
                imyaVoditeli: get(item, "imyaVoditeli", ""),
                gosNomer: get(item, "gosNomer", ""),
                imyaAvto: get(item, "imyaAvto", ""),
            });

            counterRow += 1;
        });

        const fontBoldCells = [
            "A1",
            "A" + (count + 2),
            "B" + (count + 2),
            "C" + (count + 2),
            "D" + (count + 2),
            "E" + (count + 2),
            "F" + (count + 2),
            "G" + (count + 2),
            "H" + (count + 2),
            "I" + (count + 2),
            "J" + (count + 2),
            "K" + (count + 2),
            "L" + (count + 2),
            "M" + (count + 2),
            "N" + (count + 2),
        ];
        const startCell: any = sheet.getCell(`A${count + 2}`);
        const endCell: any = sheet.getCell(`L${counterRow + count}`);

        for (let row = startCell.row; row <= endCell.row; row++) {
            for (let col = startCell.col; col <= endCell.col; col++) {
                const cell = sheet.getCell(row, col);
                cell.border = {
                    top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
                    left: { style: "thin", color: { argb: "00000000" } },
                    bottom: { style: "thin", color: { argb: "00000000" } },
                    right: { style: "thin", color: { argb: "00000000" } },
                };
            }
        }
        fontBoldCells.forEach(
            (item) =>
            (sheet.getCell(item).font = {
                size: 11,
                bold: true,
            })
        );
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${EXCEL_SHEET}.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    // change
    const handleChangeFilter = ({
        clear = false,
        value,
        name,
        ...params
    }: ParamsType) => {
        if (isEqual(get(filter, `${name}`, ""), value)) {
            return;
        }

        if (clear) {
            dispatch(changeCashExpense(null));
        }

        dispatch(filterReportRawMaterial({ ...filter, name, value }));
    };

    useEffect(() => {
        dispatch(fetchOwnSkladCM({}));
        dispatch(fetchDrivers({ all: true }));
        dispatch(fetchAllCounterpart({ all: true }));
        dispatch(fetchRawMaterial({ all: true }));

        return () => {
            handleClearFilter();
        };
    }, [dispatch]);

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <Content className={"min_height"}>
                        <Row className="mb-16">
                            <Col xs={12}>
                                <Title sm bold>
                                    Отчет о поступлении сырья и материалов
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={7}>
                                <FlexBox gap={"12px"}>
                                    <DatePicker
                                        width={"250px"}
                                        placeholder="Дата начало"
                                        dateFormat="dd.MM.yyyy"
                                        value={get(filter, "fromDate", "")}
                                        handleDate={(e: any) => {
                                            handleChangeFilter({
                                                name: "fromDate",
                                                value: moment(e).format("YYYY-MM-DD"),
                                                clear: true,
                                            });
                                        }}
                                    />

                                    <DatePicker
                                        width={"250px"}
                                        placeholder="Дата конец"
                                        dateFormat="dd.MM.yyyy"
                                        value={get(filter, "toDate", "")}
                                        handleDate={(e: any) => {
                                            handleChangeFilter({
                                                name: "toDate",
                                                value: moment(e).format("YYYY-MM-DD"),
                                                clear: true,
                                            });
                                        }}
                                    />

                                </FlexBox>
                            </Col>
                            <Col className={"text-right"} xs={5}>
                                {!isEmpty(get(data, "dataList", null)) && (
                                    <Button className="mr-8" grey handleClick={downloadExcel}>
                                        Скачать
                                    </Button>
                                )}
                                <Button
                                    green
                                    className={"mr-8"}
                                    handleClick={fetchCashExpenseDate}
                                >
                                    Сформировать
                                </Button>
                                <Button danger handleClick={handleClearFilter}>
                                    Сброс
                                </Button>
                            </Col>
                            {/* elements */}
                            <Col xs={12} className="mt-16">
                                <Row className="mb-16">
                                    <Col xs={12}>
                                        <Title sm bold>
                                            Необязательные параметры
                                        </Title>
                                    </Col>
                                </Row>
                                <FlexBox gap={"10px"}>
                                    <BaseSelect
                                        width={"250px"}
                                        placeholder={"Контрагент"}
                                        value={get(filter, "kontragentId", "")}
                                        options={contractorDropdowns}
                                        isLoading={loadingKontragent}
                                        handleValueLabel={(e: any) => {
                                            handleChangeFilter({
                                                name: "kontragentId",
                                                value: e.value,
                                                clear: true,
                                            });
                                            setDetails({
                                                ...details,
                                                kontragent: get(e, "label", ""),
                                            });
                                            if (data.dataList === null) return;
                                            dispatch(fetchReceiptOFRawMaterials({ ...filter, kontragentId: e.value }));
                                        }}
                                    />

                                    <BaseSelect
                                        width={"200px"}
                                        options={skladGPDropdowns}
                                        placeholder="Склад"
                                        value={get(filter, "skladId", null)}
                                        handleValueLabel={(e: any) => {
                                            if (filter.skladId === e.value) return;
                                            handleChangeFilter({
                                                name: "skladId",
                                                value: e.value,
                                            });
                                            setDetails({
                                                ...details,
                                                sklad: get(e, "label", ""),
                                            });
                                            if (data.dataList === null) return;
                                            dispatch(fetchReceiptOFRawMaterials({ ...filter, skladId: e.value }));
                                        }}
                                    />
                                    <BaseSelect
                                        width={"200px"}
                                        options={materialOptions}
                                        placeholder="Материал"
                                        value={get(filter, "materialId", null)}
                                        handleValueLabel={(e: any) => {
                                            if (filter.materialId === e.value) return;
                                            handleChangeFilter({
                                                name: "materialId",
                                                value: e.value,
                                            });

                                            setDetails({
                                                ...details,
                                                material: get(e, "label", ""),
                                            });
                                            if (data.dataList === null) return;
                                            dispatch(fetchReceiptOFRawMaterials({ ...filter, materialId: e.value }));
                                        }}
                                    />
                                    <BaseSelect
                                        width={"200px"}
                                        options={driversDropdowns}
                                        placeholder="Водитель"
                                        value={get(filter, "voditelId", null)}
                                        handleValueLabel={(e: any) => {
                                            if (filter.voditelId === e.value) return;

                                            handleChangeFilter({
                                                name: "voditelId",
                                                value: e.value,
                                            });

                                            setDetails({
                                                ...details,
                                                voditel: get(e, "label", ""),
                                            });

                                            if (data.dataList === null) return;
                                            dispatch(fetchReceiptOFRawMaterials({ ...filter, voditelId: e.value }));
                                        }}
                                    />
                                </FlexBox>
                            </Col>
                            <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
                                <hr />
                            </Col>
                        </Row>
                        {!LOADING_TABLE ? (
                            <>
                                {data.dataList !== null ? (
                                    <>
                                        <ReportInfoTitle
                                            filter={filter}
                                            details={details}
                                            titleTxt={
                                                "Отчет о поступлении сырья и материалов"
                                            }
                                        />
                                        {!isEmpty(get(data, "dataList", [])) ? (
                                            <ReportsRawMaterialsTable data={data} />
                                        ) : (
                                            <NotFound />
                                        )}
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <TableContentLoader col={6} />
                        )}
                    </Content>
                </Col>
            </Row>
        </div>
    );
}

export default ReceiptOfRawMaterialContainer;
