export enum MAIN_DEPARTMENT {
  HOME = "GLAVNAYA",
  DOCUMENTS = "DOKUMENTI",
  REGISTERS = "REGISTERI",
  KASSA = "KASSA",
  REPORTS = "OTCHETI",
  HANDBOOK = "SPRAVOCHNIKI",
  USERS = "POLZAVATELI",
  ENUMERATIONS = "PERECHISLENIYA",
}

export enum DOCUMENT_KEY {
  DOG_PRODAJA = "DOG_PRODAJA",
  ZAKAZ_NARYAD = "ZAKAZ_NARYAD",
  DOG_POKUPKA = "DOG_POKUPKA",
  PRIXOD_SM = "PRIXOD_SM",
  VOZVRAT_SM = "VOZVRAT_SM",
  PEREMESH_SM = "PEREMESH_SM",
  RUCHNOY_SPISANIYA = "RUCHNOY_SPISANIYA",
  VIPUSK_PF = "VIPUSK_PF",
  IZMENENIE_PF = "IZMENENIE_PF",
  VIPUSK_GP = "VIPUSK_GP",
  IZMENENIE_GP = "IZMENENIE_GP",
  PROCHI_RASXOD = "PROCHI_RASXOD",
  OTGRUZKA_GP = "OTGRUZKA_GP",
  REALIZATSIYA_GP = "REALIZATSIYA_GP",
  VOZVRAT_TOV_OGP = "VOZVRAT_TOV_OGP",
  VOZVRAT_TOV_RGP = "VOZVRAT_TOV_RGP",
  PRIOBR_BET = "PRIOBR_BET",
  PRIXOD_ND = "KASSA_DOC",
  RASXOD_ND = "KASSA_DOC",
  PRIXOD_BD = "PRIXOD_BD",
  RASXOD_BD = "RASXOD_BD",
  ZACHET_AVANS = "ZACHET_AVANS",
  SEBEST = "SEBEST",
  ZAKR_MES = "ZAKR_MES",
  RUCH_PROV = "RUCH_PROV",
  SPISANIYA_BRAK = "SPISANIYA_BRAK",
  PEREMESH_ND = "PEREMESH_ND",
}

export enum KASSA_KEY {
  KASSA_DOC = "KASSA_DOC",
}

export enum REGISTER_KEY {
  REGISTERI = "REGISTERI",
  // There will be one permission for both pages
  // may be change in the future...
}
export enum REPORT_KEY {
  REESTR_DOG_PRODAJA = "REESTR_DOG_PRODAJA",
  REESTR_ZAKAZ_NARYAD = "REESTR_ZAKAZ_NARYAD",
  REESTR_SCHET_FAKTUR = "REESTR_SCHET_FAKTUR",
  OTCHET_BRAK_TOVAR = "OTCHET_BRAK_TOVAR",
  REESTR_DOG_POKUPKA = "REESTR_DOG_POKUPKA",
  MATERIAL_PO_SKLAD = "MATERIAL_PO_SKLAD",
  MATERIAL_PO_OSTATKA = "MATERIAL_PO_OSTATKA",
  PLAN_ZAKUPOK = "PLAN_ZAKUPOK",
  OTCHET_VIPUSK_PF = "OTCHET_VIPUSK_PF",
  OTCHET_VIPUSK_GP = "OTCHET_VIPUSK_GP",
  OTCHET_OTGRUZKA_GP = "OTCHET_OTGRUZKA_GP",
  ZN_ANALIZ = "ZN_ANALIZ",
  VODITELI_PRI_OTRGUZKA = "VODITELI_PRI_OTRGUZKA",
  VODITELI_PRIOBR_BET = "VODITELI_PRIOBR_BET",
  TOVAR_PO_SKLAD = "TOVAR_PO_SKLAD",
  REESTR_PRIXOD_ND = "REESTR_PRIXOD_ND",
  REESTR_RASXOD_ND = "REESTR_RASXOD_ND",
  DVIJENIYU_ND = "DVIJENIYU_ND",
  OTCHET_NOT_REALIZATSIYA_GP = "OTCHET_NOT_REALIZATSIYA_GP",
  OTCHET_REALIZATSIYA_GP = "OTCHET_REALIZATSIYA_GP",
  KASSOVIY_OTCHET = "KASSOVIY_OTCHET",
  ZADOLJENNOST_POKUPATELEY = "ZADOLJENNOST_POKUPATELEY",
  PODOTCHETNIX_SUM = "PODOTCHETNIX_SUM",
  ZADOLJENNOST_POSTAVSHIKAM = "ZADOLJENNOST_POSTAVSHIKAM",
  AKT_SVERKI = "AKT_SVERKI",
  SALDO_VEDOMOST = "SALDO_VEDOMOST",
  TOVAR_PO_OSTATKA = "TOVAR_PO_OSTATKA",
  REESTR_PRIXOD_SM = "REESTR_PRIXOD_SM",
  OTCHET_POSTUPLENI_SM = "OTCHET_POSTUPLENI_SM",
  OTCHET_VIPOLNENI_ZAKAZ = "OTCHET_VIPOLNENI_ZAKAZ",

}
export enum HANDBOOK_KEY {
  BANKI = "BANKI",
  BANK_SCHET = "BANK_SCHET",
  KASSA = "KASSA",
  TIP_FIZ_LITSA = "TIP_FIZ_LITSA",
  TIP_YUR_LITSA = "TIP_YUR_LITSA",
  FIZ_LITSA = "FIZ_LITSA",
  YUR_LITSA = "YUR_LITSA",
  KONTRAGENT = "KONTRAGENT",
  BANK_SCHET_KONTRAGENT = "BANK_SCHET_KONTRAGENT",
  ADDRESS_DOSTAVKI = "ADDRESS_DOSTAVKI",
  USLOVIYA_OPLATA = "USLOVIYA_OPLATA",
  SKLAD = "SKLAD",
  TERRITORIYA = "TERRITORIYA",
  ED_IZM = "ED_IZM",
  SKIDKA = "SKIDKA",
  VID_TOVARA = "VID_TOVARA",
  TOVAR = "TOVAR",
  TOVAR_SENI = "TOVAR_SENI",
  KALKULYATSIYA_TOV = "KALKULYATSIYA_TOV",
  VID_MATERIALA = "VID_MATERIALA",
  SM = "SM",
  MATERIAL_SENI = "MATERIAL_SENI",
  PF = "PF",
  KALKULYATSIYA_PF = "KALKULYATSIYA_PF",
  STAVKA_NDS = "STAVKA_NDS",
  STATYA_RASXODA = "STATYA_RASXODA",
  TIP_AVTO = "TIP_AVTO",
  AVTO = "AVTO",
  VODITELI = "VODITELI",
  POLZOVATELI = "POLZOVATELI",
  KOMPANIYA = "KOMPANIYA",
  SUBKONTO = "SUBKONTO",
  SCHET_SUBKONTO = "SCHET_SUBKONTO",
}

export enum ENUMERATIONS_KEY {
  SPOSOB_OPLATI = "SPOSOB_OPLATI",
  SPOSOB_DOSTAVKI = "SPOSOB_DOSTAVKI",
  TIP_PRIXODA = "TIP_PRIXODA",
  TIP_RASXODA = "TIP_RASXODA",
  TIP_SPISANIYA_SM = "TIP_SPISANIYA_SM",
  TIP_SPISANIYA_BRAK = "TIP_SPISANIYA_BRAK",
  TIP_YUR_FIZ_LITSA = "TIP_YUR_FIZ_LITSA",
  TIP_DOGOVORA = "TIP_DOGOVORA",
  PORYADOK_OKRUGULENIYA = "PORYADOK_OKRUGULENIYA",
}

export enum USERS_KEY {
  POLZAVATELI = "POLZAVATELI",
}
