import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import moment from "moment";
import ReportsServices from "services/apiServices/reports";

const initialState: any = {
    data: { loading: false, dataList: null, error: "" },
    filter: {
        fromDate: moment(new Date()).startOf("month").format("YYYY-MM-DD"),
        toDate: moment(new Date()).format("YYYY-MM-DD"),
        voditelld: null,
        kontragentId: null,
        materialld: null,
        skladld: null,

    },
};

export const fetchReceiptOFRawMaterials = createAsyncThunk(
    "registerWorkOrder/fetchReceiptOFRawMaterials",
    async (params: any, thunkAPI) => {
        try {
            const request = await ReportsServices.FetchReceiptOfRawMaterials(params);
            const respond = await request.data;
            return respond;
        } catch (error) {
            thunkAPI.rejectWithValue(error);
        }
    }
);

const receiptRawMaterialsSlice = createSlice({
    name: "registerRawMaterials",
    initialState,
    reducers: {
        filterReportRawMaterial: (state, actions) => {
            const name = actions.payload.name;
            const value = actions.payload.value;

            state.filter = {
                ...state.filter,
                [name]: value,
            };
        },
        clearReportRawMaterial: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchReceiptOFRawMaterials.pending, (state: any) => {
            state.data = {
                loading: true,
                dataList: [],
                error: "",
            };
        });
        builder.addCase(fetchReceiptOFRawMaterials.fulfilled, (state: any, action: any) => {
            console.log(action.payload, "payload")
            state.data = {
                loading: false,
                dataList: get(action, "payload.data", []),
                error: "",
            };
        });
        builder.addCase(fetchReceiptOFRawMaterials.rejected, (state: any, payload: any) => {
            state.data = {
                loading: false,
                dataList: [],
                error: payload.error.message,
            };
        });
    },
});

export const { filterReportRawMaterial, clearReportRawMaterial } = receiptRawMaterialsSlice.actions;
export default receiptRawMaterialsSlice.reducer;
