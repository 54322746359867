import { createSelector } from "@reduxjs/toolkit";
import {
  selectAnalysisExecution,
  selectAnalysisExecutionFilter,
  selectBalanceSheet,
  selectBalanceSheetFilter,
  // selectBalanceSheet,
  // selectBalanceSheetFilter,
  selectCommodityReport,
  selectCommodityReportFilter,
  selectMaterialReport,
  selectMaterialReportFilter,
  selectOperationalReport,
  selectOperationalReportFilter,
  selectProcurementPlan,
  selectProcurementPlanFilter,
  selectReportReleases,
  selectReportReleasesFilter,
  selectSaleTable,
  selectSaleTableFilter,
  selectCashTableFilter,
  selectCashTableReport,
  selectCashExpenseFilter,
  selectCashExpenseReport,
  selectWorkOrderFilter,
  selectWorkOrderReport,
  selectInvoicesReport,
  selectInvoicesFilter,
  selectReportSemiProduct,
  selectReportSemiProductFilter,
  selectShipmentTable,
  selectShipmentFilter,
  selectReportDefectsReport,
  selectReportDefectsFilter,
  selectRegisterPurchasesReport,
  selectRegisterPurchasesFilter,
  selectReportDriver,
  selectReportDriverFilter,
  selectReportPurchase,
  selectReportPurchaseFilter,
  selectoperationalCashFilter,
  selectOperationalCashTable,
  selectCashReportFilter,
  selectCashReportTable,
  selectBuyerReportFilter,
  selectBuyerReportTable,
  selectSupplierReportFilter,
  selectSupplierReportTable,
  selectAccountableReportFilter,
  selectAccountableReportTable,
  selectReconciliationActFilter,
  selectReconciliationActTable,
  selectReconciliationActDetails,
  selectOperationalProductBalance,
  selectOperationalProductBalanceFilter,
  selectFinishedTable,
  selectFinishedFilter,
  selectSoldFinishedTable,
  selectSoldFinishedFilter,
  selectReportRawMaterials,
  selectReportRawMaterialsFilter,
  selectFulFillmentReport,
  selectFulFillmentFilter,
} from "app/selectors/reports";
import { get } from "lodash";
import {
  selectDriversShipmentMaterialFilter,
  selectDriversShipmentMaterialTable,
} from "../../selectors/reports";

export const salesTableList = createSelector(
  selectSaleTable,
  (salesList) => salesList
);
export const salesTableFilterDate = createSelector(
  selectSaleTableFilter,
  (filter) => filter
);

// work orders
export const workOrderList = createSelector(
  selectWorkOrderReport,
  (orderList) => orderList
);
export const workOrderFilter = createSelector(
  selectWorkOrderFilter,
  (filter) => filter
);

// register invoices
export const invoicesList = createSelector(
  selectInvoicesReport,
  (invoiceList) => invoiceList
);
export const invoicesFilter = createSelector(
  selectInvoicesFilter,
  (filter) => filter
);

// report defetcs
export const reportDefectsList = createSelector(
  selectReportDefectsReport,
  (salesList) => salesList
);
export const reportDefectsFilter = createSelector(
  selectReportDefectsFilter,
  (filter) => filter
);

// register purchases
export const registerPurchasesList = createSelector(
  selectRegisterPurchasesReport,
  (data) => data
);
export const registerPurchasesFilter = createSelector(
  selectRegisterPurchasesFilter,
  (filter) => filter
);

//  material
export const materialReportList = createSelector(
  selectMaterialReport,
  (salesList) => salesList
);
export const materialReportFilter = createSelector(
  selectMaterialReportFilter,
  (filter) => filter
);
//  operational table
export const operationalReportList = createSelector(
  selectOperationalReport,
  (data) => data
);
export const operationalReportFilter = createSelector(
  selectOperationalReportFilter,
  (filter) => filter
);
//  procurement plan table
export const procurementPlanList = createSelector(
  selectProcurementPlan,
  (salesList) => salesList
);
export const procurementPlanFilter = createSelector(
  selectProcurementPlanFilter,
  (filter) => filter
);

// report smi product
export const reportSemiProductsList = createSelector(
  selectReportSemiProduct,
  (salesList) => salesList
);
export const reportSemiProductsFilter = createSelector(
  selectReportSemiProductFilter,
  (filter) => filter
);

//  report release table
export const reportReleaseList = createSelector(
  selectReportReleases,
  (salesList) => salesList
);
export const reportReleasesFilter = createSelector(
  selectReportReleasesFilter,
  (filter) => filter
);
// report cash receipts table
export const reportCashFilter = createSelector(
  selectCashTableFilter,
  (filter) => filter
);
export const reportCashList = createSelector(
  selectCashTableReport,
  (data) => data
);

// cash expense
export const cashExpenseFilter = createSelector(
  selectCashExpenseFilter,
  (filter) => filter
);
export const cashExpenseList = createSelector(
  selectCashExpenseReport,
  (data) => data
);

// oprational cash report
export const operationalCashFilter = createSelector(
  selectoperationalCashFilter,
  (filter) => filter
);
export const operationalCashList = createSelector(
  selectOperationalCashTable,
  (data) => data
);

// cash report
export const cashReportFilter = createSelector(
  selectCashReportFilter,
  (filter) => filter
);
export const cashReportList = createSelector(
  selectCashReportTable,
  (data) => data
);

// buyer report
export const buyerReportFilter = createSelector(
  selectBuyerReportFilter,
  (filter) => filter
);
export const buyerReportList = createSelector(
  selectBuyerReportTable,
  (data) => data
);

// accountable report
export const accountableReportFilter = createSelector(
  selectAccountableReportFilter,
  (filter) => filter
);
export const accountableReportList = createSelector(
  selectAccountableReportTable,
  (data) => data
);

// supplier report
export const reconciliationActFilter = createSelector(
  selectReconciliationActFilter,
  (filter) => filter
);
export const reconciliationActList = createSelector(
  selectReconciliationActTable,
  (data) => data
);

// act
export const supplierReportFilter = createSelector(
  selectSupplierReportFilter,
  (filter) => filter
);
export const supplierReportList = createSelector(
  selectSupplierReportTable,
  (data) => data
);

//  analytics
export const analysisExecutionList = createSelector(
  selectAnalysisExecution,
  (salesList) => salesList
);
export const analysisExecutionFilter = createSelector(
  selectAnalysisExecutionFilter,
  (filter) => filter
);

// report driver
export const reportDriverList = createSelector(
  selectReportDriver,
  (data) => data
);
export const reportDriverFilter = createSelector(
  selectReportDriverFilter,
  (filter) => filter
);

// report purchase
export const reportPurchaseList = createSelector(
  selectReportPurchase,
  (data) => data
);
export const reportPurchaseFilter = createSelector(
  selectReportPurchaseFilter,
  (filter) => filter
);

// commodity
export const commodityReportList = createSelector(
  selectCommodityReport,
  (data) => data
);
export const commodityReportFilter = createSelector(
  selectCommodityReportFilter,
  (filter) => filter
);
// balance
export const balanceSheetList = createSelector(
  selectBalanceSheet,
  (data) => data
);
export const balanceSheetFilter = createSelector(
  selectBalanceSheetFilter,
  (filter) => filter
);

// shipment
export const reportShipmentList = createSelector(
  selectShipmentTable,
  (data) => {
    if (!get(data, "shipmentList", [])) return data;
    const list = get(data, "shipmentList", []) ? get(data, "shipmentList", [])?.map((item, index) => ({ ...item, idx: index + 1 })) : []
    let newArray = [...list];

    for (let i = 0; i < newArray?.length - 1; i++) {
      if (newArray[i].imyaKontragent != newArray[i + 1].imyaKontragent) {
        newArray?.splice(i + 1, 0, {
          "idx": 0,
          "address": "",
          "nomerDoc": "",
          "dataDoc": "",
          "kol": null,
          "imyaKontragent": "",
          "imyaTovar": "",
          "imyaVoditeli": "",
          "edIzm": "",
          "gosNomer": "",
          "imyaTerritori": ""
        })
        i++;
      }
    }
    return {
      ...data,
      shipmentList: newArray,
    }
  }
);
export const reportShipmentFilter = createSelector(
  selectShipmentFilter,
  (filter) => filter
);

// finished
export const reportFinishedList = createSelector(
  selectFinishedTable,
  (data) => {
    if (!get(data, "finishedList", [])) return data;
    const list = get(data, "finishedList", []) ? get(data, "finishedList", [])?.map((item, index) => ({ ...item, idx: index + 1 })) : []
    let newArray = [...list];

    for (let i = 0; i < newArray?.length - 1; i++) {
      if (newArray[i].imyaKontragent != newArray[i + 1].imyaKontragent) {
        newArray?.splice(i + 1, 0, {
          "idx": 0,
          "address": "",
          "comment": "",
          "dataDoc": "",
          "kol": null,
          "imyaKontragent": "",
          "imyaTerritorii": "",
          "imyaTovar": "",
          "zakazNaryad": "",
          "edIzm": "",
          "innNomer": null,
          "phoneNumber": ""
        })
        i++;
      }
    }
    return {
      ...data,
      finishedList: newArray,
    }
  }
)
export const reportFinishedFilter = createSelector(
  selectFinishedFilter,
  (filter) => filter
)

// sold finished
export const reportSoldFinishedList = createSelector(
  selectSoldFinishedTable,
  (data) => {
    if (!get(data, "solsFinishedList", [])) return data;
    let newArray = [...get(data, "solsFinishedList", []).map((item, index) => ({ ...item, idx: index + 1 }))];

    for (let i = 0; i < newArray?.length - 1; i++) {
      if (newArray[i].imyaKontragent != newArray[i + 1].imyaKontragent) {
        newArray?.splice(i + 1, 0, {
          "idx": 0,
          "comment": "",
          "nomerZakazNaryad": "",
          "dataDoc": "",
          "kol": null,
          "imyaKontragent": "",
          "kontragentId": null,
          "imyaTerritorii": "",
          "imyaTovar": "",
          "edIzm": "",
          "phoneNumber": "",
          "vsegoSumma": ""
        })
        i++;
      }
    }
    return {
      ...data,
      solsFinishedList: newArray,
    }
  }
)
export const reportSoldFinishedFilter = createSelector(
  selectSoldFinishedFilter,
  (filter) => filter
)


//  driver shipment material
export const driverMaterialList = createSelector(
  selectDriversShipmentMaterialTable,
  (data) => data
);
export const driversMaterialFilter = createSelector(
  selectDriversShipmentMaterialFilter,
  (filter) => filter
);

export const reportActProjaka = createSelector(
  selectReconciliationActDetails,
  (data) => {
    return get(data, "data.prodajaSet", []).map(
      (item: { dogProdaja: string; dogProdajaId: string }) => ({
        label: get(item, "dogProdaja", ""),
        value: get(item, "dogProdajaId", null),
      })
    );
  }
);

export const reportActPokupki = createSelector(
  selectReconciliationActDetails,
  (data) => {
    return get(data, "data.pokupkiSet", []).map(
      (item: { dogPokupki: string; dogPokupkiId: string }) => ({
        label: get(item, "dogPokupki", ""),
        value: get(item, "dogPokupkiId", null),
      })
    );
  }
);

// L
export const operationalBalance = createSelector(
  selectOperationalProductBalance,
  (data) => data
);
export const operationalBalanceFilter = createSelector(
  selectOperationalProductBalanceFilter,
  (filter) => filter
);
// new 
export const reportRawMaterialData = createSelector(
  selectReportRawMaterials,
  (data) => data
);
export const reportRawMaterialFilter = createSelector(
  selectReportRawMaterialsFilter,
  (filter) => filter
);

export const fulfillmentReportData = createSelector(
  selectFulFillmentReport,
  (data) => data
);
export const fulfillmentReportFilter = createSelector(
  selectFulFillmentFilter,
  (filter) => filter
);