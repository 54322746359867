import React, { useCallback, useEffect } from "react";

import moment from "moment";
import { get, isEmpty, isEqual } from "lodash";
import { Col, Row } from "react-grid-system";
import { useAppDispatch, useAppSelector } from "app/hook";
import { getKassaData } from "app/states/handbooks/selectStates";
import { cashExpenseFilter, cashExpenseList } from "app/states/reports";
import {
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Title,
} from "components";
import {
  changeCashExpense,
  clearCashExpense,
  fetchCashExpense,
  filterCashExpense,
} from "app/slices/reportSlices/registerCashExpenseSlices/registerCashExpenseSlices";

import RegisterCashExpenseTable from "../components/RegisterCashExpenseTable";
import {
  fetchOwnKassa,
} from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import ReportInfoTitle from "../components/ReportInfoTitle";
import TableContentLoader from "../louders/TableContentLoader";
import ExcelJS from "exceljs";
import NotFound from "../components/NotFound";
type ParamsType = {
  name: string;
  clear?: boolean;
  value: any;
};

// Реестр документов по расходу наличных денежных средств
function RegisterCashExpenseContainer() {
  const dispatch = useAppDispatch();

  // data
  const filter = useAppSelector(cashExpenseFilter);
  const data = useAppSelector(cashExpenseList);
  const allKassa = useAppSelector(getKassaData);
  const LOADING_TABLE = get(data, "loading", false);
  //download Excel
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const EXCEL_SHEET = `Реестр документов по расходу наличных денежных средств ${moment(
      new Date()
    ).format("YYYY-MM-DD")}`;
    const sheet = workbook.addWorksheet(EXCEL_SHEET);
    sheet.mergeCells("A1", `I1`);
    const rowCompany = sheet.getRow(1);
    rowCompany.height = 20;
    let count = 1;
    sheet.getCell(
      "A1"
    ).value = `Реестр документов по расходу наличных денежных средствс ${moment(
      get(filter, "fromDate", 0)
    ).format("DD.MM.YYYY")} по ${moment(get(filter, "toDate", 0)).format(
      "DD.MM.YYYY"
    )}.`;

    const tableHeader = [
      {
        header: "№",
        key: "order",
        width: 4,
      },
      {
        header: "Номер документа",
        key: "nomerDoc",
        width: 30,
      },
      {
        header: "Дата документа",
        key: "dataDoc",
        width: 25,
      },
      {
        header: "Контрагент",
        key: "imyaKontragent",
        width: 35,
      },
      {
        header: "Договор",
        key: "dog",
        width: 30,
      },
      {
        header: "Тип расхода",
        key: "imyaTipRasxoda",
        width: 30,
      },
      {
        header: "Сумма",
        key: "summa",
        width: 30,
      },
      {
        header: "Комментарий",
        key: "kommentariya",
        width: 50,
      },
    ];

    const headerValues = tableHeader.map((item) => item.header);
    const headerKeys = tableHeader.map(({ header, key, ...props }) => ({
      key: key,
      ...props,
    }));
    sheet.getRow(count + 2).values = headerValues;
    sheet.columns = headerKeys;
    const headerRow = sheet.getRow(count + 2);
    headerRow.height = 25;
    headerRow.alignment = { vertical: "middle", horizontal: "center" };
    let counterRow = 1;
    get(data, "dataList.list", [])?.forEach((item: any, index: number) => {
      const row = sheet.addRow({
        order: index + 1,
        nomerDoc: get(item, "nomerDoc", ""),
        dataDoc: get(item, "dataDoc", ""),
        imyaKontragent: get(item, "imyaKontragent", ""),
        dog: get(item, "nomerDoc", ""),
        imyaTipRasxoda: get(item, "imyaTipRasxoda", ""),
        summa: Number(get(item, "summa", 0)),
        kommentariya: get(item, "kommentariya", ""),
      });
      // row.height = 25;
      // row.alignment = { vertical: "middle", horizontal: "center" };
      counterRow += 1;
    });
    sheet.mergeCells(`A${counterRow + 3}`, `F${counterRow + 3}`);
    sheet.getCell(`A${counterRow + 3}`).value = "Итого:";
    // sheet.getCell(`G${counterRow + 3}`).value = get(data, 'dataList.totalSum', '').toLocaleString()
    const totSumma = sheet.getCell(`G${counterRow + 3}`);
    totSumma.value = Number(get(data, "dataList.totalSum", 0));

    // Matnni markazini o'zgartiramiz
    // totSumma.alignment = { horizontal: "center" };
    // totSumma.height = 25;
    const fontBoldCells = [
      "A1",
      "A" + (count + 2),
      "B" + (count + 2),
      "C" + (count + 2),
      "D" + (count + 2),
      "E" + (count + 2),
      "F" + (count + 2),
      "G" + (count + 2),
      "H" + (count + 2),
      "I" + (count + 2),
      "J" + (count + 2),
      "K" + (count + 2),
      `A${counterRow + count + 2}`,
      `G${counterRow + count + 2}`,
    ];
    const startCell: any = sheet.getCell(`A${count + 2}`);
    const endCell: any = sheet.getCell(`H${counterRow + count + 2}`);

    for (let row = startCell.row; row <= endCell.row; row++) {
      for (let col = startCell.col; col <= endCell.col; col++) {
        const cell = sheet.getCell(row, col);
        cell.border = {
          top: { style: "thin", color: { argb: "00000000" } }, // Replace 'FF0000FF' with your desired color
          left: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } },
          right: { style: "thin", color: { argb: "00000000" } },
        };
      }
    }
    fontBoldCells.forEach(
      (item) =>
      (sheet.getCell(item).font = {
        size: 11,
        bold: true,
      })
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `${EXCEL_SHEET}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  // cash list filter
  const fetchCashExpenseDate = useCallback(() => {
    if (filter.kassaId) dispatch(fetchCashExpense(filter));
  }, [dispatch, filter]);

  const handleClearFilter = () => {
    dispatch(clearCashExpense());
  };

  // change
  const handleChangeFilter = ({
    clear = false,
    value,
    name,
    ...params
  }: ParamsType) => {
    if (isEqual(get(filter, `${name}`, ""), value)) {
      return;
    }

    if (clear) {
      dispatch(changeCashExpense(null));
    }

    dispatch(filterCashExpense({ ...filter, name, value }));
  };

  useEffect(() => {
    dispatch(fetchOwnKassa({ all: true }));

    return () => {
      handleClearFilter();
    };
  }, [dispatch]);

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Content className={"min_height"}>
            <Row className="mb-16">
              <Col xs={12}>
                <Title sm bold>
                  Обязательные параметры
                </Title>
              </Col>
            </Row>
            <Row>
              <Col xs={7}>
                <FlexBox gap={"12px"}>
                  <DatePicker
                    placeholder="Дата начало"
                    dateFormat="dd.MM.yyyy"
                    value={get(filter, "fromDate", "")}
                    handleDate={(e: any) => {
                      handleChangeFilter({
                        name: "fromDate",
                        value: moment(e).format("YYYY-MM-DD"),
                        clear: true,
                      });
                    }}
                  />

                  <DatePicker
                    placeholder="Дата конец"
                    dateFormat="dd.MM.yyyy"
                    value={get(filter, "toDate", "")}
                    handleDate={(e: any) => {
                      handleChangeFilter({
                        name: "toDate",
                        value: moment(e).format("YYYY-MM-DD"),
                        clear: true,
                      });
                    }}
                  />

                  <BaseSelect
                    options={allKassa}
                    placeholder="Касса"
                    value={get(filter, "kassaId", "")}
                    handleChange={(e: number) => {
                      handleChangeFilter({
                        name: "kassaId",
                        value: e,
                        clear: true,
                      });
                    }}
                  />
                </FlexBox>
              </Col>
              <Col className={"text-right"} xs={5}>
                {!isEmpty(get(data, "dataList.list", null)) && (
                  <Button className="mr-8" grey handleClick={downloadExcel}>
                    Скачать
                  </Button>
                )}
                <Button
                  green
                  className={"mr-16"}
                  handleClick={fetchCashExpenseDate}
                >
                  Сформировать
                </Button>
                <Button danger handleClick={handleClearFilter}>
                  Сброс
                </Button>
              </Col>
              <Col xs={12} className={"mt-16"} style={{ padding: 0 }}>
                <hr />
              </Col>
            </Row>
            {!LOADING_TABLE ? (
              <>
                {data.dataList !== null ? (
                  <>
                    <ReportInfoTitle
                      filter={filter}
                      titleTxt={
                        "Реестр документов по расходу наличных денежных средств"
                      }
                    />
                    {!isEmpty(get(data, "dataList.list", [])) ? (
                      <RegisterCashExpenseTable data={data} />
                    ) : (
                      <NotFound />
                    )}
                  </>
                ) : null}
              </>
            ) : (
              <TableContentLoader col={6} />
            )}
          </Content>
        </Col>
      </Row>
    </div>
  );
}

export default RegisterCashExpenseContainer;
