import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import DocumentServices from "services/apiServices/documents";

const initialState: any = {
    statistic: {
        loading: false,
        data: {},
        error: "",
    },
    filter: {
        tovarId: null,
        fromDate: null,
        toDate: null,
        page: 0,
        size: 10,
    },
    returnedConcretes: {
        loading: false,
        data: [],
        error: "",
    },
    returnedConcretesTemp: {
        loading: false,
        data: [],
        error: "",
    },
};

export const fetchAllReturnedConcreteDrivers = createAsyncThunk(
    "returnConcreteDriver/fetchAllReturnedConcreteDrivers",
    async (params: any, thunkAPI) => {
        try {
            const request = await DocumentServices.GetPrioberBeton(params);
            const respond = await request.data;
            const { data, pagination } = await respond;
            return { data, pagination };
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);

export const fetchAllReturnedConcreteDriversTemp = createAsyncThunk(
    "returnConcreteDriver/fetchAllReturnedConcreteDriversTemp",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.GetPrioberBetonTemp();
            const respond = await request.data;
            const { data } = await respond;
            return { data };
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);
export const fetchReturnConcreteDriversStat = createAsyncThunk(
    "returnMaterials/fetchReturnConcreteDriversStat",
    async (_, thunkAPI) => {
        try {
            const request = await DocumentServices.GetPrioberBetonStatistic();
            const respond = await request.data;
            return respond;
        } catch (err) {
            thunkAPI.rejectWithValue(err);
        }
    }
);
const returnConcreteDriverSlice = createSlice({
    name: "returnConcreteDriver",
    initialState,
    reducers: {
        handleFilterChange: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        handleFilterReturnCD: (state, actions) => {
            state.filter = {
                ...state.filter,
                [actions.payload.name]: actions.payload.value,
            };
        },
        clearReturnDriver: (state) => state = initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllReturnedConcreteDrivers.pending, (state) => {
            state.returnedConcretes = {
                loading: true,
            };
        });
        builder.addCase(
            fetchAllReturnedConcreteDrivers.fulfilled,
            (state, actions) => {
                state.returnedConcretes = {
                    loading: false,
                    data: get(actions, "payload", {}),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchAllReturnedConcreteDrivers.rejected,
            (state, actions) => {
                state.returnedConcretes = {
                    loading: false,
                    data: {},
                    error: get(actions, "error.message", ""),
                };
            }
        );

        builder.addCase(
            fetchAllReturnedConcreteDriversTemp.pending,
            (state) => {
                state.returnedConcretesTemp = {
                    loading: true,
                };
            }
        );
        builder.addCase(
            fetchAllReturnedConcreteDriversTemp.fulfilled,
            (state, actions) => {
                state.returnedConcretesTemp = {
                    loading: false,
                    data: get(actions, "payload", {}),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchAllReturnedConcreteDriversTemp.rejected,
            (state, actions) => {
                state.returnedConcretesTemp = {
                    loading: false,
                    data: {},
                    error: get(actions, "error.message", ""),
                };
            }
        );
        // statistic
        builder.addCase(
            fetchReturnConcreteDriversStat.pending,
            (state) => {
                state.statistic = {
                    loading: true,
                };
            }
        );
        builder.addCase(
            fetchReturnConcreteDriversStat.fulfilled,
            (state, actions) => {
                state.statistic = {
                    loading: false,
                    data: get(actions, "payload.data", {}),
                    error: "",
                };
            }
        );
        builder.addCase(
            fetchReturnConcreteDriversStat.rejected,
            (state, actions) => {
                state.statistic = {
                    loading: false,
                    data: {},
                    error: get(actions, "error.message", ""),
                };
            }
        );

    },
});

export const { handleFilterChange, clearReturnDriver, handleFilterReturnCD } = returnConcreteDriverSlice.actions;
export default returnConcreteDriverSlice.reducer;
