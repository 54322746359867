import { Space, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchOrderRegister,
  fetchOrderRegisterById,
  fetchOrderRegisterByTempId,
  fetchOrderRegisterStat,
  fetchTempsOrderRegisterOrder,
  movementOrderSubject,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import {
  fetchDetailsProductById,
  fetchSalesContracts,
} from "app/slices/documentSlices/registerSalesCntSlice/registerSalesCntSlice";
import { fetchAllDeliveryAddress } from "app/slices/handbookSlices/deleveryAddressSlices/deliveryAddressSlices";
import { fetchProduct } from "app/slices/handbookSlices/productSlices/productSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import { orderRegisterFilter, orderRegisterForm } from "app/states/documents";
import {
  contractorOptions,
  deliveryMethodsOptions,
  getOnlyContractorIdOptions,
  getSortDetailsProduct,
  loadingConterpart,
  loadingDogovar,
  loadingSalesDetails,
} from "app/states/documents/selectStates";
import {
  getDeliveryData,
  getSkladCM,
  getSkladGP,
  getTerritoriesData,
  loadingDeliverAddress,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import col_action from "assets/images/icons/colaction.svg";
import row_action from "assets/images/icons/rowaction.svg";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import { AddAddressModal } from "modules/statics/spravochnik/containers/DeliveryAddressesContainer";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, HANDBOOK_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import HandBookServices from "services/apiServices/spravochnik";
import { AddOrderRegister, ProductDetailsTable } from "types/documentsType";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import { orderRegisterSalesMaterial } from "utilities/schema/document/documentSchemas";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";

// Добавить Оформление Заказ-Наряда
type SubjectProduct = {
  tovarId?: number | null | string | undefined;
  edIzmId: number | null;
  kol: number | null;
  imyaTovara: string;
  imyaEdIzm: string;
  dataDoc?: string | null;
};

export const AddContractSubjects = ({
  handleOpenModal,
  handleCloseModal,
  tipDogProdaja,
  tableList,
  id,
  znId,
  dataDoc,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  tipDogProdaja?: number | string;
  tableList: Array<ProductDetailsTable>;
  id: number | null | undefined;
  znId: string | null;
  dataDoc: string | null;
}) => {
  const getProduct = useAppSelector((store) =>
    getSortDetailsProduct(store, tipDogProdaja)
  );
  const dispatch = useAppDispatch();
  const orderRegisterSubjects = useAppSelector(orderRegisterForm);
  const [loading, setLoading] = useState(false);
  const [tovar, setTovar] = useState(0);
  const details = tableList?.find((res: any) => res.tovarId === id);
  const loadingNew = useAppSelector(loadingSalesDetails);
  const product = getProduct?.find((res: any) => res.value === id);
  const onSubmit = (values: any) => {
    if (values.kol && values.tovarId && dataDoc) {
      HandBookServices.GetProductMaterial({
        kol: get(values, "kol", 0),
        tovarId: get(values, "tovarId.value", 0),
        dataDoc: dataDoc,
      }).then((res: any) => {
        setSubmitting(false);
        const material = {
          ...values,

          imyaTovara: get(values, "tovarId.label", ""),
          tovarId: get(values, "tovarId.value", ""),
          imyaEdIzm: get(values, "tovarId.imyaEdIzm", ""),
          id: get(values, "id", null),
          details: get(res, "data.data.kalkulyatsiyaDetailsList"),
        };
        if (res.status === 200) {
          if (isEmpty(details)) {
            dispatch(movementOrderSubject({ data: material, type: "ADD" }));
          } else {
            dispatch(
              movementOrderSubject({ data: material, type: "UPDATE", id })
            );
          }
          handleCloseModal();
          resetForm();
        }
      });
    }
  };
  const initialValue = {
    tovarId: {
      value: get(details, "tovarId", ""),
      label: get(details, "imyaTovara", ""),
      imyaEdIzm: get(details, "imyaEdIzm", ""),
    },
    edIzmId: get(details, "edIzmId", null),
    kol: get(details, "kol", ""),
    imyaTovara: get(details, "imyaTovara", ""),
    imyaEdIzm: get(details, "imyaEdIzm", ""),
    id: get(details, "id", null),
    limitKol: get(product, "kol", 0),
  };
  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: orderRegisterSalesMaterial,
    onSubmit,
  });

  const getMaxKol = useCallback(() => {
    let tovarDetails = get(
      orderRegisterSubjects,
      "data.zakazNaryadTovars",
      []
    ).find((item: any) => item.tovarId == tovar);
    if (get(tovarDetails, "kol", 0) > 0 && get(values, "limitKol", null)) {
      setFieldValue(
        "limitKol",
        Number(get(values, "limitKol", 0)) - Number(get(tovarDetails, "kol", 0))
      );
    }
  }, [tovar]);

  useEffect(() => {
    getMaxKol();
  }, [tovar]);
  useEffect(() => {
    setLoading(true);
    dispatch(fetchProduct({ all: true, kalkulyatsiya: true }))
      .then((res) => {
        if (res) {
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  }, []);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            {PAGE.ADD_DATA}
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            isLoading={loading || loadingNew}
            id={"tovarId"}
            isSearchable
            label={"Товар"}
            defaultValue={get(values, "tovarId", {})}
            value={get(values, "tovarId.value", "")}
            options={removeValueOption({
              currentOption: getProduct,
              removeValueList: tableList,
              valueName: "tovarId",
            })}
            required={true}
            handleValueLabel={(e: any) => {
              setFieldValue("tovarId", e);
              setFieldValue("edIzmId", get(e, "edIzmId", ""));
              setTovar(e.value);
              setFieldValue("limitKol", get(e, "remainingKolichestva", 0));
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                placeholder={
                  isEqual(tipDogProdaja, 2)
                    ? `до: ${textFormatter(Number(get(values, "limitKol", 0)))}`
                    : ""
                }
                value={get(values, "kol", "") || " "}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                min={1}
                maxLength={40}
              />
              {Number(get(values, "kol", 0)) >
                Number(get(values, "limitKol", 0)) &&
                isEqual(tipDogProdaja, 2) && (
                  <Text xs danger>
                    ограничение количество до:
                    {textFormatter(Number(get(values, "limitKol", 0)))}
                  </Text>
                )}
            </Col>
          </Row>
          <Col xs={12} className="text-right mt-24 mb-16">
            {isEqual(Number(tipDogProdaja), 2) ? (
              <FormButton
                color={"#00c838"}
                // className="mr-8"
                // green
                loading={isSubmitting}
                disabled={
                  Number(get(values, "kol", 0)) >
                  Number(get(values, "limitKol", 0))
                }
                handleClick={handleSubmit}
              // inability={
              //   Number(get(values, "kol", 0)) >
              //   Number(get(values, "limitKol", 0))
              // }
              >
                {get(details, "tovarId", null) ? PAGE.CHANGE : PAGE.ADD}
              </FormButton>
            ) : (
              <FormButton
                color={"#00c838"}
                loading={isSubmitting}
                handleClick={handleSubmit}
              >
                {get(details, "tovarId", null) ? PAGE.CHANGE : PAGE.ADD}
              </FormButton>
            )}
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};
const AddOrderRegisterContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const getTerritory = useAppSelector(getTerritoriesData);
  const getSkladSMlist = useAppSelector(getSkladCM);
  const getSkladGPlist = useAppSelector(getSkladGP);
  const getDeliveryAddress = useAppSelector(getDeliveryData);
  const deliveryMethodsDropdowns = useAppSelector(deliveryMethodsOptions);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const filter = useAppSelector(orderRegisterFilter);
  const [openModal, setOpenModal] = useState(false);
  const orderRegisterSubjects = useAppSelector(orderRegisterForm);
  const [action, setAction] = useState<number | null>(null);
  const [disable, setDisable] = useState(false);
  const [tovarId, setTovarId] = useState<number | null | undefined>(null);
  const [addressModal, setOpenAddressModal] = useState(false);
  const [complete, setComplete] = useState<boolean>(true);
  // loadings
  const LOADING = useMemo(
    () => get(orderRegisterSubjects, "loading", false),
    [orderRegisterSubjects]
  );
  let loadingKontragent = useAppSelector(loadingConterpart);
  let loadingRegisterSales = useAppSelector(loadingDogovar);
  let loadingDelivery = useAppSelector(loadingDeliverAddress);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback((id?: number | null | undefined) => {
    setOpenModal(true);
    setTovarId(id);
  }, []);
  // console.log(get(orderRegisterSubjects, "data.zakazNaryadTovars", []), "data");
  const onSubmit = (values: AddOrderRegister) => {
    const cntrcSubjects = get(
      orderRegisterSubjects,
      "data.zakazNaryadTovars",
      []
    ).map((item: any) => ({
      tovarId: get(item, "tovarId", ""),
      edIzmId: get(item, "edIzmId", ""),
      id: get(item, "id", null),
      kol: get(item, "kol", ""),
    }));
    let valuesToSbm: any = {};
    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = [
        "dateIsp",
        "nomerDoc",
        "dataDoc",
        "dataTsen",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", ""))
    );
    try {
      if (get(state, "isUpdate", false)) {
        DocumentServices.UpdateOrderRegister({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            id: get(state, "id", null),
            dogProdajaNomer: get(values, "dogProdajaId.label", ""),
            kontragentImya: get(values, "kontragentId.label", ""),

            zakazNaryadTovars: !isEmpty(cntrcSubjects) ? cntrcSubjects : [],
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchOrderRegister(filter));
            dispatch(fetchOrderRegisterStat());
            toast.success(MESSAGES.ADDED);
          }
          setSubmitting(false);
        });
      } else {
        DocumentServices.AddOrderRegister({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...valuesToSbm,
            dataDoc: !isEqual(get(values, "dataDoc", null), null)
              ? get(values, "dataDoc", "")
              : moment(new Date()).format("YYYY-MM-DD"),
            zakazNaryadTovars: get(values, "dogProdajaId.value", "")
              ? cntrcSubjects
              : null,
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchOrderRegister(filter));
            toast.success(MESSAGES.ADDED);

            if (get(state, "id", false)) {
              DocumentServices.RemoveOrderRegisterById(
                get(state, "id", "")
              ).then((res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchTempsOrderRegisterOrder());
                }
              });
            }
          }
          setSubmitting(false);
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, isSubmitting, setSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(orderRegisterSubjects, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(orderRegisterSubjects, "data.formIntVls.dataDoc", "") : '',
        dataTsen: get(state, 'id', false) ? get(orderRegisterSubjects, "data.formIntVls.dataTsen", "") : '',
      },
      onSubmit,
    });
  const getSaleContracts = useAppSelector((store) =>
    getOnlyContractorIdOptions(store, get(values, "kontragentId.value", null))
  );

  // submit not fully filled form
  const saveNotFullFilledForm = useCallback(() => {
    const cntrcSubjects = get(
      orderRegisterSubjects,
      "data.zakazNaryadTovars",
      []
    ).map((item: any) => ({
      tovarId: get(item, "tovarId", ""),
      imyaTovara: get(item, "imyaTovara", ""),
      edIzmId: get(item, "edIzmId", ""),
      imyaEdIzm: get(item, "imyaEdIzm", ""),
      kol: get(item, "kol", ""),
    }));

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = [
        "dateIsp",
        "nomerDoc",
        "dataDoc",
        "dataTsen",
      ].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddNotFilledOrderRegister({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          id: get(state, "id", null),
          dogProdajaNomer: get(values, "dogProdajaId.label", ""),
          kontragentImya: get(values, "kontragentId.label", ""),
          imyaSposobDostavka: get(values, "sposobDostavkaId.label", ""),
          imyaAddresaDostavki: get(values, "addresaDostavkiId.label"),
          imyaTerritoriya: get(values, "territoriyaId.label", ""),
          imyaSkladaGP: get(values, "skladGPId.label", ""),
          skladGPId: get(values, "skladGPId.value", ""),
          imyaSklada: get(values, "skladId.label", ""),
          skladId: get(values, "skladId.value", ""),
          imyaEdIzm: get(values, "edIzmId.label", ""),
          tipDogovoraId: get(values, "tipDogovoraId", ""),
          zakazNaryadTovars: !isEmpty(cntrcSubjects) ? cntrcSubjects : [],
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchTempsOrderRegisterOrder());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, orderRegisterSubjects, values]);

  // compted order
  const comptedOrderFunction = useCallback(() => {
    if (!isEmpty(get(state, "id", null))) {
      DocumentServices.CompletedOrder(get(state, "id", null)).then((res) => {
        if (res.status == 200) {
          setComplete(true);

          // navigate(-1);
          dispatch(fetchOrderRegister(filter));
          toast.success(MESSAGES.ADDED);
        }
      });
    }
  }, [get(state, "id", "")]);
  //  filter kontragent and get  document
  const getSearchContract = useCallback(() => {
    if (get(values, "kontragentId.value", null)) {
      dispatch(
        fetchSalesContracts({
          all: true,
          filterPage: "ZAKAZ_NARYAD",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
      dispatch(
        fetchAllDeliveryAddress({
          kontragentId: get(values, "kontragentId.value", null),
          all: true,
        })
      );
    }
  }, [values.kontragentId]);
  //  Сумма полученного аванса
  const getAvans = useCallback(() => {
    if (
      get(values, "kontragentId.value", null) &&
      get(values, "dogProdajaId.value")
    ) {
      try {
        DocumentServices.GetAdvance({
          kontragentId: get(values, "kontragentId.value", null),
          dogProdajaId: get(values, "dogProdajaId.value", null),
        }).then((res: any) => {
          if (get(res, "status", "") == 200) {
            setFieldValue("avans", get(res, "data.data", ""));
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [values.kontragentId, values.dogProdajaId]);

  //  Задолженность контрагента
  const fetchDebts = useCallback(() => {
    if (get(values, "kontragentId.value", "")) {
      try {
        DocumentServices.GetKontragentDebt({
          kontragentId: get(values, "kontragentId.value", null),
        }).then((res) => {
          if (res.status == 200) {
            setFieldValue("doljnost", get(res, "data.data", 0));
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.kontragentId]);
  // get dog proda
  const getDogProdaja = useCallback(() => {
    if (get(values, "dogProdajaId.value", null)) {
      try {
        if (get(state, "id", "")) {
          dispatch(
            fetchDetailsProductById({
              id: get(values, "dogProdajaId.value", ""),
              znId: get(state, "id", ""),
            })
          );
        } else {
          dispatch(
            fetchDetailsProductById({
              id: get(values, "dogProdajaId.value", ""),
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.dogProdajaId]);

  const changeDataIsp = useCallback(() => {
    if (get(values, "dataDoc", "") > get(values, "dateIsp", "")) {
      setFieldValue("dateIsp", null);
    }
  }, [values.dataDoc, values.dateIsp]);

  const getOrderDocNumber = useCallback((year?: string | null) => {
    DocumentServices.GetOrderNumber({ year }).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue('dataDoc', localDate)
        }
        if (isEmpty(get(values, 'dataTsen', ''))) {
          setFieldValue('dataTsen', localDate)
        }
      }
    });
  }, [values]);

  const handleCheckTemp = useCallback(() => {
    if (!isEmpty(get(state, "id", false))) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchOrderRegisterByTempId(get(state, "id", "")));
      } else if (get(state, "isUpdate", false)) {
        dispatch(fetchOrderRegisterById(get(state, "id", ""))).then((res: any) => {
          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            // if (isEmpty(get(values, 'dataDoc', ''))) {
            //   setFieldValue("dataDoc", localDate);
            // }
          }
        });
        dispatch(
          fetchAllCounterpart({ all: true, filterPage: "ZAKAZ_NARYAD" })
        );
        dispatch(fetchRawMaterial({ all: true }));
        if (get(state, "complete", false) === 0) {
          setComplete(false);
        }
      } else {
        dispatch(fetchOrderRegisterById(get(state, "id", "")));
        setDisable(true);
        if (get(state, "complete", false) === 0) {
          setComplete(false);
        }
      }
    } else {
      getOrderDocNumber();
      dispatch(fetchAllCounterpart({ all: true, filterPage: "ZAKAZ_NARYAD" }));
      setDisable(false);
    }
  }, [dispatch, state]);

  useEffect(() => {
    changeDataIsp();
  }, [values.dataDoc, values.dateIsp]);

  useEffect(() => {
    handleCheckTemp();
  }, []);

  useEffect(() => {
    getSearchContract();
  }, [values.kontragentId]);

  useEffect(() => {
    getDogProdaja();
  }, [values.dogProdajaId]);

  useEffect(() => {
    getAvans();
  }, [values.kontragentId, values.dogProdajaId]);

  useEffect(() => {
    fetchDebts();
  }, [values.kontragentId]);

  return (
    <Content className={"min_height"} style={{ position: "relative" }}>
      <ReactModal isOpen={openModal}>
        <AddContractSubjects
          handleCloseModal={handleCloseModal}
          tipDogProdaja={get(values, "tipDogovoraId", 0)}
          tableList={get(orderRegisterSubjects, "data.zakazNaryadTovars", [])}
          id={tovarId}
          znId={get(state, "id", "")}
          dataDoc={get(values, "dataDoc", null)}
        />
      </ReactModal>
      <ReactModal isOpen={addressModal}>
        <AddAddressModal
          handleCloseModal={() => setOpenAddressModal(false)}
          kontragentIdCore={get(values, "kontragentId.value", null)}
          afterSubmitAction={() => {
            dispatch(
              fetchAllDeliveryAddress({
                kontragentId: get(values, "kontragentId.value", null),
                all: true,
              })
            );
          }}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  {PAGE.REQ}
                </Title>

                {isEqual(get(state, "complete", false), 0) ? (
                  <HasAccess>
                    {({ userCan }: any) =>
                      userCan({
                        permission: "ZAKAZ_NARYAD_UPDATE",
                        page: "ZAKAZ_NARYAD",
                        department: "DOKUMENTI",
                        type: PROTECTED_TYPE.PERMISSIONS,
                      }) && (
                        <Button
                          green
                          handleClick={comptedOrderFunction}
                          disabled={complete}
                        >
                          Выполнен
                        </Button>
                      )
                    }
                  </HasAccess>
                ) : (
                  !get(state, "isUpdate", false) && (
                    <Button
                      green
                      handleClick={saveNotFullFilledForm}
                      disabled={disable}
                    >
                      {PAGE.SAVE}
                    </Button>
                  )
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    {PAGE.NUMBER}
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          dateFormat="dd.MM.yyyy"
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                            if (
                              checkDateYear({
                                checkYear: moment(e).format("YYYY-MM-DD"),
                                currentYear: get(values, "dataDoc", null),
                              })
                            ) {
                              getOrderDocNumber(
                                checkDateYear({
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                  currentYear: get(values, "dataDoc", null),
                                })
                              );
                            }
                          }}
                          // maxDate={new Date()}

                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    defaultValue={get(values, "kontragentId", {})}
                    isLoading={loadingKontragent}
                    value={get(values, "kontragentId.value", "")}
                    options={contractorDropdowns}
                    handleValueLabel={(e: any) => {
                      if (!isEqual(values.kontragentId, e)) {
                        setFieldValue("kontragentId", e);
                        // for clear when changes value
                        setFieldValue("dogProdajaId.value", null);
                        setFieldValue("dogProdajaId.label", null);
                        setFieldValue("sposobDostavkaId.value", null);
                        setFieldValue("sposobDostavkaId.label", null);
                        setFieldValue("dateIsp", null);
                        setFieldValue("tipDogovoraId", null);
                        setFieldValue("addresaDostavkiId", null);
                        dispatch(
                          movementOrderSubject({ data: null, type: "ADD" })
                        );
                      } else {
                        setFieldValue("kontragentId", e);
                      }
                    }}
                    isDisabled={
                      disable || loadingRegisterSales || loadingDelivery
                    }
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Договор <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"dogProdajaId"}
                    placeholder={get(values, "dogProdajaId.label", "")}
                    defaultValue={get(values, "dogProdajaId", "")}
                    isSearchable
                    isLoading={loadingRegisterSales}
                    value={get(values, "dogProdajaId.value", "")}
                    options={getSaleContracts}
                    handleValueLabel={(e: any) => {
                      if (!isEqual(values.dogProdajaId, e)) {
                        setFieldValue("dogProdajaId", e);
                        setFieldValue(
                          "sposobDostavkaId.value",
                          get(e, "sposobDostavkaId", "")
                        );
                        setFieldValue(
                          "sposobDostavkaId.label",
                          get(e, "sposobDostavkaName", "")
                        );
                        setFieldValue(
                          "tipDogovoraId",
                          get(e, "tipDogovoraId", 0)
                        );
                        setFieldValue("addresaDostavkiId", null);
                        dispatch(
                          movementOrderSubject({ data: null, type: "ADD" })
                        );
                      } else {
                        setFieldValue("dogProdajaId", e);
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Дата исполнения <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} width={"100%"}>
                  <DatePicker
                    id={"dateIsp"}
                    value={get(values, "dateIsp", null) || ""}
                    dateFormat="dd.MM.yyyy"
                    minDate={moment(get(values, "dataDoc", "")).toDate()}
                    handleDate={(e) =>
                      setFieldValue("dateIsp", moment(e).format("YYYY-MM-DD"))
                    }
                    disabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Способ доставки<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"sposobDostavkaId"}
                    value={get(values, "sposobDostavkaId.value", "")}
                    options={deliveryMethodsDropdowns}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <FormInput
                id={"avans"}
                label={"Сумма полученного аванса"}
                required={true}
                value={get(values, "avans", null)}
                handleValueLabel={(e: number) => {
                  setFieldValue("avans", e);
                }}
                disabled={true}
              />
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                disabled={disable}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Территория <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"territoriyaId"}
                    isSearchable
                    value={get(values, "territoriyaId.value", "")}
                    defaultValue={get(values, "territoriyaId", {})}
                    options={getTerritory}
                    handleValueLabel={(e: any) => {
                      setFieldValue("territoriyaId", e);
                      setFieldValue(
                        "skladGPId.value",
                        get(e, "skladGPId", null)
                      );
                      setFieldValue(
                        "skladGPId.label",
                        get(e, "imyaSkladGP", null)
                      );
                      setFieldValue(
                        "skladId.label",
                        get(e, "imyaSkladSM", null)
                      );
                      setFieldValue("skladId.value", get(e, "skladCMId", null));
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склада ГП<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladGPId"}
                    defaultValue={get(values, "skladGPId", "")}
                    isSearchable
                    options={getSkladGPlist}
                    value={get(values, "skladGPId.value", "")}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склада для списания материалов
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladId"}
                    isSearchable
                    defaultValue={get(values, "skladId", {})}
                    value={get(values, "skladId.value", "")}
                    options={getSkladSMlist}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Адрес доставки<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <HasAccess>
                    {({ userCan }: any) =>
                      userCan({
                        permission: `${HANDBOOK_KEY.ADDRESS_DOSTAVKI}_CREATE`,
                        page: HANDBOOK_KEY.ADDRESS_DOSTAVKI,
                        department: MAIN_DEPARTMENT.HANDBOOK,
                        type: PROTECTED_TYPE.PERMISSIONS,
                      }) ? (
                        <BaseSelect
                          id={"addresaDostavkiId.value"}
                          isSearchable
                          value={get(values, "addresaDostavkiId.value", "")}
                          options={
                            get(values, "kontragentId.value", null)
                              ? getDeliveryAddress
                              : []
                          }
                          isLoading={loadingDelivery}
                          defaultValue={get(values, "addresaDostavkiId", {})}
                          isCustomMenu={
                            get(values, "kontragentId.value", null)
                              ? true
                              : false
                          }
                          menuButtonTitle={"+ Добавить"}
                          handleAddNewOption={() => setOpenAddressModal(true)}
                          handleValueLabel={(e: any) => {
                            setFieldValue("addresaDostavkiId", e);
                          }}
                          isDisabled={disable}
                        />
                      ) : (
                        <BaseSelect
                          id={"addresaDostavkiId.value"}
                          isSearchable
                          value={get(values, "addresaDostavkiId.value", "")}
                          options={
                            get(values, "kontragentId.value", null)
                              ? getDeliveryAddress
                              : []
                          }
                          defaultValue={get(values, "addresaDostavkiId", {})}
                          handleValueLabel={(e: any) => {
                            setFieldValue("addresaDostavkiId", e);
                          }}
                          isDisabled={disable}
                        />
                      )
                    }
                  </HasAccess>
                </Col>
              </Row>
              <FormInput
                id={"doljnost"}
                label={"Задолженность контрагента"}
                required={true}
                value={get(values, "doljnost", null)}
                handleValueLabel={(e: number) => {
                  setFieldValue("doljnost", e);
                }}
                disabled={true}
              />
              {isEqual(get(values, "tipDogovoraId", 1), 1) && (
                <Row align="center" className="mt-24">
                  <Col xs={4}>
                    <Title>
                      Цена товаров на какую дату{" "}
                      <span className="required_point">*</span>
                    </Title>
                  </Col>
                  <Col xs={8} width={"100%"}>
                    <DatePicker
                      id={"dataTsen"}
                      value={get(values, "dataTsen", null)}
                      dateFormat="dd.MM.yyyy"
                      handleDate={(e) =>
                        setFieldValue(
                          "dataTsen",
                          moment(e).format("YYYY-MM-DD")
                        )
                      }
                      disabled={disable}
                      maxDate={new Date(get(values, 'maxDate', new Date()))}
                    // maxDate={new Date()}

                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mt-24">
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>
                {!isEmpty(get(values, "dogProdajaId.value", null)) &&
                  isEqual(disable, false) && (
                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={() => handleOpenModal(null)}
                    />
                  )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={["№", "Товар", "Ед. изм", "Количество", ""]}
                >
                  {!isEmpty(
                    get(orderRegisterSubjects, "data.zakazNaryadTovars", [])
                  ) ? (
                    get(
                      orderRegisterSubjects,
                      "data.zakazNaryadTovars",
                      []
                    ).map((item: SubjectProduct, index: number) => (
                      <>
                        <tr
                          key={get(item, "tovarId", null)}
                          onClick={() =>
                            setAction(action !== index ? index : null)
                          }
                        >
                          <td>{index + 1}</td>
                          <td>{get(item, "imyaTovara", "-")}</td>
                          <td>{get(item, "imyaEdIzm", "-")}</td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={get(item, "kol", "-")}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </td>
                          <td>
                            <FlexBox>
                              {isEqual(action, index) ? (
                                <ReactSVG src={row_action} />
                              ) : (
                                <ReactSVG src={col_action} />
                              )}
                              <ActionBase
                                handleUpdate={handleOpenModal}
                                handleDelete={() =>
                                  dispatch(
                                    movementOrderSubject({
                                      type: ACTION.DELETE,
                                      id: get(item, "tovarId", null),
                                    })
                                  )
                                }
                                disable={disable}
                                id={get(item, "tovarId", null)}
                              />
                            </FlexBox>
                          </td>
                        </tr>
                        {isEqual(action, index) && (
                          <>
                            <tr>
                              <td colSpan={5}>Сырье и материалы</td>
                            </tr>
                            {get(item, "details", []).map(
                              (item: any, idx: number) => (
                                <tr
                                  key={get(item, "id", idx + 1)}
                                  className="material-color"
                                >
                                  <td></td>

                                  <td>{get(item, "imyaMateriala", "-")}</td>
                                  <td>{get(item, "imyaEdIzm", "")}</td>
                                  <td>
                                    <NumericFormat
                                      displayType="text"
                                      value={get(item, "kol", 0)}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                    />
                                  </td>
                                  <td></td>
                                </tr>
                              )
                            )}
                          </>
                        )}
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>{PAGE.NO_DATA}</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>

          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {get(state, "isUpdate", false) ? (
                <FormButton
                  disabled={disable}
                  loading={isSubmitting}
                  handleClick={handleSubmit}
                >
                  {PAGE.CHANGE}
                </FormButton>
              ) : (
                <HasAccess>
                  {({ userCan }: any) =>
                    userCan({
                      permission: `${DOCUMENT_KEY.ZAKAZ_NARYAD}_CREATE`,
                      page: DOCUMENT_KEY.ZAKAZ_NARYAD,
                      department: MAIN_DEPARTMENT.DOCUMENTS,
                      type: PROTECTED_TYPE.PERMISSIONS,
                    }) && (
                      <FormButton
                        disabled={disable}
                        loading={isSubmitting}
                        handleClick={handleSubmit}
                      >
                        {PAGE.DONE}
                      </FormButton>
                    )
                  }
                </HasAccess>
              )}
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Space className="centrality">
          <Spin size="large" />
        </Space>
      )}
    </Content>
  );
};

export default AddOrderRegisterContainer;
