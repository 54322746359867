import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchOrderRegister,
  clearRegisteredOrders,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import {
  fetchAllReturnedConcreteDrivers,
  fetchAllReturnedConcreteDriversTemp,
} from "app/slices/documentSlices/returnConcreteDriverSlice/returnConcreteDriverSlice";
import { fetchAllCounterpart } from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchDrivers } from "app/slices/handbookSlices/driversSlices/driversSlices";
import { fetchTrucks } from "app/slices/handbookSlices/truckSlices/truckSlices";
import { fetchOwnWarHouse } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import { rtrnConcreteDriverFilter } from "app/states/documents";
import {
  contractorOptions,
  loadingConterpart,
  orderRegisterOptionsById,
  loadingOrderData,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import {
  getDriversData,
  getTerritoriesData,
  getTruckData,
  getWarHouseData,
  loadingDrivers,
  loadingTruck,
} from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormSelect,
  LoadingAddPages,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { NumberDocType } from "types/documentsType";
import { checkDateYear } from "utilities/helpers";

// Приобретение бетона у водителей

function AddReturnConcreteDriverContainer() {
  const navigate = useNavigate();
  const filter = useAppSelector(rtrnConcreteDriverFilter);
  const [disable, setDisable] = useState(false);
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const territoryOptions = useAppSelector(getTerritoriesData);
  const kontragentOptions = useAppSelector(contractorOptions);
  const driversOptions = useAppSelector(getDriversData);
  const skladOptions = useAppSelector(getWarHouseData);
  const truck = useAppSelector(getTruckData);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const [products, setProducts] = useState<any>([]);
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [maxKol, setMaxKol] = useState<any>("");
  // loading
  const [LOADING, setLoaidng] = useState(true);
  const LOADING_KONTRAGENT = useAppSelector(loadingConterpart);
  let loadingDive = useAppSelector(loadingDrivers);
  let loadingTruckData = useAppSelector(loadingTruck);
  const initialValues = {
    nomerDoc: "",
    dataDoc: '',
    kommentariya: "",

    zakazNaryadId: "",
    nomerZakazNaryad: "",

    territoriyaId: "",
    imyaTerritoriya: "",

    skladGPId: "",
    imyaSkladGP: "",

    tovarId: "",
    imyaTovar: "",

    kontragentId: "",
    imyaKontragent: "",

    edIzmId: "",
    imyaEdIzm: "",

    voditeliId: "",
    imyaVoditeli: "",

    avtoId: "",
    gosNomer: "",

    tsena: 0,
    kol: 0,

    summa: "",
  };

  const onSubmit = (values: any) => {
    const params: any = {
      nomerDoc: get(values, "nomerDoc", ""),
      dataDoc: get(values, "dataDoc", ""),

      zakazNaryadId: get(values, "zakazNaryadId.value", ""),
      nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),

      kommentariya: get(values, "kommentariya", ""),

      territoriyaId: get(values, "territoriyaId.value", ""),
      imyaTerritoriya: get(values, "territoriyaId.label", ""),

      skladGPId: get(values, "skladGPId.value", ""),
      imyaSkladGP: get(values, "skladGPId.label", ""),

      tovarId: get(values, "tovarId.value", ""),
      imyaTovar: get(values, "tovarId.label", ""),

      kontragentId: get(values, "kontragentId.value", ""),
      imyaKontragent: get(values, "kontragentId.label", ""),

      edIzmId: get(values, "edIzmId.value", ""),
      imyaEdIzm: get(values, "edIzmId.label", ""),

      voditeliId: get(values, "voditeliId.value", ""),
      imyaVoditeli: get(values, "voditeliId.label", ""),

      avtoId: get(values, "avtoId.value", ""),
      gosNomer: get(values, "avtoId.label", ""),

      tsena: get(values, "tsena", ""),
      kol: get(values, "kol", ""),

      summa: get(values, "summa", ""),
    };

    try {
      DocumentServices.AddPrioberBeton({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          dispatch(fetchAllReturnedConcreteDrivers(filter));
          navigate(-1);
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemovePrioberBetonTemp(get(state, "id", "")).then(
              (res) => {
                if (res.status == 200) {
                  dispatch(fetchAllReturnedConcreteDriversTemp());
                }
              }
            );
          }
        } else {
          setSubmitting(false);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      initialValues,
      onSubmit,
    });

  const orderRegisterOption = useAppSelector((store) =>
    orderRegisterOptionsById(store, get(values, "kontragentId", ""))
  );
  let loadingOrderOption = useAppSelector(loadingOrderData);

  const getOrderDocNumber = useCallback(
    (date: NumberDocType) => {
      if (get(state, "isTemp", true)) {
        DocumentServices.PrioberBetonNumber(date).then(({ data }) => {
          setFieldValue("nomerDoc", get(data, "data", ""));
          if (get(data, "transactionTime", "")) {
            const transactionTime = get(data, "transactionTime", "");
            const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localMaxDate);
            if (isEmpty(get(values, 'dataDoc', ''))) {
              setFieldValue("dataDoc", localDate);
            }
          }
        });
      }
    },
    [setFieldValue, state, values]
  );

  const fetchSelectors = useCallback(() => {
    dispatch(
      fetchAllCounterpart({
        all: true,
        filterPage: "VIPUSK_GP",
        vidTovarId: 1,
      })
    );
    dispatch(fetchDrivers({ all: true }));
    dispatch(fetchTrucks({ all: true }));
    dispatch(fetchOwnWarHouse({ all: true }));
  }, [dispatch]);

  const fetchOrderByIds = useCallback(() => {
    dispatch(clearRegisteredOrders());
    dispatch(
      fetchOrderRegister({
        all: true,
        otmVipolneniya: 0,
        vidTovarId: 1,
        filterPage: "VIPUSK_GP",
        kontragentId: get(values, "kontragentId.value", null),
      })
    );
  }, [values.kontragentId]);

  const getOrderProductsById = useCallback(() => {
    if (get(values, "zakazNaryadId.value", null)) {
      setLoadingProduct(true);
      try {
        DocumentServices.GetOrderProductsById({
          zakazNaryadId: get(values, "zakazNaryadId.value", ""),
          vidTovarId: 1,
        }).then(({ data }) => {
          setLoadingProduct(false);

          setProducts(
            get(data, "data", []).map((item: any) => ({
              value: get(item, "tovarId", null),
              label: get(item, "imyaTovara", null),
              maxKol: get(item, "maxKol", 0),
              edIzmId: get(item, "edIzmId", null),
              imyaEdizm: get(item, "imyaEdIzm", ""),
            }))
          );
        });
      } catch (error) {
        setLoadingProduct(false);
        console.log(error);
      }
    }
  }, [values.zakazNaryadId]);

  const saveNotFullFilledForm = useCallback(() => {
    const params: any = {
      nomerDoc: get(values, "nomerDoc", ""),
      dataDoc: get(values, "dataDoc", ""),

      zakazNaryadId: get(values, "zakazNaryadId.value", ""),
      nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),

      territoriyaId: get(values, "territoriyaId.value", ""),
      imyaTerritoriya: get(values, "territoriyaId.label", ""),

      skladGPId: get(values, "skladGPId.value", ""),
      imyaSkladGP: get(values, "skladGPId.label", ""),

      tovarId: get(values, "tovarId.value", ""),
      imyaTovar: get(values, "tovarId.label", ""),

      kontragentId: get(values, "kontragentId.value", ""),
      imyaKontragent: get(values, "kontragentId.label", ""),

      edIzmId: get(values, "edIzmId.value", ""),
      imyaEdIzm: get(values, "edIzmId.label", ""),

      voditeliId: get(values, "voditeliId.value", ""),
      imyaVoditeli: get(values, "voditeliId.label", ""),

      avtoId: get(values, "avtoId.value", ""),
      gosNomer: get(values, "avtoId.label", ""),

      tsena: get(values, "tsena", ""),
      kol: get(values, "kol", ""),

      summa: get(values, "summa", ""),
    };

    try {
      DocumentServices.SavePrioberBetonTemp({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchAllReturnedConcreteDriversTemp());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values]);

  const handleSetInitialValues = (data: any) => {
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kontragentId", {
      value: get(data, "kontragentId", ""),
      label: get(data, "imyaKontragent", ""),
    });
    setFieldValue("zakazNaryadId", {
      value: get(data, "zakazNaryadId", ""),
      label: get(data, "nomerZakazNaryad", ""),
    });
    setFieldValue("territoriyaId", {
      value: get(data, "territoriyaId", ""),
      label: get(data, "imyaTerritoriya", ""),
    });
    setFieldValue("skladGPId", {
      value: get(data, "skladGPId", ""),
      label: get(data, "imyaSkladGP", ""),
    });
    setFieldValue("tovarId", {
      value: get(data, "tovarId", ""),
      label: get(data, "imyaTovar", ""),
    });
    setFieldValue("edIzmId", {
      value: get(data, "edIzmId", ""),
      label: get(data, "imyaEdIzm", ""),
    });
    setFieldValue("voditeliId", {
      value: get(data, "voditeliId", ""),
      label: get(data, "imyaVoditeli", ""),
    });
    setFieldValue("avtoId", {
      value: get(data, "avtoId", ""),
      label: get(data, "gosNomer", ""),
    });
    setFieldValue("tsena", get(data, "tsena", ""));
    setFieldValue("kol", get(data, "kol", ""));

    setFieldValue("summa", get(data, "summa", ""));
  };

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        DocumentServices.FetchPrioberBetonTempById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoaidng(false);
            }
            setLoaidng(false);
          }
        );
      } else {
        DocumentServices.FetchPrioberBetonById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoaidng(false);
            }
            setLoaidng(false);
          }
        );
        setDisable(true);
      }
    } else {
      getOrderDocNumber({});
      setLoaidng(false);
    }
  }, [dispatch, state]);
  // find auto by avto id
  const getGosNumber = useCallback(() => {
    if (get(values, "avtoId.value", null)) {
      const find = truck.find(
        (item) => item.value == get(values, "avtoId.value", 0)
      );
      setFieldValue("avtoId.label", get(find, "label", ""));
    }
  }, [values.voditeliId]);
  const calculateTotalAmnt = useCallback(() => {
    const total = get(values, "kol", 0) * get(values, "tsena", 0);
    setFieldValue("summa", total);
  }, [setFieldValue, values.tsena, values.kol]);

  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    calculateTotalAmnt();
  }, [calculateTotalAmnt]);

  useEffect(() => {
    fetchOrderByIds();
  }, [fetchOrderByIds]);

  useEffect(() => {
    getGosNumber();
  }, [getGosNumber]);

  useEffect(() => {
    if (isEmpty(get(state, "id", null))) getOrderProductsById();
  }, [getOrderProductsById]);

  useEffect(() => {
    if (isEmpty(get(state, "id", null))) fetchSelectors();
  }, [fetchSelectors]);

  return (
    <Content className={"min_height"}>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        name={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            if (checkDateYear({
                              currentYear: get(values, "dataDoc", null),
                              checkYear: moment(e).format("YYYY-MM-DD"),
                            }))
                              getOrderDocNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FormSelect
                id={"voditeliId"}
                isLoading={loadingDive}
                label={"Водитель"}
                placeholder={get(values, "voditeliId.label", "")}
                defaultValue={get(values, "voditeliId", "")}
                isSearchable
                required
                className="mt-24"
                value={get(values, "voditeliId.value", "")}
                options={driversOptions}
                handleValueLabel={(e: any) => {
                  setFieldValue("voditeliId", e);
                  setFieldValue("avtoId.value", get(e, "avto", ""));
                }}
                isDisabled={disable}
              />
              <FormSelect
                label={"Автомобиль"}
                id={"avtoId"}
                required
                className="mt-24"
                defaultValue={get(values, "avtoId", {})}
                isSearchable
                value={get(values, "avtoId.value", "")}
                options={truck}
                isLoading={loadingTruckData}
                handleValueLabel={(e: any) => {
                  setFieldValue("avtoId", e);
                }}
                isDisabled={disable}
              />
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    defaultValue={values.kontragentId}
                    isLoading={LOADING_KONTRAGENT}
                    value={get(values, "kontragentId.value", "")}
                    options={kontragentOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                      if (
                        get(e, "value", "") !=
                        get(values, "kontragentId.value", "")
                      ) {
                        setFieldValue("territoriyaId", "");
                        setFieldValue("zakazNaryadId", "");
                        setFieldValue("skladGPId", "");
                        setFieldValue("tovarId", "");
                        setFieldValue("edIzmId", "");
                        setFieldValue("kol", "");
                        setFieldValue("tsena", 0);
                        setFieldValue("summa", 0);
                        setMaxKol("");
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Заказ-наряд
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    defaultValue={get(values, "zakazNaryadId", {})}
                    id={"zakazNaryadId"}
                    isSearchable
                    isLoading={loadingOrderOption}
                    value={get(values, "zakazNaryadId.value", "")}
                    options={orderRegisterOption}
                    handleValueLabel={(e: any) => {
                      setFieldValue("zakazNaryadId", e);
                      setFieldValue("territoriyaId", {
                        value: get(e, "territory", ""),
                        label: get(e, "imyaTerritory", ""),
                      });
                      setFieldValue("skladGPId", {
                        value: get(e, "skladGPId", ""),
                        label: get(e, "imyaSkladaGP", ""),
                      });
                      if (
                        get(e, "value", "") !=
                        get(values, "zakazNaryadId.value", "")
                      ) {
                        setFieldValue("tovarId", "");
                        setFieldValue("edIzmId", "");
                        setFieldValue("kol", "");
                        setFieldValue("tsena", 0);
                        setFieldValue("summa", 0);
                        setMaxKol("");
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Территория
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"territoriyaId"}
                    isSearchable
                    value={get(values, "territoriyaId.value", "")}
                    defaultValue={get(values, "territoriyaId", "")}
                    handleValueLabel={(e: any) => {
                      setFieldValue("territoriyaId", e);
                    }}
                    options={territoryOptions}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            {/* right */}
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад готовой продукции
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladGPId"}
                    isSearchable
                    value={get(values, "skladGPId.value", "")}
                    defaultValue={get(values, "skladGPId", "")}
                    options={skladOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladGPId", e);
                    }}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Товар
                    <span className="required_point">*</span>{" "}
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tovarId"}
                    isLoading={loadingProduct}
                    isSearchable
                    value={get(values, "tovarId.value", "")}
                    defaultValue={get(values, "tovarId", "")}
                    options={products}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tovarId", e);
                      setFieldValue("edIzmId", {
                        value: get(e, "edIzmId", ""),
                        label: get(e, "imyaEdizm", ""),
                      });
                      setMaxKol(get(e, "maxKol", ""));
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Количество
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseMaskInput
                        id={"kol"}
                        placeholder={`до: ${maxKol}`}
                        value={get(values, "kol", "")}
                        handleInput={(e: number) => {
                          setFieldValue("kol", e);
                        }}
                        disabled={disable}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text sm>Ед.изм:</Text>
                        <BaseSelect
                          id={"edIzmId"}
                          value={get(values, "edIzmId.value", "")}
                          options={unitsMeasurementDropdowns}
                          isDisabled={true}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Цена
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <BaseMaskInput
                    id={"tsena"}
                    placeholder={``}
                    value={get(values, "tsena", "")}
                    handleInput={(e: number) => {
                      setFieldValue("tsena", e);
                    }}
                    // min={1}
                    disabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Сумма
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summa"}
                    value={get(values, "summa", 0)}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.PRIOBR_BET}_CREATE`,
                    page: DOCUMENT_KEY.PRIOBR_BET,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>

              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
}

export default AddReturnConcreteDriverContainer;
