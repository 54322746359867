import { TableWrapper } from "./styles/style";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";

function ReportsRawMaterialsTable({ data }: any) {
    return (
        <Row className="mt-16">
            <Col>
                <Row>
                    <Col className="responsive_overflow">
                        <TableWrapper minWidth={"1400px"} className="mb-16">
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Дата документа</th>
                                    <th style={{ width: "30vh" }}>Склад</th>
                                    <th style={{ width: "30vh" }}>Контрагент</th>
                                    <th>Материал</th>
                                    <th>Количество</th>
                                    <th>Ед. измерение</th>
                                    <th style={{ width: "15vh" }}>Цена</th>
                                    <th style={{ width: "15vh" }}>Всего</th>
                                    <th>Водитель</th>
                                    <th style={{ width: "15vh" }}>Гос. номер</th>
                                    <th>Авто</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(get(data, "dataList", [])) ? (
                                    get(data, "dataList", []).map(
                                        (item: any, index: number) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {moment(get(item, "date", "")).format(
                                                        "DD.MM.YYYY"
                                                    )}
                                                </td>
                                                <td>{get(item, "imyaSklad", "")}</td>
                                                <td>{get(item, "imyaKontragent", "")}</td>
                                                <td>{get(item, "imyaMaterial", "")}</td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "kol", "")}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td>{get(item, "edIzm", "")}</td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "sena", "")}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "vsego", "")}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td>{get(item, "imyaVoditeli", "")}</td>
                                                <td>{get(item, "gosNomer", "")}</td>
                                                <td>{get(item, "imyaAvto", "")}</td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={11}>Виберите параметры</td>
                                    </tr>
                                )}

                            </tbody>
                        </TableWrapper>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ReportsRawMaterialsTable;
