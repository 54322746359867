import { useCallback, useEffect, useState } from "react";

import moment from "moment";
import { useFormik } from "formik";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { forEach, get, isEmpty, isEqual } from "lodash";
import { useAppDispatch, useAppSelector } from "app/hook";
import add_green from "assets/images/icons/add_green.svg";
import { MaterialDetailsType, NumberDocType } from "types/documentsType";
import { ACTION, MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentServices from "services/apiServices/documents";
import { getSkladCM, loadingSklad } from "app/states/handbooks/selectStates";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import {
  returnMaterialsFilter,
  returnMaterialsForm,
} from "app/states/documents";
import { prixodDetails } from "app/slices/documentSlices/receivedMaterialSlices/type";
import { fetchOwnSkladCM } from "app/slices/handbookSlices/wareHouseSlices/wareHouseSlices";
import {
  clearContractorsState,
  clearCounterPartAddress,
  fetchAllCounterpart,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import {
  contractorOptions,
  getPurchaseContractsSubjectData,
  loadingConterpart,
  loadingRegisterPruchase,
} from "app/states/documents/selectStates";
import {
  clearPurchaseProductsState,
  fetchPurchaseContracts,
} from "app/slices/documentSlices/registerPurchaseSlices/registerPurchaseSlices";
import {
  ActionBase,
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import {
  addSubjectsReturnMaterials,
  clearFormDataReturnMaterials,
  fetchNotFilledReturnMaterials,
  fetchReturnMaterials,
  fetchReturnMaterialsById,
  fetchReturnMaterialsByTempId,
  fetchReturnMaterialsStat,
  movementReturnMaterialSub,
} from "app/slices/documentSlices/returnMaterialSlices/returnMaterialSlices";
import { MaterialDetailsSchema } from "utilities/schema/document/documentSchemas";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import { Spin } from "antd";
import axios from "axios";
const cancelMaterial = axios.CancelToken.source();
// Возврат сырья и материалов
const AddNewDocToTable = ({
  handleOpenModal,
  handleCloseModal,
  dogPokupkaId,
  skladId,
  dataTable,
  id,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  skladId?: number;
  dogPokupkaId?: number;
  dataTable?: Array<MaterialDetailsType>;
  id?: number | null | undefined;
}) => {
  const dispatch = useAppDispatch();
  const [loadingMaterial, setLoadingMaterial] = useState<boolean>(false);
  const [materialOptions, setMaterialOptions] = useState<Array<any>>([]);
  const [loadingPrices, setLoadingPrices] = useState<boolean>(false);
  const details = dataTable?.find((value) => value.materialId === id);
  const onSubmit = (values: any) => {
    const res = {
      ...values,
      materialId: get(values, "materialId.value", null),
      material: get(values, "materialId", {}),
      imyaMateriala: get(values, "materialId.label", ""),
      edIzmId: get(values, "edIzmId", ""),
      imyaEdIzm: get(values, "imyaEdIzm", ""),
      kol: get(values, "kol", 0),
    };
    if (isEmpty(details)) {
      dispatch(movementReturnMaterialSub({ data: res, type: ACTION.ADD }));
    } else {
      dispatch(
        movementReturnMaterialSub({ data: res, type: ACTION.UPDATE, id })
      );
    }
    resetForm();
    handleCloseModal();
  };

  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      materialId: {
        value: get(details, "materialId", ""),
        label: get(details, "imyaMateriala", ""),
        edIzmId: get(details, "edIzmId", ""),
        imyaEdIzm: get(details, "imyaEdIzm", ""),
      },
      maxKol: get(details, "maxKol", 0),
      summa: get(details, "summa", ""),
      summaNDS: get(details, "summaNDS", ""),
      sena: get(details, "sena", ""),
      vsego: get(details, "vsego", ""),
      kol: get(details, "kol", ""),
    },
    enableReinitialize: true,
    validationSchema: MaterialDetailsSchema,
    onSubmit,
  });

  const fetchReturnMaterialDataPrice = useCallback(() => {
    setLoadingMaterial(true);
    DocumentServices.FetchReturnMaterialsKolMaterial({
      dogPokupkaId,
      skladId,
    }).then((res: any) => {
      setLoadingMaterial(false);
      const materialList = get(res, "data.data", []).map((item: any) => ({
        value: get(item, "material.id", ""),
        imyaEdIzm: get(item, "imyaEdIzm", ""),
        edIzmId: get(item, "edIzmId", ""),
        label: get(item, "material.imyaMateriala", ""),
        kol: get(item, "kol", ""),
      }));
      setMaterialOptions(materialList);
    });
  }, [dogPokupkaId]);

  const fetchMaterialDetails = useCallback(() => {
    if (get(values, "materialId.value", null) && get(values, "kol", null)) {
      setLoadingPrices(true);
      DocumentServices.FetchReturnMaterialsDetails({
        dogPokupkaId,
        materialId: get(values, "materialId.value", ""),
        kol: get(values, "kol", 0),
      })
        .then((res) => {
          setLoadingPrices(false);
          setFieldValue("imyaEdIzm", get(res, "data.data.imyaEdIzm", ""));
          setFieldValue("edIzmId", get(res, "data.data.edIzmId", ""));
          setFieldValue("sena", get(res, "data.data.sena", ""));
          setFieldValue("summa", get(res, "data.data.summa", ""));
          setFieldValue("vsego", get(res, "data.data.vsego", ""));
          setFieldValue("summaNDS", get(res, "data.data.summaNDS", ""));
        })
        .catch((err) => {
          console.log(err);
          setLoadingPrices(false);
        });
    }
  }, [values.kol, dogPokupkaId, values.materialId]);

  useEffect(() => {
    fetchReturnMaterialDataPrice();
  }, [dogPokupkaId, skladId]);
  useEffect(() => {
    fetchMaterialDetails();
    return () => {
      setMaterialOptions([]);
    };
  }, [dogPokupkaId, values.kol]);

  return (
    <>
      <Col xs={12} className="mt-16 text-left">
        <Title sm bold>
          {PAGE.ADD_DATA}
        </Title>
      </Col>
      <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
        <hr />
      </Col>
      <Form onSubmit={handleSubmit} align={"center"}>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"materialId"}
            label={"Сырьё и материал"}
            required={true}
            isSearchable
            isLoading={loadingMaterial}
            value={get(values, "materialId.value", "")}
            defaultValue={get(values, "materialId", "")}
            options={removeValueOption({
              currentOption: materialOptions,
              removeValueList: dataTable,
              valueName: "materialId",
              isUpdate: get(details, "material", null),
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("materialId", e);
              setFieldValue("maxKol", get(e, "kol", 0));
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                value={get(values, "kol", "")}
                placeholder={`до: ${textFormatter(get(values, "maxKol", 0))}`}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                fixedDecimalScale={false}
              />
              {loadingPrices && (
                <Spin size="small" style={{ marginLeft: "10px" }} />
              )}
              {Number(get(values, "kol", 0)) >
                Number(get(values, "maxKol", 0)) && (
                  <Text xs danger>
                    ограничение количество до:
                    {textFormatter(get(values, "maxKol", 0))}
                  </Text>
                )}
            </Col>
          </Row>

          <FormInput
            label={"Цена"}
            value={get(values, "sena", "")}
            disabled={true}
            required={true}
          />
          <FormInput
            label={"Сумма"}
            value={get(values, "summa", "")}
            thousandSeparator={true}
            fixedDecimalScale={true}
            disabled={true}
            required={true}
          />
          <FormInput
            disabled={true}
            value={get(values, "summaNDS", "")}
            label={"Сумма НДС"}
            thousandSeparator={true}
            fixedDecimalScale={true}
            required={true}
          />
          <FormInput
            label={"Всего"}
            value={get(values, "vsego", "")}
            thousandSeparator={true}
            fixedDecimalScale={true}
            disabled={true}
            required={true}
          />
          <Col xs={12} className="text-right mt-24 mb-16">
            <Button
              className="mr-8"
              type="submit"
              green
              inability={
                Number(get(values, "maxKol", 0)) < Number(get(values, "kol", 0))
                  ? true
                  : false
              }
            >
              {get(details, "materialId", null) ? PAGE.CHANGE : PAGE.ADD}
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              {PAGE.CLOSE}
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddReturnMaterialsContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { state } = useLocation();
  const getWareHouse = useAppSelector(getSkladCM);
  const returnMaterialData = useAppSelector(returnMaterialsForm);
  const filter = useAppSelector(returnMaterialsFilter);
  const [disable, setDisable] = useState(false);
  const getPurchaseContracts = useAppSelector(getPurchaseContractsSubjectData);
  const contractorDropdowns = useAppSelector(contractorOptions);
  const [materialId, setMaterial] = useState<number | null | undefined>();
  // loadings
  let loadingDocument = get(returnMaterialData, "loading", false);
  let isLoadingSklad = useAppSelector(loadingSklad);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  let loadingKontragent = useAppSelector(loadingConterpart);
  let loadingDocOptions = useAppSelector(loadingRegisterPruchase);
  const handleOpenModal = useCallback((id?: number | null | undefined) => {
    setOpenModal(true);
    setMaterial(id);
  }, []);
  const onSubmit = (values: any) => {
    const cntrcSubjects = get(
      returnMaterialData,
      "data.vozvratSMDetailsList",
      []
    ).map((item: prixodDetails) => ({
      materialId: get(item, "materialId", ""),
      edIzmId: get(item, "edIzmId", ""),
      kol: get(item, "kol", ""),
    }));

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc", "kommentariya"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddReturnMaterials({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          vozvratSMDetailsList: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchReturnMaterials(filter));
          dispatch(fetchReturnMaterialsStat());
          toast.success(MESSAGES.ADDED);
          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsReturnMaterials(
              get(state, "id", "")
            ).then((res) => {
              // error
              if (get(res, "status", "") == 200) {
                navigate(-1);
                dispatch(fetchReturnMaterialsStat());
                if (get(state, "id", false)) {
                  DocumentServices.RemoveTempsReturnMaterials(
                    get(state, "id", "")
                  ).then((res) => {
                    if (get(res, "status", "") == 200) {
                      dispatch(fetchNotFilledReturnMaterials());
                    }
                  });
                }
              }
            });
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        ...get(returnMaterialData, "data.formIntVls", {}),
        dataDoc: get(state, 'id', false) ? get(returnMaterialData, "data.formIntVls.dataDoc", '') : ''
      },
      onSubmit,
    });

  const saveNotFullFilledForm = useCallback(() => {
    const cntrcSubjects = get(
      returnMaterialData,
      "data.vozvratSMDetailsList",
      []
    ).map((item: prixodDetails) => ({
      materialId: get(item, "materialId", ""),
      imyaMateriala: get(item, "imyaMateriala", ""),
      imyaEdIzm: get(item, "imyaEdIzm", ""),
      edIzmId: get(item, "edIzmId", ""),
      kol: get(item, "kol", ""),
    }));

    let valuesToSbm: any = {};

    forEach(values, (value: any, key: any) => {
      valuesToSbm[key] = ["dataDoc", "nomerDoc"].includes(key)
        ? value
        : get(value, "value", "");
    });
    try {
      DocumentServices.AddNotFilledReturnMaterials({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          id: get(state, "id", null),
          imyaPodotchet: get(values, "podotchet.label", "Официально"),
          imyaKontragent: get(values, "kontragentId.label", ""),
          imyaSklada: get(values, "skladId.label", ""),
          nomerDogPokupka: get(values, "dogPokupkaId.label", ""),

          vozvratSMDetailsList: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchNotFilledReturnMaterials());
          toast.success(MESSAGES.ADDED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values, returnMaterialData]);

  const getDocNumber = useCallback((date: NumberDocType) => {
    DocumentServices.GetReturnMaterialsNumber(date).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }
      }
    });
  }, [values]);
  const getCounterPartBySkladId = useCallback(() => {
    if (get(values, "skladId.value", null)) {
      dispatch(
        fetchAllCounterpart({
          all: true,
          filterPage: "VOZVRAT_SM",
          skladId: get(values, "skladId.value", null),
        })
      );
    }
  }, [values.skladId]);
  const getSearchByContract = useCallback(() => {
    if (get(values, "kontragentId.value", null)) {
      dispatch(
        fetchPurchaseContracts({
          all: true,
          kontragentId: get(values, "kontragentId.value", null),
          skladId: get(values, "skladId.value", null),
          filterPage: "VOZVRAT_SM",
        })
      );
    }
  }, [values.kontragentId]);

  const handleClickTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchReturnMaterialsByTempId(get(state, "id", "")));
      } else {
        dispatch(fetchReturnMaterialsById(get(state, "id", ""))).then((res: any) => {
          // console.log('first2', res);
          if (get(res, "payload.transactionTime", "")) {
            const transactionTime = get(res, "payload.transactionTime", "");
            const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
            setFieldValue("maxDate", localDate);
            // if (isEmpty(get(values, 'dataDoc', ''))) {
            //   setFieldValue("dataDoc", localDate);
            // }
          }
        });
        setDisable(true);
      }
    } else {
      getDocNumber({});
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (get(values, "skladId.value", null) && !get(state, "id", false)) {
      const cancelReq = dispatch(
        fetchAllCounterpart({
          all: true,
          filterPage: "VOZVRAT_SM",
          skladId: get(values, "skladId.value", null),
        })
      );
      return () => {
        cancelReq.abort();
      };
    }
  }, [values.skladId]);
  useEffect(() => {
    if (get(values, "kontragentId.value", null) && !get(state, "id", false)) {
      const cancelReq = dispatch(
        fetchPurchaseContracts({
          all: true,
          kontragentId: get(values, "kontragentId.value", null),
          skladId: get(values, "skladId.value", null),
          filterPage: "VOZVRAT_SM",
        })
      );
      return () => {
        cancelReq.abort();
      };
    }
  }, [values.kontragentId]);

  useEffect(() => {
    if (!get(state, "id", false)) {
      const req = dispatch(fetchOwnSkladCM({}));
      return () => {
        req.abort();
      };
    }
  }, []);
  useEffect(() => {
    handleClickTemp();
    return () => {
      dispatch(clearFormDataReturnMaterials());
      dispatch(clearPurchaseProductsState());
      cancelMaterial.cancel();
    };
  }, []);

  return (
    <Content className={"min_height"}>
      {/* modal */}
      <ReactModal isOpen={openModal}>
        <AddNewDocToTable
          handleCloseModal={handleCloseModal}
          dogPokupkaId={get(values, "dogPokupkaId.value", "")}
          skladId={get(values, "skladId.value", "")}
          dataTable={get(returnMaterialData, "data.vozvratSMDetailsList", [])}
          id={materialId}
        />
      </ReactModal>

      {!loadingDocument ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button>
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            if (checkDateYear({
                              currentYear: get(values, "dataDoc", null),
                              checkYear: moment(e).format("YYYY-MM-DD"),
                            }))
                              getDocNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Склад сырья и <br />
                    материалов<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladId"}
                    isSearchable
                    value={get(values, "skladId.value", "")}
                    options={getWareHouse}
                    isLoading={isLoadingSklad}
                    handleValueLabel={(e: any) => {
                      if (
                        !isEmpty(get(values, "skladId.value", "")) ||
                        get(values, "skladId.value", "") !== e.value
                      ) {
                        dispatch(addSubjectsReturnMaterials(null));
                        setFieldValue("kontragentId", { label: "", value: "" });
                        setFieldValue("dogPokupkaId", { label: "", value: "" });
                      }

                      setFieldValue("skladId", e);
                    }}
                    isDisabled={
                      disable || loadingKontragent || loadingDocOptions
                    }
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    isSearchable
                    value={get(values, "kontragentId.value", "")}
                    options={contractorDropdowns}
                    isLoading={loadingKontragent}
                    handleValueLabel={(e: any) => {
                      if (e !== values.kontragentId) {
                        setFieldValue("kontragentId", e);

                        setFieldValue("dogPokupkaId", null);
                        dispatch(addSubjectsReturnMaterials(null));
                      } else {
                        setFieldValue("kontragentId", e);
                      }
                    }}
                    isDisabled={disable || loadingDocOptions}
                    defaultValue={get(values, "kontragentId", {})}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Договор<span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"dogPokupkaId"}
                    isSearchable
                    value={get(values, "dogPokupkaId.value", "")}
                    defaultValue={get(values, "dogPokupkaId", {})}
                    options={getPurchaseContracts}
                    handleValueLabel={(e: any) => {
                      if (e !== values.dogPokupkaId) {
                        setFieldValue("dogPokupkaId", e);
                        dispatch(addSubjectsReturnMaterials(null));
                      }
                      setFieldValue("dogPokupkaId", e);
                    }}
                    isLoading={loadingDocOptions}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть<span className="required_point">*</span>
                </Title>

                {isEqual(disable, false) &&
                  !isEmpty(get(values, "dogPokupkaId.value", null)) ? (
                  <ReactSVG
                    src={add_green}
                    className="svg_icon"
                    onClick={() => handleOpenModal()}
                  />
                ) : (
                  ""
                )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Сырьё и материал",
                    "Единица измерения",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "Сумма НДС",
                    "Всего",
                    "",
                  ]}
                >
                  {!isEmpty(
                    get(returnMaterialData, "data.vozvratSMDetailsList", [])
                  ) ? (
                    get(
                      returnMaterialData,
                      "data.vozvratSMDetailsList",
                      []
                    ).map((item: prixodDetails, index: number) => (
                      <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{get(item, "imyaMateriala", "-")}</td>
                        <td>{get(item, "imyaEdIzm", "-")}</td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "kol", "")}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "sena", "")}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summa", "")}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "summaNDS", "")}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={get(item, "vsego", "")}
                            thousandSeparator
                            decimalScale={2}
                          />
                        </td>
                        <td>
                          <ActionBase
                            handleUpdate={handleOpenModal}
                            handleDelete={() =>
                              dispatch(
                                movementReturnMaterialSub({
                                  type: ACTION.DELETE,
                                  id: get(item, "materialId", null),
                                })
                              )
                            }
                            disable={disable}
                            id={get(item, "materialId", null)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={9}>{PAGE.NO_DATA}</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {/* <Button dark className={"mr-16"} type="submit">
              {PAGE.SEAL}
            </Button> */}
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.VOZVRAT_SM}_CREATE`,
                    page: DOCUMENT_KEY.VOZVRAT_SM,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddReturnMaterialsContainer;
