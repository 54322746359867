import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { AuthLayout, MainLayout } from "layouts";
import {
  LoginPage,
  OrderRegisterPage,
  PivotTablePage,
  ReceivedRowMatPage,
  RegistSalesContractsPage,
  ReleaseProductsPage,
  RemainsPage,
  ShipmentProductsPage,
  RegisterPurchasesPage,
  ReturnMaterialsPage,
  MovementMaterialsPage,
  ManualWriteMaterialsPage,
  OutputSemiProducts,
  OtherExpensesPage,
  ReturnProductsPages,
  ArrivalCashPage,
  ExpenseCashPage,
  RegisterParishCashPage,
  RegisterExpenseCashPage,
  OffsetAdvanceBuyersPage,
  CalculateProductsPage,
  CloseMonthPage,
  ManualPostingsPage,
  OperationalReportPage,
  MaterialReportsPage,
  BanksPage,
  ReportOnReleasePage,
  ReportOnShipmentPage,
  AddPurchaseContractsPage,
  PaymentMethodPage,
  AnalysisExecutionPage,
  SalesProductsPage,
  CommodityReportPage,
  BalanceSheetPage,
  AddRegistSalesContractsPage,
  AddOrderRegisterPage,
  AddRecivedRowMatPage,
  AddReturnMaterialsPage,
  AddMovementMaterialPage,
  AddManualWriteMaterialPage,
  BankAccountsPage,
  CheckoutPage,
  PhysicalPersonsPage,
  LegalPersonsPage,
  TypesPhysicalPersonPage,
  TypesLegalPersonsPage,
  CounterpartsPage,
  BankCounterpartsPage,
  DeliveryAddressesPage,
  TermsPaymentPage,
  WarehousesPage,
  TerritoriesPage,
  UnitsMeasurementPage,
  DiscountsPage,
  CalculationPage,
  TypeMaterialPage,
  RawMaterialsPage,
  MaterialPricesPage,
  VatRatesPage,
  ItemsExpenditurePage,
  TruckPage,
  DriversPage,
  UsersPage,
  DetailsCompanyPage,
  SubAccountPage,
  ManagementAccountPage,
  TypeProductPage,
  ProductPage,
  PricesPage,
  DeliveryMethodPage,
  TypeIncomePage,
  TypeFlowPage,
  WriteoffTypePage,
  LegalIndividualPage,
  TypeContractPage,
  RegisterSlicesContractPage,
  WorkOrderRegisterPage,
  RegisterInvoicesPage,
  TruckTypePage,
  RoundOrderPage,
  AddReleaseProductPage,
  AddShipmentProductPage,
  AddSalesProductPage,
  HomePage,
  ProcurementPlanPage,
  AddOutputSemiProductPage,
  SemiProductPage,
  SemiCalculationPage,
  AddReturnProductsPage,
  AddOtherCostsPage,
  ReturnProductBefRealPage,
  AddReturnProductBefRealPage,
  AddRegisterParishCashPage,
  AddArrivalCashPage,
  AddCostInCashPage,
  ReturnConcreteDriversPage,
  AddReturnConcreteDriversPage,
  AddRegisterExpenseCashPage,
  AddOffsetAdvanceBuyerPage,
  AddCalculateProductPage,
  AddCloseMonthPage,
  AddManualPostingPage,
  ReportOnDefectsPage,
  RegisterPurchasesMaterialsPage,
  ReportOnDriversPage,
  ReportOnPurchasePage,
  ReconciliationActPage,
  RegisterCashReceiptsPage,
  RegisterCashExpensePage,
  OperationalCashReportPage,
  CashReportPage,
  BuyersReportPage,
  AccountableReportPage,
  SuppliersReportPage,
  ReportSemiProductsPage,
  SubstandardProductPage,
  AddSubstandardProductPage,
  TypeSubstandardPage,
  UserRolePage,
  TypeRolePage,
  AddTypeRolePage,
  AddUserRolePage,
  ChangeSemiProductPage,
  AddChangeProductPage,
  AddChangeSemiProductPage,
  ChangeProductPage,
  BalanceSheetItemPage,
  LogUsersPage,
  AddMovingCashPage,
  MovingCashPage,
  DriversMaterialPage,
  KassaPage,
  OperationalProductBalancePage,
  ReportOnUnsolFinishedPage,
  ReportOnSoldFinishedPage,
} from "modules";
import {
  Auth,
  Documents,
  Enumerations,
  Handbooks,
  Kassa,
  Main,
  Registers,
  Reports,
  Users,
} from "routers/paths";
import {
  DocumentsInnerLayout,
  EnumerationsInnerLayout,
  HandbooksInnerLayout,
  KassaInnerLayout,
  RegisterInnerLayout,
  ReportsInnerLayout,
  UsersInnerLayout,
} from "layouts/InnerLayouts";
import ProtectedRoute from "./ProtectedRoute";
import ShowLogsPage from "modules/users/pages/ShowLogsPage";
import {
  DOCUMENT_KEY,
  ENUMERATIONS_KEY,
  HANDBOOK_KEY,
  KASSA_KEY,
  MAIN_DEPARTMENT,
  REGISTER_KEY,
  REPORT_KEY,
} from "./constants";
import NotFount from "./NotFount";
import ReceiptOfRawMaterialContainer from "modules/reports/containers/ReceiptOfRawMaterialContainer";
import FulFillmentReportContainer from "modules/reports/containers/FulfillmentReportContainer";
/**
 *
 */
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* main */}
      <Route path={Main.HOME} element={<MainLayout />}>
        <Route
          path={Main.HOME}
          element={
            <ProtectedRoute
              page={MAIN_DEPARTMENT.HOME}
              department={MAIN_DEPARTMENT.HOME}
            >
              <HomePage />
            </ProtectedRoute>
          }
        />
        {/* documents */}
        <Route path={Main.DOCUMENTS} element={<DocumentsInnerLayout />}>
          <Route
            path={Documents.SALES_CONTRACTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.DOG_PRODAJA}
              >
                <RegistSalesContractsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_REGIST_SALES}
              element={<AddRegistSalesContractsPage />}
            />
          </Route>
          <Route
            path={Documents.REGISTER_ORDERS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.ZAKAZ_NARYAD}
              >
                <OrderRegisterPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_ORDER_REGISTER}
              element={<AddOrderRegisterPage />}
            />
          </Route>
          <Route
            path={Documents.REGISTER_PURCHASES}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.DOG_POKUPKA}
              >
                <RegisterPurchasesPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_PURCHASE_CONTRACTS}
              element={<AddPurchaseContractsPage />}
            />
          </Route>
          <Route
            path={Documents.RECEIVING_MATERIAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PRIXOD_SM}
              >
                <ReceivedRowMatPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_RECEIVED_ROWMAT}
              element={<AddRecivedRowMatPage />}
            />
          </Route>
          <Route
            path={Documents.RETURN_MATERIAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.VOZVRAT_SM}
              >
                <ReturnMaterialsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_RETURN_MATERIAL}
              element={<AddReturnMaterialsPage />}
            />
          </Route>
          <Route
            path={Documents.MOVEMENT_MATERIAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PEREMESH_SM}
              >
                <MovementMaterialsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_MOVEMENT_MATERIAL}
              element={<AddMovementMaterialPage />}
            />
          </Route>
          <Route
            path={Documents.MANUAL_MATERIAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.RUCHNOY_SPISANIYA}
              >
                <ManualWriteMaterialsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_MANUAL_MATERIAL}
              element={<AddManualWriteMaterialPage />}
            />
          </Route>
          <Route
            path={Documents.OUTPUT_SEMI}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.VIPUSK_PF}
              >
                <OutputSemiProducts />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_SEMI_PRODUCT}
              element={<AddOutputSemiProductPage />}
            />
          </Route>
          <Route
            path={Documents.OUTPUT_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.VIPUSK_GP}
              >
                <ReleaseProductsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_RELEASE_PRODUCT}
              element={<AddReleaseProductPage />}
            />
          </Route>
          <Route
            path={Documents.OTHER_EXPENSES}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PROCHI_RASXOD}
              >
                <OtherExpensesPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_OTHER_EXPENSES}
              element={<AddOtherCostsPage />}
            />
          </Route>
          <Route
            path={Documents.SHIPMENT_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.OTGRUZKA_GP}
              >
                <ShipmentProductsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_SHIPMENT_PRODUCT}
              element={<AddShipmentProductPage />}
            />
          </Route>
          <Route
            path={Documents.CHANGE_SEMI_PRODUCT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.IZMENENIE_PF}
              >
                <ChangeSemiProductPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_SEMI_CHANGE_PRODUCT}
              element={<AddChangeSemiProductPage />}
            />
          </Route>
          <Route
            path={Documents.CHANGE_PRODUCT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.IZMENENIE_GP}
              >
                <ChangeProductPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_CHANGE_PRODUCT}
              element={<AddChangeProductPage />}
            />
          </Route>
          <Route
            path={Documents.RETURN_PRODUCT_BEFORE_REAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.VOZVRAT_TOV_OGP}
              >
                <ReturnProductBefRealPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_RETURN_PRODUCT_BEFORE_REAL}
              element={<AddReturnProductBefRealPage />}
            />
          </Route>
          <Route
            path={Documents.SALES_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.REALIZATSIYA_GP}
              >
                <SalesProductsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_SALES_PRODUCT}
              element={<AddSalesProductPage />}
            />
          </Route>
          <Route
            path={Documents.RETURN_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.VOZVRAT_TOV_RGP}
              >
                <ReturnProductsPages />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_RETURN_PRODUCTS}
              element={<AddReturnProductsPage />}
            />
          </Route>
          <Route
            path={Documents.RETURN_CONCRETE_DRIVERS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PRIOBR_BET}
              >
                <ReturnConcreteDriversPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_RETURN_CONCRETE_DRIVERS}
              element={<AddReturnConcreteDriversPage />}
            />
          </Route>
          <Route
            path={Documents.ARRIVAL_CASH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PRIXOD_ND}
              >
                <ArrivalCashPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_ARRIVAL_CASH}
              element={<AddArrivalCashPage />}
            />
          </Route>
          <Route
            path={Documents.EXPENSE_CASH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.RASXOD_ND}
              >
                <ExpenseCashPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_COSTS_IN_CASH}
              element={<AddCostInCashPage />}
            />
          </Route>
          <Route
            path={Documents.REGISTER_PARISH_CASH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PRIXOD_BD}
              >
                <RegisterParishCashPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_REGISTER_PARISH_CASH}
              element={<AddRegisterParishCashPage />}
            />
          </Route>
          <Route
            path={Documents.REGISTER_EXPENSE_CASH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.RASXOD_BD}
              >
                <RegisterExpenseCashPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_REGISTER_EXPENSE_CASH}
              element={<AddRegisterExpenseCashPage />}
            />
          </Route>

          <Route
            path={Documents.OFFSET_ADVANCE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.ZACHET_AVANS}
              >
                <OffsetAdvanceBuyersPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_OFFSET_ADVANCE}
              element={<AddOffsetAdvanceBuyerPage />}
            />
          </Route>

          <Route
            path={Documents.CALCULATE_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.SEBEST}
              >
                <CalculateProductsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_CALCULATE_PRODUCT}
              element={<AddCalculateProductPage />}
            />
          </Route>
          <Route
            path={Documents.CLOSE_MONTH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.ZAKR_MES}
              >
                <CloseMonthPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_CLOSE_MONTH}
              element={<AddCloseMonthPage />}
            />
          </Route>
          <Route
            path={Documents.MANUAL_POSTINGS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.RUCH_PROV}
              >
                <ManualPostingsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_MANUAL_POSTING}
              element={<AddManualPostingPage />}
            />
          </Route>
          <Route
            path={Documents.SUBSTANDARD_PRODUCT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.VOZVRAT_TOV_OGP}
              >
                <SubstandardProductPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_SUBSTANDARD_PRODUCT}
              element={<AddSubstandardProductPage />}
            />
          </Route>

          <Route
            path={Documents.MOVING_CASH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.DOCUMENTS}
                page={DOCUMENT_KEY.PEREMESH_ND}
              >
                <MovingCashPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Documents.ADD_MOVING_CASH}
              element={<AddMovingCashPage />}
            />
          </Route>
        </Route>
        <Route path={Main.KASSA} element={<KassaInnerLayout />}>
          <Route
            index
            path={Kassa.KASSA}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.KASSA}
                page={KASSA_KEY.KASSA_DOC}
              >
                <KassaPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={Main.REGISTERS} element={<RegisterInnerLayout />}>
          <Route
            path={Registers.PIVOT_TABLE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REGISTERS}
                page={REGISTER_KEY.REGISTERI}
              >
                <PivotTablePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Registers.REMAINS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REGISTERS}
                page={REGISTER_KEY.REGISTERI}
              >
                <RemainsPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={Main.REPORTS} element={<ReportsInnerLayout />}>
          <Route
            path={Reports.REGISTER_SLICES_CONTRACT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.REESTR_DOG_PRODAJA}
              >
                <RegisterSlicesContractPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.WORK_ORDER_REGISTER}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.REESTR_ZAKAZ_NARYAD}
              >
                <WorkOrderRegisterPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_DEFECTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_BRAK_TOVAR}
              >
                <ReportOnDefectsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_PURCHASE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_BRAK_TOVAR}
              >
                <ReportOnPurchasePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REGISTER_INVOICE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.REESTR_SCHET_FAKTUR}
              >
                <RegisterInvoicesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.MATERIAL_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.MATERIAL_PO_SKLAD}
              >
                <MaterialReportsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.OPERATIONAL_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.MATERIAL_PO_OSTATKA}
              >
                <OperationalReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_RELEASE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_VIPUSK_GP}
              >
                <ReportOnReleasePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_SHIPMENT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_OTGRUZKA_GP}
              >
                <ReportOnShipmentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_FINISHED}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_NOT_REALIZATSIYA_GP}
              >
                <ReportOnUnsolFinishedPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_SOLD}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_REALIZATSIYA_GP}
              >
                <ReportOnSoldFinishedPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.ANALYSIS_EXECUTION}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.ZN_ANALIZ}
              >
                <AnalysisExecutionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_DRIVERS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.VODITELI_PRI_OTRGUZKA}
              >
                <ReportOnDriversPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.DRIVERS_SHIPMENT_MATERIAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.REESTR_PRIXOD_SM}
              >
                <DriversMaterialPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REGISTER_PURCHASES_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.VODITELI_PRIOBR_BET}
              >
                <RegisterPurchasesMaterialsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.COMMODITY_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.TOVAR_PO_SKLAD}
              >
                <CommodityReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.BALANCE_SHEET}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.SALDO_VEDOMOST}
              >
                <BalanceSheetPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={Reports.REPORT_BALANCE_SHEET_ITEM}
              element={
                <ProtectedRoute
                  department={MAIN_DEPARTMENT.REPORTS}
                  page={REPORT_KEY.SALDO_VEDOMOST}
                >
                  <BalanceSheetItemPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path={Reports.RECONCILIATION_ACT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.AKT_SVERKI}
              >
                <ReconciliationActPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REGISTER_CASH_RECEIPTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.REESTR_PRIXOD_ND}
              >
                <RegisterCashReceiptsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REGISTER_CASH_EXPENSE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.REESTR_RASXOD_ND}
              >
                <RegisterCashExpensePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.OPERATIONAL_REPORT_CASH}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.DVIJENIYU_ND}
              >
                <OperationalCashReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.CASH_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.KASSOVIY_OTCHET}
              >
                <CashReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.BUYERS_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.ZADOLJENNOST_POKUPATELEY}
              >
                <BuyersReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.ACCOUNTABLE_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.PODOTCHETNIX_SUM}
              >
                <AccountableReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.SUPPLIERS_REPORT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.ZADOLJENNOST_POSTAVSHIKAM}
              >
                <SuppliersReportPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.PROCUREMENT_PLAN}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.PLAN_ZAKUPOK}
              >
                <ProcurementPlanPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.REPORT_SEMI_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_VIPUSK_PF}
              >
                <ReportSemiProductsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.OPERATIONAL_REPORT_BALANCE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.TOVAR_PO_OSTATKA}
              >
                <OperationalProductBalancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.RECEIPT_OF_RAW_MATERIALS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_POSTUPLENI_SM}
              >
                <ReceiptOfRawMaterialContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path={Reports.FULFILLMENT_REPORTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.REPORTS}
                page={REPORT_KEY.OTCHET_VIPOLNENI_ZAKAZ}
              >
                <FulFillmentReportContainer />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={Main.HANDBOOK} element={<HandbooksInnerLayout />}>
          <Route
            path={Handbooks.BANKS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.BANKI}
              >
                <BanksPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.BANK_ACCOUNTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.BANK_SCHET}
              >
                <BankAccountsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.CHECKOUT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.KASSA}
              >
                <CheckoutPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.PHYSICAL_PERSONS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.FIZ_LITSA}
              >
                <PhysicalPersonsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.LEGAL_PERSONS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.YUR_LITSA}
              >
                <LegalPersonsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TYPES_PHYSICAL_PERSONS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.TIP_FIZ_LITSA}
              >
                <TypesPhysicalPersonPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TYPES_LEGAL_PERSONS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.TIP_YUR_LITSA}
              >
                <TypesLegalPersonsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.COUNTERPARTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.KONTRAGENT}
              >
                <CounterpartsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.BANK_COUNTERPARTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.BANK_SCHET_KONTRAGENT}
              >
                <BankCounterpartsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.DELIVERY_ADDRESSES}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.ADDRESS_DOSTAVKI}
              >
                <DeliveryAddressesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TERMS_PAYMENT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.USLOVIYA_OPLATA}
              >
                <TermsPaymentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.WAREHOUSES}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.SKLAD}
              >
                <WarehousesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TERRITORIES}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.TERRITORIYA}
              >
                <TerritoriesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.UNITS_MEASUREMENT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.ED_IZM}
              >
                <UnitsMeasurementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.DISCOUNTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.SKIDKA}
              >
                <DiscountsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TYPE_PRODUCT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.VID_TOVARA}
              >
                <TypeProductPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.PRODUCT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.TOVAR}
              >
                <ProductPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.PRICE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.TOVAR_SENI}
              >
                <PricesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.CALCULATION}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.KALKULYATSIYA_TOV}
              >
                <CalculationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TYPE_MATERIAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.VID_MATERIALA}
              >
                <TypeMaterialPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.RAW_MATERIALS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.SM}
              >
                <RawMaterialsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.MATERIAL_PRICE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.MATERIAL_SENI}
              >
                <MaterialPricesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.SEMI_PRODUCTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.PF}
              >
                <SemiProductPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.SEMI_CALCULATION}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.KALKULYATSIYA_PF}
              >
                <SemiCalculationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.VAT_RATES}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.STAVKA_NDS}
              >
                <VatRatesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.ITEMS_EXPENDITURE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.STATYA_RASXODA}
              >
                <ItemsExpenditurePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TRUCK_TYPE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.TIP_AVTO}
              >
                <TruckTypePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.TRUCK}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.AVTO}
              >
                <TruckPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.DRIVERS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.VODITELI}
              >
                <DriversPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.USERS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.POLZOVATELI}
              >
                <UsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.DETAILS_COMPANY}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.KOMPANIYA}
              >
                <DetailsCompanyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.SUB_ACCOUNT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.SUBKONTO}
              >
                <SubAccountPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Handbooks.MANAGEMENT_ACCOUNTS}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.HANDBOOK}
                page={HANDBOOK_KEY.SCHET_SUBKONTO}
              >
                <ManagementAccountPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={Main.ENUMERATIONS} element={<EnumerationsInnerLayout />}>
          <Route
            path={Enumerations.PAYMENT_METHOD}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.SPOSOB_OPLATI}
              >
                <PaymentMethodPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.DELIVER_METHOD}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.SPOSOB_DOSTAVKI}
              >
                <DeliveryMethodPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.TYPE_INCOME}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.TIP_PRIXODA}
              >
                <TypeIncomePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.TYPE_FLOW}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.TIP_RASXODA}
              >
                <TypeFlowPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.WRITEOFF_TYPE}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.TIP_SPISANIYA_SM}
              >
                <WriteoffTypePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.LEGAL_INDIVIDUAL}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.TIP_YUR_FIZ_LITSA}
              >
                <LegalIndividualPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.TYPE_CONTRACT}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.TIP_DOGOVORA}
              >
                <TypeContractPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.ROUND_ORDER}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.PORYADOK_OKRUGULENIYA}
              >
                <RoundOrderPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={Enumerations.TYPE_SUBSTANDARD}
            element={
              <ProtectedRoute
                department={MAIN_DEPARTMENT.ENUMERATIONS}
                page={ENUMERATIONS_KEY.TIP_SPISANIYA_BRAK}
              >
                <TypeSubstandardPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={Main.USERS}
          element={
            <ProtectedRoute
              department={MAIN_DEPARTMENT.USERS}
              page={MAIN_DEPARTMENT.USERS}
            >
              <UsersInnerLayout />
            </ProtectedRoute>
          }
        >
          <Route path={Users.USER_ROLE} element={<UserRolePage />}>
            <Route path={Users.ADD_USER_ROLE} element={<AddUserRolePage />} />
          </Route>
          <Route path={Users.TYPE_ROLE} element={<TypeRolePage />}>
            <Route path={Users.ADD_TYPE_ROLE} element={<AddTypeRolePage />} />
          </Route>
          <Route path={Users.LOG_USERS} element={<LogUsersPage />}>
            <Route path={Users.SHOW_LOG} element={<ShowLogsPage />} />
          </Route>
        </Route>
      </Route>
      {/* for auth */}
      <Route element={<AuthLayout />}>
        <Route path={Auth.LOG_IN} element={<LoginPage />} />
      </Route>
      <Route path="*" element={<NotFount />} />
    </Route>
  )
);

const MainRouter = () => {
  return <RouterProvider router={router} />;
};

export default MainRouter;
