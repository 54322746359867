import { RootState } from "app/store";

export const selectSaleTable = (store: RootState) =>
  store.reports.registerSalesTable.data;
export const selectSaleTableFilter = (store: RootState) =>
  store.reports.registerSalesTable.filter;

export const selectCashTableFilter = (store: RootState) =>
  store.reports.registerCashTable.filter;
export const selectCashTableReport = (store: RootState) =>
  store.reports.registerCashTable.data;

export const selectCashExpenseFilter = (store: RootState) =>
  store.reports.registerCashExpense.filter;
export const selectCashExpenseReport = (store: RootState) =>
  store.reports.registerCashExpense.data;

export const selectReportDefectsFilter = (store: RootState) =>
  store.reports.reportDefectsTable.filter;
export const selectReportDefectsReport = (store: RootState) =>
  store.reports.reportDefectsTable.data;

export const selectRegisterPurchasesFilter = (store: RootState) =>
  store.reports.registerPurchasesTable.filter;
export const selectRegisterPurchasesReport = (store: RootState) =>
  store.reports.registerPurchasesTable.data;

export const selectWorkOrderFilter = (store: RootState) =>
  store.reports.workOrderRegister.filter;
export const selectWorkOrderReport = (store: RootState) =>
  store.reports.workOrderRegister.data;

export const selectInvoicesFilter = (store: RootState) =>
  store.reports.registerInvoice.filter;
export const selectInvoicesReport = (store: RootState) =>
  store.reports.registerInvoice.data;

export const selectMaterialReport = (store: RootState) =>
  store.reports.materialReportTable.data;
export const selectMaterialReportFilter = (store: RootState) =>
  store.reports.materialReportTable.filter;

export const selectOperationalReport = (store: RootState) =>
  store.reports.operationalTable.data;
export const selectOperationalReportFilter = (store: RootState) =>
  store.reports.operationalTable.filter;

export const selectProcurementPlan = (store: RootState) =>
  store.reports.procurementTable.data;
export const selectProcurementPlanFilter = (store: RootState) =>
  store.reports.procurementTable.filter;

export const selectReportSemiProduct = (store: RootState) =>
  store.reports.reportSemiProduct.data;
export const selectReportSemiProductFilter = (store: RootState) =>
  store.reports.reportSemiProduct.filter;

export const selectReportReleases = (store: RootState) =>
  store.reports.reportReleasesTable.data;
export const selectReportReleasesFilter = (store: RootState) =>
  store.reports.reportReleasesTable.filter;

export const selectAnalysisExecution = (store: RootState) =>
  store.reports.analysisExecutionTable.data;
export const selectAnalysisExecutionFilter = (store: RootState) =>
  store.reports.analysisExecutionTable.filter;

export const selectReportDriver = (store: RootState) =>
  store.reports.reportDriverTable.data;
export const selectReportDriverFilter = (store: RootState) =>
  store.reports.reportDriverTable.filter;

export const selectReportPurchase = (store: RootState) =>
  store.reports.reportPurchaseTable.data;
export const selectReportPurchaseFilter = (store: RootState) =>
  store.reports.reportPurchaseTable.filter;

export const selectCommodityReport = (store: RootState) =>
  store.reports.commodityReportTable.data;
export const selectCommodityReportFilter = (store: RootState) =>
  store.reports.commodityReportTable.filter;

export const selectBalanceSheet = (store: RootState) =>
  store.reports.balanceSheetTable.data;
export const selectBalanceSheetFilter = (store: RootState) =>
  store.reports.balanceSheetTable?.filter;

export const selectShipmentTable = (store: RootState) =>
  store.reports.reportShipmentTable.data;
export const selectShipmentFilter = (store: RootState) =>
  store.reports.reportShipmentTable.filter;
export const selectFinishedTable = (store: RootState) =>
  store.reports.reportFinishedTable.data;
export const selectFinishedFilter = (store: RootState) =>
  store.reports.reportFinishedTable.filter;

export const selectSoldFinishedTable = (store: RootState) =>
  store.reports.reportSoldFinishedTable.data;
export const selectSoldFinishedFilter = (store: RootState) =>
  store.reports.reportSoldFinishedTable.filter;


export const selectOperationalCashTable = (store: RootState) =>
  store.reports.operationalCashTable.data;
export const selectoperationalCashFilter = (store: RootState) =>
  store.reports.operationalCashTable.filter;

export const selectCashReportTable = (store: RootState) =>
  store.reports.cashReportTable.data;
export const selectCashReportFilter = (store: RootState) =>
  store.reports.cashReportTable.filter;

export const selectBuyerReportTable = (store: RootState) =>
  store.reports.buyerReportTable.data;
export const selectBuyerReportFilter = (store: RootState) =>
  store.reports.buyerReportTable.filter;

export const selectSupplierReportTable = (store: RootState) =>
  store.reports.supplierReportTable.data;
export const selectSupplierReportFilter = (store: RootState) =>
  store.reports.supplierReportTable.filter;

export const selectAccountableReportTable = (store: RootState) =>
  store.reports.accountableReportTable.data;
export const selectAccountableReportFilter = (store: RootState) =>
  store.reports.accountableReportTable.filter;

export const selectReconciliationActTable = (store: RootState) =>
  store.reports.reconciliationActTable.data;
export const selectReconciliationActFilter = (store: RootState) =>
  store.reports.reconciliationActTable.filter;
export const selectReconciliationActDetails = (store: RootState) =>
  store.reports.reconciliationActTable.options;

export const selectDriversShipmentMaterialTable = (store: RootState) =>
  store.reports.driversShipmentMaterialTable.data;
export const selectDriversShipmentMaterialFilter = (store: RootState) =>
  store.reports.driversShipmentMaterialTable.filter;

export const selectOperationalProductBalance = (store: RootState) =>
  store.reports.operationalBalance.data;
export const selectOperationalProductBalanceFilter = (store: RootState) =>
  store.reports.operationalBalance.filter;

export const selectReportRawMaterials = (store: RootState) =>
  store.reports.reportRawMaterialsTable.data;
export const selectReportRawMaterialsFilter = (store: RootState) =>
  store.reports.reportRawMaterialsTable.filter;

export const selectFulFillmentReport = (store: RootState) =>
  store.reports.fetchFulFillmentReportsTable.data;
export const selectFulFillmentFilter = (store: RootState) =>
  store.reports.fetchFulFillmentReportsTable.filter;
