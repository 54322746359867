import { Space, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "app/hook";
import {
  fetchMovingCash,
  fetchMovingCashStat,
  fetchMovingCashTemp,
} from "app/slices/documentSlices/movingCashSlices/movingCashSlices";

import {
  fetchAllKassa,
  fetchOwnKassa,
} from "app/slices/handbookSlices/kassaSlice/kassaSlice";
import { movingCashFilter } from "app/states/documents";
import { getAllKassa, getKassaData } from "app/states/handbooks/selectStates";
import {
  BaseInput,
  BaseMaskInput,
  BaseSelect,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  Text,
  TextArea,
  Title,
} from "components";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual, isObject } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import { checkDateYear, textFormatter } from "utilities/helpers";
import { NumberDocType } from "types/documentsType";

// Перемещение наличных денег  −> Добавить

const AddMovingCashContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allKassa = useAppSelector(getAllKassa);
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const kassaOptions = useAppSelector(getKassaData);
  const onSubmit = (values: any) => {
    if (get(state, "isUpdate", false)) {
      const params: any = {};
      forEach(values, (value, key) => {
        if (value) {
          if (key == "dataDoc" || !isObject(value)) {
            params[key] = value;
          } else {
            params[key] = get(value, "value", "");
          }
        }
      });
      try {
        DocumentServices.UpdateMovingCash({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: {
            ...params,
            id: get(state, "id", null),
          },
        }).then((res) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchMovingCash(filter));
            toast.success(MESSAGES.CHANGED);
          }
        });
      } catch (err) {
        console.log("err", err);
      }
    } else {
      const params: any = {};
      forEach(values, (value, key) => {
        if (value) {
          if (key == "dataDoc" || !isObject(value)) {
            params[key] = value;
          } else {
            params[key] = get(value, "value", "");
          }
        }
      });
      try {
        DocumentServices.AddNewMovingCash({
          transactionTime: new Date(),
          resultCode: null,
          resultMsg: null,
          data: params,
        }).then((res: any) => {
          if (get(res, "status", "") == 200) {
            navigate(-1);
            dispatch(fetchMovingCash(filter));
            dispatch(fetchMovingCashStat());
            toast.success(MESSAGES.ADDED);
            if (get(state, "id", false)) {
              DocumentServices.RemoveMovingCashesTemp(
                get(state, "id", "")
              ).then((res) => {
                if (get(res, "status", "") == 200) {
                  dispatch(fetchMovingCashTemp());
                }
              });
            }
          }
          setSubmitting(false);
        });
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const initialValues = {
    nomerDoc: "",
    dataDoc: '',
    prixodKassaId: "",
    imyaPrixodKassa: "",
    rasxodKassaId: "",
    imyaRasxodKassa: "",
    summa: "",
    summaInUSD: "",
    summaInUZS: "",
    kommentariya: "",
  };

  const { values, setFieldValue, handleSubmit, setSubmitting, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
    });
  console.log("state", state)
  const [disable, setDisable] = useState(false);
  const filter = useAppSelector(movingCashFilter);
  const [maxAmount, setMaxAmount] = useState<any>(0);

  // submit not fully filled form
  const saveNotFullFilledForm = useCallback(() => {
    const params: any = {
      id: get(state, "id", null),
      imyaPrixodKassa: get(values, "prixodKassaId.label", null),
      imyaRasxodKassa: get(values, "rasxodKassaId.label", null),
    };
    forEach(values, (value, key) => {
      if (key == "dataDoc" || !isObject(value)) {
        params[key] = value;
      } else {
        params[key] = get(value, "value", "");
      }
    });
    try {
      DocumentServices.SaveMovingCashesTemp({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: params,
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchMovingCashTemp());
          toast.success(MESSAGES.SEVED);
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  }, [dispatch, navigate, values]);

  const getDocNumber = useCallback(
    (date: NumberDocType) => {
      // if (get(state, "isTemp", true)) {
      DocumentServices.MovingCashNumber(date).then(({ data }) => {
        setFieldValue("nomerDoc", get(data, "data", ""));
        if (get(data, "transactionTime", "")) {
          const transactionTime = get(data, "transactionTime", "");
          const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
          const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
          setFieldValue("maxDate", localMaxDate);
          if (isEmpty(get(values, 'dataDoc', ''))) {
            setFieldValue("dataDoc", localDate);
          }
        }
        // setFieldValue("dataDoc", moment(new Date()).format("YYYY-MM-DD"));
      });
      // }
    },
    [setFieldValue, state, values]
  );

  const handleSetInitialValues = (data: any) => {
    setFieldValue("nomerDoc", get(data, "nomerDoc", ""));
    setFieldValue("dataDoc", get(data, "dataDoc", ""));
    setFieldValue("kommentariya", get(data, "kommentariya", ""));
    setFieldValue("prixodKassaId", {
      value: get(data, "prixodKassaId", ""),
      label: get(data, "imyaPrixodKassa", ""),
    });
    setFieldValue("rasxodKassaId", {
      value: get(data, "rasxodKassaId", ""),
      label: get(data, "imyaRasxodKassa", ""),
    });
    setFieldValue("summa", get(data, "summa", ""));
    setFieldValue("summaInUSD", get(data, "summaInUSD", ""));
    setFieldValue("summaInUZS", get(data, "summaInUZS", ""));
  };
  // check details
  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      setLoading(true);
      if (get(state, "isTemp", false)) {
        DocumentServices.FetchMovingCashesTempById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else if (get(state, "isUpdate", false)) {
        DocumentServices.FetchMovingCashesById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              if (get(res, "data.transactionTime", "")) {
                const transactionTime = get(res, "data.transactionTime", "");
                const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
                const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
                setFieldValue("maxDate", localMaxDate);
                // if (isEmpty(get(values, 'dataDoc', ''))) {
                //   setFieldValue("dataDoc", localDate);
                // }
              }
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
        setDisable(false);
      } else {
        DocumentServices.FetchMovingCashesById(get(state, "id", "")).then(
          (res) => {
            if (get(res, "status", "") == 200) {
              let respond = get(res, "data.data", {});
              handleSetInitialValues(respond);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
        setDisable(true);
      }
    } else {
      getDocNumber({});
      setLoading(false);
    }
  }, [dispatch, state]);

  const fetchSummaLimit = useCallback(() => {
    if (
      get(values, "rasxodKassaId.value", null) &&
      !get(state, "isTemp", null)
    ) {
      const req = {
        rasxodKassaId: get(values, "rasxodKassaId.value", ""),
        docId: get(state, "id", null),
      };
      DocumentServices.FetchPriceMovingCash(req)
        .then(({ data }) => {
          if (data) {
            setMaxAmount(get(data, "data", 0));
          }
        })
        .catch((err) => console.log(err));
    }
  }, [values.rasxodKassaId]);
  const fetchFormSelectorOptions = useCallback(() => {
    if (get(state, "isUpdate", false) || isEmpty(get(state, "id", null))) {
      dispatch(fetchOwnKassa({ all: true }));
      dispatch(fetchAllKassa({ all: true }));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchFormSelectorOptions();
  }, [fetchFormSelectorOptions]);
  useEffect(() => {
    fetchSummaLimit();
  }, [fetchSummaLimit]);
  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  return (
    <Content className={"min_height"}>
      {isEqual(loading, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  {PAGE.REQ}
                </Title>
                {/* {isEqual(get(state, "isUpdate", false), false) && (
                  <Button
                    green
                    handleClick={saveNotFullFilledForm}
                    disabled={disable}
                  >
                    {PAGE.SAVE}
                  </Button>
                )} */}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center">
                <Col xs={4}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        name={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          value={get(values, "dataDoc", null)}
                          handleDate={(e: any) => {
                            if (checkDateYear({
                              currentYear: get(values, "dataDoc", null),
                              checkYear: moment(e).format("YYYY-MM-DD"),
                            }))
                              getDocNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                          }}
                          // maxDate={new Date()}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Расходная касса
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"rasxodKassaId"}
                    defaultValue={get(values, "rasxodKassaId", {})}
                    value={get(values, "rasxodKassaId.value", "")}
                    options={kassaOptions.filter(
                      (item: any) =>
                        item.value !== get(values, "prixodKassaId.value", "")
                    )}
                    handleValueLabel={(e: any) => {
                      console.log(e, "event");
                      setFieldValue("rasxodKassaId.value", e.value);
                      setFieldValue("imyaRasxodKassa", e.label);

                      if (
                        isEqual(e, get(values, "rasxodKassaId", false)) &&
                        e !== get(values, "prixodKassaId", {})
                      ) {
                        setFieldValue("prixodKassaId", null);
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Приходная касса
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"prixodKassaId"}
                    defaultValue={get(values, "prixodKassaId", null)}
                    value={get(values, "prixodKassaId.value", "")}
                    options={allKassa.filter(
                      (item: any) =>
                        item.value !== get(values, "rasxodKassaId.value", "")
                    )}
                    handleValueLabel={(e: any) => {
                      setFieldValue("imyaPrixodKassa", e.label);
                      setFieldValue("prixodKassaId.value", e.value);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Сумма
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summa"}
                    placeholder={
                      maxAmount !== null
                        ? ` до: ${textFormatter(maxAmount)}`
                        : ""
                    }
                    value={get(values, "summa", "")}
                    handleInput={(e: any) => setFieldValue("summa", e)}
                    disabled={disable}
                  />
                  {!disable && (
                    <p>
                      {maxAmount !== null &&
                        get(values, "summa", 0) > maxAmount &&
                        isEqual(disable, false) ? (
                        <Text xs danger>
                          ограничение до:
                          {textFormatter(maxAmount)}
                        </Text>
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>В сумах</Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summaInUZS"}
                    value={get(values, "summaInUZS", "")}
                    handleInput={(e: any) => setFieldValue("summaInUZS", e)}
                    disabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>В долларах США</Title>
                </Col>
                <Col xs={8}>
                  <BaseMaskInput
                    id={"summaInUSD"}
                    value={get(values, "summaInUSD", "")}
                    handleInput={(e: any) => setFieldValue("summaInUSD", e)}
                    disabled={disable}
                  />
                </Col>
              </Row>
              <TextArea
                disabled={disable}
                id={"kommentariya"}
                label={"Коментарии"}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
          </Row>

          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              {get(state, "isUpdate", false) ? (
                <Button
                  primary
                  disabled={disable}
                  className={"mr-16"}
                  disable
                  loading={isSubmitting}
                  handleClick={handleSubmit}
                >
                  {PAGE.CHANGE}
                </Button>
              ) : (
                <HasAccess>
                  {({ userCan }: any) =>
                    userCan({
                      permission: `${DOCUMENT_KEY.PEREMESH_ND}_CREATE`,
                      page: DOCUMENT_KEY.PEREMESH_ND,
                      department: MAIN_DEPARTMENT.DOCUMENTS,
                      type: PROTECTED_TYPE.PERMISSIONS,
                    }) && (
                      <FormButton
                        disabled={disable}
                        loading={isSubmitting}
                        handleClick={handleSubmit}
                      >
                        {PAGE.DONE}
                      </FormButton>
                    )
                  }
                </HasAccess>
              )}
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Space className="centrality">
          <Spin size="large" />
        </Space>
      )}
    </Content>
  );
};

export default AddMovingCashContainer;
