import { TableWrapper } from "./styles/style";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";

function FulfillmentReportTable({ data }: any) {
    const statusItem = (text: string) => {
        switch (text) {
            case 'NEW':
                return 'Новый'
            case 'IN_PROGRESS':
                return 'В процессе'
            case 'COMPLETED':
                return 'Произведен'
            case 'CLOSED':
                return 'Выполнен'
        }
    }
    return (
        <Row className="mt-16">
            <Col>
                <Row>
                    <Col className="responsive_overflow">
                        <TableWrapper minWidth={"1400px"} className="mb-16">
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Дата документа</th>
                                    <th >Номер Заказ-наяда</th>
                                    <th style={{ minWidth: "200px" }}>Контрагент</th>
                                    <th style={{ minWidth: "150px" }}>Товар</th>
                                    <th>Количество</th>
                                    <th>Ед. измерение</th>
                                    <th>На складе</th>
                                    <th>Отгузка</th>
                                    <th>Остатка</th>
                                    <th>Статус</th>
                                    <th style={{ minWidth: "180px" }}>Телефоны</th>
                                    <th>Территория</th>
                                    <th >Тип лица</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isEmpty(get(data, "dataList", [])) ? (
                                    get(data, "dataList", []).map(
                                        (item: any, index: number) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {moment(get(item, "dataDoc", "")).format(
                                                        "DD.MM.YYYY"
                                                    )}
                                                </td>
                                                <td>{get(item, "nomer", "")}</td>
                                                <td>{get(item, "imyaKontragent", "")}</td>
                                                <td >{get(item, "imyaTovar", "")}</td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "kol", "")}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td>{get(item, "edIzm", "")}</td>
                                                <td> <NumericFormat
                                                    displayType="text"
                                                    value={get(item, "skladKol", "")}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                /></td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "otgruzkaKol", "")}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>
                                                <td>
                                                    <NumericFormat
                                                        displayType="text"
                                                        value={get(item, "ostatkaKol", "")}
                                                        thousandSeparator
                                                        decimalScale={2}
                                                    />
                                                </td>

                                                <td>{statusItem(get(item, "status", ""))}</td>
                                                <td>{get(item, "telNumber", "")}</td>
                                                <td>{get(item, "imyaTerritori", "")}</td>
                                                <td>{get(item, "tipLitsa", "")}</td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan={11}>Виберите параметры</td>
                                    </tr>
                                )}

                            </tbody>
                        </TableWrapper>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default FulfillmentReportTable;
