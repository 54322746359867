import { useAppDispatch, useAppSelector } from "app/hook";
import {
  clearRegisteredOrders,
  fetchOrderRegister,
} from "app/slices/documentSlices/orderRegisterSlice/orderRegisterSlice";
import {
  addSubjectsOnesSemi,
  addSubjectsSemi,
  addSubjectsSemiPofaktu,
  changeItemSubjectsSemi,
  clearDetailsSemi,
  clearFormSemiData,
  fetchNotFilledSemiProduct,
  fetchSemiProduct,
  fetchSemiProductDetails,
  fetchSemiProductsById,
  fetchSemiProductStat,
  fetchSemiProductsTempById,
  removeSubjectSemi,
} from "app/slices/documentSlices/semiProductSlice/semiProductSlices";
import { fetchTypeWriteOff } from "app/slices/enumerationsSlices/typeWriteOffSlices/typeWriteOffSlices";
import {
  fetchAllCounterpart,
  fetchCounterpartVipuskPF,
} from "app/slices/handbookSlices/counterpartSlices/counterpartSlices";
import { fetchRawMaterial } from "app/slices/handbookSlices/rawMaterialSlices/rawMaterialSlices";
import {
  loadingSemiProduct,
  semiProductDetails,
  semiProductFilter,
  semiProductForm,
} from "app/states/documents";
import {
  contractorOptions,
  getSaleContractsSubjectData,
  loadingConterpart,
  loadingOrderData,
  orderRegisterOptionsById,
  unitsMeasurementOptions,
} from "app/states/documents/selectStates";
import { getTypeWriteData } from "app/states/enumerations/selectStates";
import {
  getCounterpartVipuskPF,
  getMaterialData,
  getSkladCM,
  getTerritoriesData,
  getWarHouseData,
  loadingConterpartVipuskPF,
  loadingRawMaterial,
} from "app/states/handbooks/selectStates";
import add_green from "assets/images/icons/add_green.svg";
import remove_red from "assets/images/icons/remove_red.svg";
import {
  BaseInput,
  BaseSelect,
  BaseTable,
  Button,
  Content,
  DatePicker,
  FlexBox,
  Form,
  FormButton,
  FormInput,
  FormSelect,
  LoadingAddPages,
  ReactModal,
  Text,
  TextArea,
  Title,
} from "components";
import BaseMarkInput from "components/BaseMarkInput/BaseMarkInput";
import TableInput from "components/TableInput/TableInput";
import { useFormik } from "formik";
import { forEach, get, isEmpty, isEqual } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import HasAccess from "routers/HasAccess";
import { DOCUMENT_KEY, MAIN_DEPARTMENT } from "routers/constants";
import DocumentServices from "services/apiServices/documents";
import { MESSAGES, PAGE, PROTECTED_TYPE } from "utilities/constants";
import {
  checkDateYear,
  removeValueOption,
  textFormatter,
} from "utilities/helpers";
import {
  manualMaterialSchema,
  semiProductSchema,
} from "utilities/schema/document/documentSchemas";
import { NumberDocType } from "types/documentsType";
import { ProgressBar } from "primereact/progressbar";
import { useDebounce } from "use-debounce";

// Добавить  Выпуск полуфабрикатов
export const AddContractSubjects = ({
  handleOpenModal,
  handleCloseModal,
  skladId,
  zakazNaryadId,
  dataTable,
}: {
  handleOpenModal?: () => void;
  handleCloseModal: () => void;
  skladId: number | null;
  zakazNaryadId: string | null;
  dataTable: Array<any>;
}) => {
  const getMaterialOptions = useAppSelector(getMaterialData);
  const loadingMaterial = useAppSelector(loadingRawMaterial);
  const dispatch = useAppDispatch();
  const onSubmit = (values: any) => {
    let res = {
      ...values,
      materialId: get(values, "materialId.value", ""),
      imyaMaterial: get(values, "materialId.label", null),
      imyaEdIzm: get(values, "materialId.imyaEdIzm", null),
    };
    dispatch(addSubjectsOnesSemi(res));
    resetForm();
    handleCloseModal();
  };
  const { values, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues: {
      imyaMaterial: "",
      materialId: null,
      kol: "",
      imyaEdIzm: "",
      tsena: 0,
      summa: 0,
    },
    onSubmit,
    validationSchema: manualMaterialSchema,
  });

  const getPrice = useCallback(() => {
    if (get(values, "materialId", "") && get(values, "kol", null)) {
      try {
        DocumentServices.GetPriceMaterial({
          materialId: get(values, "materialId.value", null),
          skladId: skladId,
          kol: get(values, "kol", "0"),
          zakazNaryadId: zakazNaryadId,
        }).then((res) => {
          setFieldValue("tsena", get(res, "data.data.tsena", 0));
          setFieldValue(
            "summa",
            get(res, "data.data.tsena", 0) * Number(get(values, "kol", 0))
          );
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [values.kol, zakazNaryadId, values.materialId]);

  useEffect(() => {
    getPrice();
  }, [getPrice]);
  useEffect(() => {
    dispatch(fetchRawMaterial({ all: true, schetMateriala: "SCHET_1010" }));
  }, [dispatch]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Col xs={12} className="mt-16 text-left">
          <Title sm bold>
            Реквизиты
          </Title>
        </Col>
        <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
          <hr />
        </Col>
        <Col xs={12} className={"px-24"}>
          <FormSelect
            id={"materialId"}
            label={"Сырьё и материал"}
            isLoading={loadingMaterial}
            isSearchable
            required={true}
            value={get(values, "materialId.value", "")}
            options={removeValueOption({
              currentOption: getMaterialOptions,
              removeValueList: dataTable,
              valueName: "materialId",
            })}
            handleValueLabel={(e: any) => {
              setFieldValue("materialId", e);
              setFieldValue("edIzmId", get(e, "edIzmId", null));
            }}
          />
          <Row align="center" className="mt-24">
            <Col xs={4} style={{ paddingRight: 0 }}>
              <Title>
                Количество
                <span className="required_point">*</span>
              </Title>
            </Col>
            <Col xs={8}>
              <BaseMarkInput
                id={"kol"}
                value={get(values, "kol", "")}
                handleInput={(e: number) => {
                  setFieldValue("kol", e);
                }}
                min={1}
                maxLength={40}
              />
            </Col>
          </Row>

          <Col xs={12} className="text-right mt-24 mb-16">
            <Button className="mr-8" green type="submit">
              {
                // get(details, "id", null) ? PAGE.CHANGE :
                PAGE.ADD
              }
            </Button>
            <Button
              onClick={() => {
                handleCloseModal();
                resetForm();
              }}
              danger
            >
              Закрыть
            </Button>
          </Col>
        </Col>
      </Form>
    </>
  );
};

const AddSemiProductContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const unitsMeasurementDropdowns = useAppSelector(unitsMeasurementOptions);
  const territory = useAppSelector(getTerritoriesData);
  const skladSMOptions = useAppSelector(getSkladCM);
  const skladGPOptions = useAppSelector(getWarHouseData);

  const getSaleContracts = useAppSelector(getSaleContractsSubjectData);
  const contractorDropdowns = useAppSelector(getCounterpartVipuskPF);
  const spisanyaOptions = useAppSelector(getTypeWriteData);
  const { state } = useLocation();
  const productOptions = useAppSelector(semiProductDetails);
  const semiFormData = useAppSelector(semiProductForm);
  const filter = useAppSelector(semiProductFilter);
  const [disable, setDisable] = useState(true);

  // loadings
  let LOADING = get(semiFormData, "loading", false);
  let loadingConter = useAppSelector(loadingConterpartVipuskPF);
  let loadingOrder = useAppSelector(loadingOrderData);
  let produtLoad = useAppSelector(loadingSemiProduct);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);
  const onSubmit = (values: any) => {
    const cntrcSubjects =
      get(values, "tipSpisaniy.value", null) === 1
        ? get(semiFormData, "data.vipuskPFDetailsList", [])
        : get(semiFormData, "data.poFaktuDetailsList", []).map((item: any) => ({
          materialId: get(item, "materialId", ""),
          kol: get(item, "kol", ""),
        }));

    let valuesToSbm: any = {};

    forEach(
      values,
      (value: any, key: any) =>
      (valuesToSbm[key] = [
        "nomerDoc",
        "kolPolufabrikat",
        "kommentariya",
      ].includes(key)
        ? value
        : get(value, "value", ""))
    );
    try {
      DocumentServices.AddSemiProduct({
        transactionTime: new Date(),
        resultCode: null,
        resultMsg: null,
        data: {
          ...valuesToSbm,
          dataDoc: !isEqual(get(values, "dataDoc", null), null)
            ? get(values, "dataDoc", "")
            : moment(new Date()).format("YYYY-MM-DD"),
          vipuskPFDetailsList: cntrcSubjects,
        },
      }).then((res) => {
        if (get(res, "status", "") == 200) {
          navigate(-1);
          dispatch(fetchSemiProduct(filter));
          dispatch(fetchSemiProductStat());
          toast.success(MESSAGES.ADDED);
          // setLoading(false)
          if (get(state, "id", false)) {
            DocumentServices.RemoveTempsReleaseProduct(
              get(state, "id", "")
            ).then((res) => {
              if (get(res, "status", "") == 200) {
                dispatch(fetchNotFilledSemiProduct());
              }
            });
          }
        }
        setSubmitting(false);
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...get(semiFormData, "data.formIntVls", {}),
      dataDoc: get(state, 'id', false) ? get(semiFormData, "data.formIntVls.dataDoc", "") : '',
    },
    onSubmit,
    validationSchema: semiProductSchema,
  });
  const [debouncedText] = useDebounce(
    get(values, "kolPolufabrikat", null),
    500
  );
  const orderRegister = useAppSelector((store) =>
    orderRegisterOptionsById(store, get(values, "kontragentId", ""))
  );
  // const saveNotFullFilledForm = useCallback(() => {
  //   const cntrcSubjects =
  //     get(values, "tipSpisaniy aId.value", null) === 2
  //       ? get(semiFormData, "data.poFaktuDetailsList", [])
  //       : get(semiFormData, "data.vipuskPFDetailsList", []).map(
  //           (item: any) => ({
  //             imyaMaterial: get(item, "imyaMaterial", null),
  //             materialId: get(item, "materialId", null),
  //             kol: get(item, "kol", 0),
  //             imyaEdIzm: get(item, "imyaEdIzm", null),
  //             tsena: get(item, "tsena", 0),
  //             summa: get(item, "summa", 0),
  //           })
  //         );

  //   let valuesToSbm: any = {};

  //   forEach(values, (value: any, key: any) => {
  //     valuesToSbm[key] = ["nomerDoc", "kolPolufabrikat", "dataDoc"].includes(
  //       key
  //     )
  //       ? value
  //       : get(value, "value", "");
  //   });

  //   try {
  //     DocumentServices.AddNotFilledSemiProduct({
  //       transactionTime: new Date(),
  //       resultCode: null,
  //       resultMsg: null,
  //       data: {
  //         ...valuesToSbm,
  //         id: get(state, "id", null),
  //         dataDoc: !isEqual(get(values, "dataDoc", null), null)
  //           ? get(values, "dataDoc", "")
  //           : moment(new Date()).format("YYYY-MM-DD"),
  //         nomerZakazNaryad: get(values, "zakazNaryadId.label", ""),
  //         nomerDogProdaja: get(values, "dogProdajaId.label", ""),
  //         imyaKontragent: get(values, "kontragentId.label", ""),
  //         imyaPolufabrikat: get(values, "polufabrikatId.label", ""),
  //         imyaTerritorii: get(values, "territoriyaId.label", ""),
  //         imyaSkladPF: get(values, "skladPFId.label", ""),
  //         imyaSkladSpis: get(values, "skladSpisId.label", ""),
  //         imyaTovar: get(values, "tovarId.label", ""),
  //         imyaEdIzm: get(values, "edIzmId.label", ""),
  //         kolPolufabrikat: get(values, "kolPolufabrikat", ""),
  //         vipuskPFDetailsList: cntrcSubjects,
  //       },
  //     }).then((res) => {
  //       if (get(res, "status", "") == 200) {
  //         navigate(-1);
  //         dispatch(fetchNotFilledSemiProduct());
  //         toast.success(MESSAGES.ADDED);
  //       }
  //     });
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // }, [dispatch, navigate, semiFormData, values]);

  const getDocNumber = useCallback((year: NumberDocType) => {
    DocumentServices.GetSemiNumber(year).then(({ data }) => {
      setFieldValue("nomerDoc", get(data, "data", ""));
      if (get(data, "transactionTime", "")) {
        const transactionTime = get(data, "transactionTime", "");
        const localMaxDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD HH:mm");
        const localDate = moment.utc(transactionTime).add(5, 'hours').format("YYYY-MM-DD");
        setFieldValue("maxDate", localMaxDate);
        if (isEmpty(get(values, 'dataDoc', ''))) {
          setFieldValue("dataDoc", localDate);
        }
      }
    });
  }, [values]);

  const handleCheckTemp = useCallback(() => {
    if (get(state, "id", false)) {
      if (get(state, "isTemp", false)) {
        dispatch(fetchSemiProductsTempById(get(state, "id", "")));
        setDisable(false);
      } else {
        dispatch(fetchSemiProductsById(get(state, "id", "")));
      }
    } else {
      setDisable(false);
    }
  }, [dispatch, state]);
  //   details - get materials from product id
  const getDetails = useCallback(() => {
    if (
      get(values, "zakazNaryadId.value", null) &&
      !get(state, "id", null) &&
      get(values, "tipSpisaniyaId.value", null)
    ) {
      // this api only works when enter by temp id or add
      try {
        if (
          disable === false &&
          get(values, "zakazNaryadId.value", null) &&
          get(values, "polufabrikatId.value", null) &&
          get(values, "kolPolufabrikat", null)
        ) {
          setLoadingDetails(true);
          DocumentServices.FetchSemiProductDetails({
            zakazNaryadId: get(values, "zakazNaryadId.value", ""),
            polufabrikatId: get(values, "polufabrikatId.value", null),
            tipSpisaniyaId: get(values, "tipSpisaniyaId.value", null),
            kol: get(values, "kolPolufabrikat", null),
          }).then((res) => {
            setLoadingDetails(false);
            if (res.status == 200) {
              dispatch(
                addSubjectsSemi({
                  data: get(res, "data.data", []),
                  tipSpisaniyaId: get(values, "tipSpisaniyaId", 1),
                })
              );
              dispatch(addSubjectsSemiPofaktu(get(res, "data.data", [])));
            }
          });
        }
      } catch (error) {
        console.log(error);
      }

      if (isEmpty(values.kolPolufabrikat) || values.kolPolufabrikat == 0) {
        dispatch(addSubjectsSemi(null));
        dispatch(addSubjectsSemiPofaktu(null));
        dispatch(addSubjectsOnesSemi(null));
      }
    }
  }, [
    debouncedText,
    values.tipSpisaniyaId,
    values.zakazNaryadId,
    values.kolTovara,
    values.tovarId,
    disable,
    values.tipSpisaniyaId,
  ]);
  //  get sklad by territory Id
  const getSklads = useCallback(() => {
    if (
      get(values, "zakazNaryadId.value", null) &&
      isEmpty(get(state, "id", null))
    ) {
      const findTerritory = territory.find(
        (item: any) =>
          get(item, "value", 0) === get(values, "territoriyaId.value", 0)
      );
      setFieldValue("skladPFId.value", get(findTerritory, "skladCMId", null));
      setFieldValue("skladPFId.label", get(findTerritory, "imyaSkladSM", null));
      setFieldValue("skladSpisId.value", get(findTerritory, "skladCMId", null));
      setFieldValue(
        "skladSpisId.label",
        get(findTerritory, "imyaSkladSM", null)
      );
    }
  }, [values.zakazNaryadId, values.polufabrikatId, values.kol]);

  //  function get all material Prices
  const sumWithInitial = get(
    semiFormData,
    "data.vipuskPFDetailsList",
    []
  ).reduce(
    (accumulator: any, currentValue: any) =>
      accumulator + get(currentValue, "summa", 0),
    0
  );

  const sumWithInitialPorfaktu = get(
    semiFormData,
    "data.poFaktuDetailsList",
    []
  ).reduce(
    (accumulator: any, currentValue: any) =>
      accumulator + get(currentValue, "summa", 0),
    0
  );
  useEffect(() => {
    handleCheckTemp();
  }, [handleCheckTemp]);

  useEffect(() => {
    if (get(values, "kontragentId.value", null) && !get(state, "id", null)) {
      const stopReq = dispatch(
        fetchOrderRegister({
          otmVipolneniya: 0,
          filterPage: "VIPUSK_PF",
          kontragentId: get(values, "kontragentId.value", null),
        })
      );
      return () => {
        stopReq.abort();
        dispatch(clearRegisteredOrders());
      };
    }
  }, [values.kontragentId]);
  useEffect(() => {
    getSklads();
  }, [getSklads]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    if (get(values, "zakazNaryadId.value", null) && !get(state, "id", null)) {
      const cancelReq = dispatch(
        fetchSemiProductDetails({
          zakazNaryadId: get(values, "zakazNaryadId.value", ""),
        })
      );
      return () => {
        cancelReq.abort();
        dispatch(clearDetailsSemi());
        dispatch(clearFormSemiData());
      };
    }
  }, [values.zakazNaryadId]);

  useEffect(() => {
    if (!get(state, "id", null)) {
      getDocNumber({});
      dispatch(fetchTypeWriteOff({ all: true }));
    }
    if (!get(state, "id", null)) {
      const Cancelreq = dispatch(fetchCounterpartVipuskPF());
      return () => {
        Cancelreq.abort();
      };
    }
  }, [dispatch]);

  return (
    <Content className={"min_height"}>
      <ReactModal isOpen={openModal}>
        <AddContractSubjects
          handleCloseModal={handleCloseModal}
          skladId={get(values, "skladSpisId.value", "")}
          zakazNaryadId={get(values, "zakazNaryadId.value", null)}
          dataTable={get(semiFormData, "data.poFaktuDetailsList", [])}
        />
      </ReactModal>
      {isEqual(LOADING, false) ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={12}>
              <FlexBox justify={"space-between"} style={{ width: "100%" }}>
                <Title bold sm>
                  Реквизиты
                </Title>
                {/* <Button
                  green
                  handleClick={saveNotFullFilledForm}
                  disabled={disable}
                >
                  Сохранить
                </Button> */}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <FlexBox>
                <Col xs={4} style={{ paddingLeft: 0 }}>
                  <Title>
                    Номер
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseInput
                        id={"nomerDoc"}
                        value={get(values, "nomerDoc", "")}
                      />
                    </Col>
                    <Col xs={6} style={{ paddingLeft: 0 }}>
                      <FlexBox>
                        <Text>от:</Text>
                        <DatePicker
                          id={"dataDoc"}
                          maxDate={new Date(get(values, 'maxDate', new Date()))}
                          value={get(values, "dataDoc", "")}
                          handleDate={(e: any) => {
                            setFieldValue(
                              "dataDoc",
                              moment(e).format("YYYY-MM-DD")
                            );
                            if (
                              checkDateYear({
                                checkYear: moment(e).format("YYYY-MM-DD"),
                                currentYear: get(values, "dataDoc", null),
                              })
                            ) {
                              getDocNumber({
                                year: checkDateYear({
                                  currentYear: get(values, "dataDoc", null),
                                  checkYear: moment(e).format("YYYY-MM-DD"),
                                }),
                              });
                            }
                          }}
                          // maxDate={new Date()}

                          dateFormat="dd.MM.yyyy"
                          disabled={disable}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </FlexBox>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Контрагент
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"kontragentId"}
                    defaultValue={get(values, "kontragentId", {})}
                    placeholder={get(values, "kontragentId.label", "")}
                    isSearchable
                    isLoading={loadingConter}
                    value={get(values, "kontragentId.value", "")}
                    options={contractorDropdowns}
                    handleValueLabel={(e: any) => {
                      setFieldValue("kontragentId", e);
                      if (!isEqual(e, get(values, "kontragentId", false))) {
                        dispatch(clearDetailsSemi());
                        setFieldValue("zakazNaryadId.value", null);
                        setFieldValue("zakazNaryadId.label", null);
                        setFieldValue("dogProdajaId.value", null);
                        setFieldValue("dogProdajaId.label", null);
                        setFieldValue("territoriyaId.value", null);
                        setFieldValue("territoriyaId.label", null);
                        setFieldValue("skladSpisId.label", null);
                        setFieldValue("skladSpisId.value", null);
                        setFieldValue("polufabrikatId.value", null);
                        setFieldValue("polufabrikatId.label", null);
                        setFieldValue("skladPFId.label", null);
                        setFieldValue("skladPFId.value", null);
                        setFieldValue("edIzmId", null);
                        setFieldValue("kolPolufabrikat", "");
                        dispatch(addSubjectsSemi(null));
                        dispatch(addSubjectsSemiPofaktu(null));
                      }
                    }}
                    isDisabled={loadingOrder || produtLoad || disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Заказ-наряд
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    defaultValue={get(values, "zakazNaryadId", {})}
                    placeholder={get(values, "zakazNaryadId.label", "")}
                    id={"zakazNaryadId"}
                    isSearchable
                    isLoading={loadingOrder}
                    value={get(values, "zakazNaryadId.value", "")}
                    options={!loadingOrder ? orderRegister : []}
                    handleValueLabel={(e: any) => {
                      setFieldValue("zakazNaryadId", e);
                      setFieldValue(
                        "dogProdajaId.value",
                        get(e, "dogProdajaId", null)
                      );
                      setFieldValue(
                        "dogProdajaId.label",
                        get(e, "imyaDogProdaja", null)
                      );
                      setFieldValue(
                        "territoriyaId.value",
                        get(e, "territory", null)
                      );
                      setFieldValue(
                        "territoriyaId.label",
                        get(e, "imyaTerritory", null)
                      );

                      if (!isEqual(e, get(values, "zakazNaryadId", false))) {
                        dispatch(clearDetailsSemi());
                        setFieldValue("skladSpisId.label", null);
                        setFieldValue("skladSpisId.value", null);
                        setFieldValue("polufabrikatId.value", null);
                        setFieldValue("polufabrikatId.label", null);
                        setFieldValue("skladPFId.label", null);
                        setFieldValue("skladPFId.value", null);
                        setFieldValue("edIzmId", null);
                        setFieldValue("kolPolufabrikat", "");
                        dispatch(addSubjectsSemi(null));
                        dispatch(addSubjectsSemiPofaktu(null));
                      }
                    }}
                    isDisabled={disable || produtLoad}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Договор <span className="required_point">*</span>{" "}
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"dogProdajaId"}
                    defaultValue={get(values, "dogProdajaId", {})}
                    placeholder={get(values, "dogProdajaId.label", "")}
                    isSearchable
                    value={get(values, "dogProdajaId.value", "")}
                    options={getSaleContracts}
                    isDisabled={true}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Территория
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    defaultValue={get(values, "territoriyaId", {})}
                    id={"territoriyaId"}
                    isSearchable
                    value={get(values, "territoriyaId.value", "")}
                    options={territory}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад <br />
                    полуфабрикатов
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladPFId"}
                    isSearchable
                    defaultValue={get(values, "skladPFId", "")}
                    value={get(values, "skladPFId.value", "")}
                    options={skladGPOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladPFId", e);
                    }}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <TextArea
                id={"kommentariya"}
                label={"Коментарии"}
                disabled={disable}
                value={get(values, "kommentariya", "")}
                handleInput={(e: string) => {
                  setFieldValue("kommentariya", e);
                }}
              />
            </Col>
            {/* right */}
            <Col xs={6}>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Склад для списания материалов
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"skladSpisId"}
                    isSearchable
                    defaultValue={get(values, "skladSpisId", "")}
                    value={get(values, "skladSpisId.value", "")}
                    options={skladSMOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("skladSpisId", e);
                    }}
                    isDisabled={true}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Тип списания <span className="required_point">*</span>{" "}
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"tipSpisaniyaId"}
                    isSearchable
                    defaultValue={get(values, "tipSpisaniyaId", {})}
                    value={get(values, "tipSpisaniyaId.value", "")}
                    options={spisanyaOptions}
                    handleValueLabel={(e: any) => {
                      setFieldValue("tipSpisaniyaId", e);
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>
              <Row align="center" className="mt-24">
                <Col xs={4} style={{ paddingRight: 0 }}>
                  <Title>
                    Полуфабрикат
                    <span className="required_point">*</span>{" "}
                  </Title>
                </Col>
                <Col xs={8}>
                  <BaseSelect
                    id={"polufabrikatId"}
                    isSearchable
                    placeholder={get(values, "polufabrikatId.label", "")}
                    value={get(values, "polufabrikatId.value", "")}
                    options={!produtLoad ? productOptions : []}
                    isLoading={produtLoad}
                    defaultValue={get(values, "polufabrikatId", {})}
                    handleValueLabel={(e: any) => {
                      setFieldValue("polufabrikatId", e);
                      setFieldValue("edIzmId.value", get(e, "edIzmId", null));
                      setFieldValue("edIzmId.label", get(e, "imyaEdizm", ""));
                      setFieldValue("maxKol", get(e, "maxKol", 0));
                      if (!isEqual(e, get(values, "polufabrikatId", false))) {
                        setFieldValue("kolPolufabrikat", "");
                        dispatch(addSubjectsSemi(null));
                        dispatch(addSubjectsSemiPofaktu(null));
                      }
                    }}
                    isDisabled={disable}
                  />
                </Col>
              </Row>

              <Row align="center" className="mt-24">
                <Col xs={4}>
                  <Title>
                    Количество
                    <span className="required_point">*</span>
                  </Title>
                </Col>
                <Col xs={8} className="form-input-date_picker">
                  <Row>
                    <Col xs={6}>
                      <BaseMarkInput
                        id={"kolPolufabrikat"}
                        value={get(values, "kolPolufabrikat", "")}
                        handleInput={(e: number) => {
                          setFieldValue("kolPolufabrikat", e);
                        }}
                        min={1}
                        disabled={disable}
                      />
                    </Col>
                    <Col
                      xs={6}
                      style={{
                        paddingLeft: 0,
                      }}
                    >
                      <FlexBox>
                        <Text sm>Ед.изм:</Text>
                        <BaseSelect
                          id={"edIzmId"}
                          value={get(values, "edIzmId.value", "")}
                          options={unitsMeasurementDropdowns}
                          isDisabled={true}
                        />
                      </FlexBox>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FormInput
                label={"Себестоимость"}
                id={"sebestPolufabrikat"}
                value={
                  isEqual(get(values, "tipSpisaniyaId.value", null), 1) ||
                    disable
                    ? sumWithInitial.toFixed(2)
                    : sumWithInitialPorfaktu.toFixed(2)
                }
                required={true}
                disabled={true}
              />
            </Col>
          </Row>

          <Row className="mt-24" justify={"between"}>
            <Col xs={12} className="align-center">
              <FlexBox justify={"space-between"} align={"center"}>
                <Title bold sm>
                  Табличная часть
                  <span className="required_point">*</span>
                </Title>
                {isEqual(get(values, "tipSpisaniyaId.value", 1), 2) &&
                  isEqual(disable, false) && (
                    <ReactSVG
                      src={add_green}
                      className="svg_icon"
                      onClick={handleOpenModal}
                    />
                  )}
              </FlexBox>
            </Col>
            <Col xs={12} style={{ padding: 0 }} className={"my-16"}>
              {loadingDetails ? (
                <ProgressBar
                  color="#5899f5"
                  mode="indeterminate"
                  style={{ height: "2px" }}
                ></ProgressBar>
              ) : (
                <hr />
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Col>
                <BaseTable
                  tableHeader={[
                    "№",
                    "Сырьё и материал",
                    "Единица измерения",
                    "Количество",
                    "Цена",
                    "Сумма",
                    "",
                  ]}
                >
                  {!loadingDetails ? (
                    <>
                      {get(values, "tipSpisaniyaId.value", null) === 1 ||
                        disable ? (
                        !isEmpty(
                          get(semiFormData, "data.vipuskPFDetailsList", [])
                        ) ? (
                          get(semiFormData, "data.vipuskPFDetailsList", []).map(
                            (item: any, index: number) => (
                              <tr key={get(item, "imyaMaterial", "")}>
                                <td>{index + 1}</td>
                                <td>{get(item, "imyaMaterial", "")}</td>
                                <td>{get(item, "imyaEdIzm", "")}</td>
                                <td>
                                  {isEqual(
                                    get(values, "tipSpisaniyaId.value", 1),
                                    2
                                  ) && isEqual(disable, false) ? (
                                    <TableInput
                                      kol={get(item, "kol", 0)}
                                      handleOnChange={(value: any) => {
                                        dispatch(
                                          changeItemSubjectsSemi({
                                            id: index,
                                            name: "kol",
                                            value,
                                          })
                                        );
                                        dispatch(
                                          changeItemSubjectsSemi({
                                            id: index,
                                            name: "summa",
                                            value:
                                              value *
                                              Number(get(item, "tsena", 0)),
                                          })
                                        );
                                      }}
                                    />
                                  ) : (
                                    <NumericFormat
                                      displayType="text"
                                      value={get(item, "kol", 0)}
                                      thousandSeparator
                                      decimalScale={2}
                                    />
                                  )}
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "tsena", "")}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "summa", 0)}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                </td>
                                <td>
                                  {isEqual(
                                    get(values, "tipSpisaniyaId.value", 1),
                                    2
                                  ) &&
                                    isEqual(disable, false) && (
                                      <span>
                                        <ReactSVG
                                          src={remove_red}
                                          className="svg_icon"
                                          style={{ fontSize: 10 }}
                                          onClick={() =>
                                            dispatch(
                                              removeSubjectSemi({ id: index })
                                            )
                                          }
                                        />
                                      </span>
                                    )}
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td colSpan={7}>Нет Данных</td>
                          </tr>
                        )
                      ) : !isEmpty(
                        get(semiFormData, "data.poFaktuDetailsList", [])
                      ) ? (
                        get(semiFormData, "data.poFaktuDetailsList", []).map(
                          (item: any, index: number) => (
                            <tr key={get(item, "imyaMaterial", "")}>
                              <td>{index + 1}</td>
                              <td>{get(item, "imyaMaterial", "")}</td>
                              <td>{get(item, "imyaEdIzm", "")}</td>
                              <td>
                                {isEqual(
                                  get(values, "tipSpisaniyaId.value", 1),
                                  2
                                ) && isEqual(disable, false) ? (
                                  <TableInput
                                    kol={get(item, "kol", 0)}
                                    handleOnChange={(value: any) => {
                                      dispatch(
                                        changeItemSubjectsSemi({
                                          id: index,
                                          name: "kol",
                                          value,
                                        })
                                      );
                                      dispatch(
                                        changeItemSubjectsSemi({
                                          id: index,
                                          name: "summa",
                                          value:
                                            value *
                                            Number(get(item, "tsena", 0)),
                                        })
                                      );
                                    }}
                                  />
                                ) : (
                                  <NumericFormat
                                    displayType="text"
                                    value={get(item, "kol", 0)}
                                    thousandSeparator
                                    decimalScale={2}
                                  />
                                )}
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "tsena", "")}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                <NumericFormat
                                  displayType="text"
                                  value={get(item, "summa", 0)}
                                  thousandSeparator
                                  decimalScale={2}
                                />
                              </td>
                              <td>
                                {isEqual(
                                  get(values, "tipSpisaniyaId.value", 1),
                                  2
                                ) &&
                                  isEqual(disable, false) && (
                                    <span>
                                      <ReactSVG
                                        src={remove_red}
                                        className="svg_icon"
                                        style={{ fontSize: 10 }}
                                        onClick={() =>
                                          dispatch(
                                            removeSubjectSemi({ id: index })
                                          )
                                        }
                                      />
                                    </span>
                                  )}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={7}>Нет Данных</td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={7}>Загрузка....</td>
                    </tr>
                  )}
                </BaseTable>
              </Col>
            </Col>
          </Row>
          <Row className={"mb-16 mt-32 text-end"}>
            <Col xs={12}>
              <HasAccess>
                {({ userCan }: any) =>
                  userCan({
                    permission: `${DOCUMENT_KEY.VIPUSK_PF}_CREATE`,
                    page: DOCUMENT_KEY.VIPUSK_PF,
                    department: MAIN_DEPARTMENT.DOCUMENTS,
                    type: PROTECTED_TYPE.PERMISSIONS,
                  }) && (
                    <FormButton
                      disabled={disable}
                      loading={isSubmitting}
                      handleClick={handleSubmit}
                    >
                      {PAGE.DONE}
                    </FormButton>
                  )
                }
              </HasAccess>
              <Button danger handleClick={() => navigate(-1)}>
                {PAGE.CLOSE}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadingAddPages />
      )}
    </Content>
  );
};

export default AddSemiProductContainer;
